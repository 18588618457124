import { AlertMessageService } from './../../../shared/services/alert-message.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AttendanceService, ResumeCheckoutService } from 'src/app/shared/services';
import { ModalShareAttendanceResume } from '../../modals';
import { Location } from '@angular/common';

@Component({
    selector: 'app-attendance-info-page',
    templateUrl: './attendance-info-page.component.html',
    styleUrls: ['./attendance-info-page.component.scss']
})
export class AttendanceInfoPageComponent implements OnInit {
    loading: boolean = false;
    patient = null;
    start_date = null;
    doctor = null;
    place = null;
    event = null;
    user = null;
    company = null;
    token = null;

    constructor(
        public attendanceService: AttendanceService,
        private route: ActivatedRoute,
        private bsModalService: BsModalService,
        private alertMessageService: AlertMessageService,
        private location: Location,
        private resumeCheckoutService: ResumeCheckoutService
    ) { }

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams.token;
        this.getAttendanceData();
    }

    getAttendanceData() {
        const data = {
            attendance_id: this.route.snapshot.params.id,
            token: this.token
        };

        this.loading = true;

        this.attendanceService.list(data, (error, resp) => {
            this.loading = false;

            if (error) {
                return;
            }

            this.mountData(resp);
            this.resumeCheckoutService.attendanceId = this.route.snapshot.params.id;
        });
    }

    mountData(resp) {
        if (!resp) {
            return;
        }

        this.start_date = resp.attendance.start_date;
        this.doctor = resp.doctor;
        this.event = resp.event;
        this.patient = resp.patient;
        this.user = resp.user;
        this.company = resp.company
        this.place = {
            address: resp.place
        };
    }

    openShareModal() {
        this.bsModalService.show(ModalShareAttendanceResume).setClass('modal-sm');
    }
}
