import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-mobile-default-header',
    templateUrl: './default-header.component.html',
    styleUrls: ['./default-header.component.scss']
})
export class MobileDefaultHeaderComponent implements OnInit {
    url;

    constructor(private router: Router) { }

    ngOnInit() {
        this.url = this.router.url;
    }

    locationBack() {
        if (this.url.includes('/mobile/scheduled-successfully')) {
            this.router.navigate(['/mobile/card-info'], { queryParamsHandling: 'preserve' });
            return;
        }
    }
}
