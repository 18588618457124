import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeviceService } from './shared/services';
import { LayoutDefaultComponent } from './shared/components/layout-default/layout-default.component';

const routes: Routes = [{
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [DeviceService]
}];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
