import { Component, OnInit } from '@angular/core';
import { AlertMessageService, AttendanceService, CheckoutService, ResumeCheckoutService, SearchService } from 'src/app/shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalShareAttendanceResume } from 'src/app/desktop/modals';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-mobile-scheduled-succesfully-page',
    templateUrl: './scheduled-succesfully-page.component.html',
    styleUrls: ['./scheduled-succesfully-page.component.scss'],
})
export class MobileScheduledSucessfullyPageComponent implements OnInit {
    patient = null;
    slot = null;
    doctor = null;
    place = null;
    event = null;
    step = 1;
    user = null;
    loading = false;
    status = null;
    procedurePayments = null;
    payment = null;
    insurance = null;

    constructor (
        private checkoutService: CheckoutService,
        private router: Router,
        private bsModalService: BsModalService,
        private activatedRoute: ActivatedRoute,
        private resumeCheckoutService: ResumeCheckoutService,
        private attendanceService: AttendanceService,
        private alertMessageService: AlertMessageService,
        private searchService: SearchService,
    ) { }

    ngOnInit(): void {
        this.insurance = this.checkoutService.getInsurance();
        this.patient = this.checkoutService.getPatient();
        this.payment = this.checkoutService.getPayment();
        this.doctor = this.checkoutService.getDoctor();
        this.place = this.checkoutService.getPlace();
        this.event = this.checkoutService.getEvent();
        this.slot = this.checkoutService.getSlot();

        this.getProceduresPrice();

        if (!this.patient || !this.doctor || !this.place || !this.event || !this.slot) {
            this.backToCardInfo();

            return;
        }

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                skdld: true
            },
            queryParamsHandling: 'merge'
        });
    }

    getProceduresPrice() {
        const data = {
            event_id: this.event.id
        };

        this.searchService.getEventsProcedures(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.procedurePayments = resp;
            this.slot.price = this.procedurePayments.price;
            this.checkoutService.setSlot(this.slot);
        });
    }

    backToCardInfo() {
        this.router.navigate(['/mobile/card-info'], { queryParamsHandling: 'preserve' });
    }

    share() {
        this.bsModalService.show(ModalShareAttendanceResume).setClass('modal-sm');
    }

    confirmAttendance() {
        const data = {
            id: this.resumeCheckoutService.getNewAttendanceId()
        };

        if (!data.id) {
            return;
        }

        this.loading = true;

        this.attendanceService.confirmAttendance(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.status = 'CONFIRMED';
            this.alertMessageService.open('Atendimento confirmado com sucesso!', null, 'success');
        });
    }
}
