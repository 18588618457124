import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { MobileAttendanceHistoryPage } from './attendance-history-page.component';
import { LoadingModule } from 'src/app/shared/components';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    declarations: [
        MobileAttendanceHistoryPage
    ],
    exports: [
        MobileAttendanceHistoryPage,
    ],
    imports: [
        RouterModule,
        BrowserModule,
        LoadingModule,
        AngularSvgIconModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AttendanceHistoryModule { }
