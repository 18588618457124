import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CheckoutService } from '../../../shared/services';
import { MobileSearchService } from './../../services';
import { SearchPageService } from 'src/app/desktop/services';

@Component({
    selector: 'app-mobile-select-address-page',
    templateUrl: './select-address-page.component.html',
    styleUrls: ['./select-address-page.component.scss']
})
export class SelectAddressPageComponent implements OnInit {
    selectedPlace;
    places = [];
    isDoctor;
    id;

    constructor (
        private checkoutService: CheckoutService,
        private mobileSearchService: MobileSearchService,
        private router: Router,
        private route: ActivatedRoute,
        private searchPageService: SearchPageService
    ) { }

    ngOnInit(): void {
        if (!this.route.snapshot.queryParams.token || !this.searchPageService.item) {
            return;
        }

        if (this.checkoutService.getPlace()) {
            this.selectedPlace = this.checkoutService.getPlace().id;
        }

        this.getPlaces();
    }

    getPlaces() {
        const item = this.searchPageService.item;
        let action = 'getDoctor';

        if (!item.isDoctor) {
            action = 'getCompany';
        }

        this.places = this.checkoutService[action]().places;
    }

    setPlace(place) {
        if (this.selectedPlace && this.selectedPlace !== place.id) {
            this.checkoutService.setEvent(null);
            this.checkoutService.setSlot(null);
            this.checkoutService.setDoctor(null);
        }

        if (this.selectedPlace && this.selectedPlace === place.id) {
            this.checkoutService.setEvent(null);
            this.checkoutService.setPlace(null);
            this.checkoutService.setSlot(null);

            if (!this.isDoctor) {
                this.checkoutService.setDoctor(null);
            }

            this.redirectToLastStep();
            return;
        }

        this.selectedPlace = place.id;

        if (place.is_tele) {
            this.checkoutService.setEvent(place);
        }

        this.checkoutService.setPlace(place);

        this.redirectToLastStep();
    }

    redirectToLastStep() {
        this.router.navigate([`/mobile/card-info/`], { queryParamsHandling: 'preserve' });
    }
}
