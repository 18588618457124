<div class="ng-autocomplete">
    <ng-autocomplete
        debounceTime="700"
        minQueryLength="3"
        [data]="results"
        (selected)='selectEvent($event)'
        (inputChanged)="onChangeSearch($event)"
        (inputCleared)="onClear()"
        [itemTemplate]="itemTemplate"
        (inputFocused)="onFocused()"
        [(ngModel)]="city">
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
        <div class="flex-row d-flex align-items-center" style="padding: 16px 26px !important;">
            <div class="location-pin">
                <svg-icon src="assets/svg/location.svg" [svgClass]="'location-icon'"></svg-icon>
            </div>
            <div class="location-name" [innerHTML]="item"></div>
        </div>
    </ng-template>
</div>
