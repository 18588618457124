import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { CompanyListPageComponent } from './company-list-page.component';
import { CompanyCardModule } from '../../components';
import { LoadingModule } from 'src/app/shared/components';

@NgModule({
    declarations: [
        CompanyListPageComponent
    ],
    exports: [
        CompanyListPageComponent
    ],
    imports: [
        BrowserModule,
        LoadingModule,
        CompanyCardModule,
        AngularSvgIconModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CompanyListPageModule { }
