<div class="close-page-div">
    <svg-icon src="assets/svg/close.svg" class="close-icon c-pointer" (click)="redirectToLastStep()"></svg-icon>
</div>

<main class="main">
    <div class="d-block flex-row select-event">
        <span class="ml-3">Selecione o atendimento</span>
        <input type="text" class="event-filter-input" (input)="applyFilter()" [(ngModel)]="inputValue" value="inputValue" style="color: #676a6c">
    </div>
    <div class="overflow-event" style="padding: 10px 15px;">
        <div *ngIf="listEventsView && listEventsView.length">
            <ul class="d-flex pl-0 m-0 flex-row event" style="margin-bottom: 10px !important; border-radius: 6px;" *ngFor="let event of listEventsView" (click)="setEvent(event)" [ngClass]="{ 'event-hover': selectedEvent === event.id }">
                <svg-icon src="assets/svg/stethoscope.svg" class="mr-2 ml-3"></svg-icon>
                <li class="event-name">
                    {{ event.name }}
                </li>
            </ul>
        </div>
    </div>
    <div class="d-flex mt-3" *ngIf="!listEventsView || !listEventsView.length">
        <div class="col-12">
            <div class="alert alert-warning">
                Não há tipos de atendimento disponíveis com o filtro selecionado!
            </div>
        </div>
    </div>
</main>
