<div class="suggest-multi {{ options.customClass }}">
    <input type="text" [(ngModel)]="searchText" class="form-control h-100 suggest-multi-input" (focus)="focusInput()">

    <div class="suggest-multi-box" [class.opened]="isOpened">
        <ul class="suggest-multi-box-list">
            <li class="suggest-multi-box-list-item"
                *ngFor="let item of items | filter: { key: 'name', value: searchText, limit_character: 3 }">
                <label class="suggest-multi-box-list-item-label d-flex align-items-center" for="item-{{ item.id }}">
                    <input type="checkbox" class="suggest-multi-box-list-item-input" id="item-{{ item.id }}"
                        [(ngModel)]="item.selected">
                    <span class="suggest-multi-box-item-name text-truncate">{{ item.name }}</span>
                </label>
            </li>
        </ul>

        <div class="suggest-multi-close d-flex align-items-center justify-content-between">
            <span class="suggest-multi-close-cancel" (click)="clear()">Cancelar</span>
            <button type="button" class="btn btn-white float-right suggest-multi-close-btn" (click)="apply()">Aplicar</button>
        </div>
    </div>
</div>
