import { Injectable } from '@angular/core';
import { SearchService, AlertMessageService } from 'src/app/shared/services';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchPageService {
    loading: Boolean = false;
    hasItem = new BehaviorSubject('');
    item = null;
    hasSelectedPreviousData: boolean = false;
    isDoctor = null;

    constructor (
        private route: ActivatedRoute,
        private searchService: SearchService,
        private alertMessageService: AlertMessageService
    ) { }

    list(callback?) {
        const data = this.getFilter();

        this.loading = true;

        this.searchService.mainList(data, (error, resp) => {
            this.loading = false;

            if (error || resp.status === 'error') {
                const errorObject = error ? error : resp;

                this.alertMessageService.open(error ? error.message : resp.message, null, 'warning');
                this.item = null;

                if (callback) {
                    callback(errorObject);
                }

                return;
            }

            this.item = resp;

            if (resp.data) {
                this.item = resp.data;
            }

            this.isDoctor = resp.isDoctor;

            if (!callback) {
                return;
            }

            callback(null, this.item);
        });
    }

    getFilter() {
        return {
            token: this.route.snapshot.queryParams.token
        };
    }
}
