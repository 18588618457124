<div class="row card-row" style="background-color: #E5E5E5; width: 100%; height: 100vh;">
    <div class="offset-md-2 col-12 col-md-8 card-column" style="padding: 0 !important; display: flex; justify-content: center; align-items: center;">
        <main style="background-color: white; border-radius: 10px; width: 100%; display: flex; justify-content: center; padding: 20px;">
            <div class="card" style="width: 100%; max-width: 800px;">
                <div class="card-body">
                    <app-loading [hidden]="!loading"></app-loading>

                    <main *ngIf="!loading && !isValidToken">
                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                                <img src="assets/img/error-icon.png" alt="" style="width: 120px;">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                                Erro!
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                                Token inválido ou já utilizado!
                            </div>
                        </div>
                    </main>

                    <main *ngIf="!loading && isValidToken && attendance && !madeRequest">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h3>
                                    Confirme seu atendimento
                                </h3>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-center mt-3">
                                Olá, <strong>{{ attendance.patient_name }}!</strong>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-center mt-1" ng-if="attendance" style="font-size: 15px;">
                                Você possui uma consulta marcada com
                                <br>
                                <p> o(a) Dr(a). {{ attendance.user.name }}, no dia {{ attendance.start_date | date: 'dd/MM/yyyy' }}<span *ngIf="!attendance.user.config_arrival_order">, às {{ attendance.start_date | date: 'HH:mm' : '-03:00' }}</span>. </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 mt-3 text-center">
                                <div style="font-size: 18px; color: #008CFF;">
                                    Confirma sua presença?
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 mt-3 pb-3 d-flex justify-content-center">
                                <button (click)="submit('CANCELED')" style="border-radius: 12px;" class="btn btn-secondary mr-4">Cancelar</button>
                                <button (click)="submit('CONFIRMED')" style="border-radius: 12px;" class="btn btn-primary">Confirmar</button>
                            </div>
                        </div>

                        <hr>

                        <div style="width: 95%; font-size: 15px; margin: 20px 0 auto; color: #848585; margin-top: 20px;">
                            <div>
                                <div style="padding-top: 20px;">
                                    <img src="https://agendemais.s3-sa-east-1.amazonaws.com/email-icons/calendar.png" style="width: 18px; height: 19px; margin-right: 5px; position: relative; top: -2px;">
                                    <span style="color: #676a6c;" class="content-text content-vertical-align">
                                        {{ attendance.start_date | date: 'dd/MM/yyyy' }}<span *ngIf="!attendance.user.config_arrival_order">, às {{ attendance.start_date | date: 'HH:mm' : '-03:00' }}</span>
                                    </span>
                                </div>
                                <div style="padding-top: 20px;">
                                    <img src="https://agendemais.s3-sa-east-1.amazonaws.com/email-icons/user.png" style="width: 18px; height: 19px; margin-right: 5px; position: relative; top: -2px;">
                                    <span style="color: #676a6c;" class="content-text content-vertical-align">
                                        Dr(a). {{ attendance.user.name || '' }}
                                    </span>
                                </div>
                                <div style="padding-top: 20px;">
                                    <img src="https://agendemais.s3-sa-east-1.amazonaws.com/email-icons/hospital.png" style="width: 18px; height: 19px; margin-right: 5px; position: relative; top: -2px;">
                                    <span style="color: #676a6c;" class="content-text content-vertical-align">
                                        {{ attendance.company_name }}
                                    </span>
                                </div>
                                <div style="padding-top: 20px; padding-bottom: 20px;">
                                    <img src="https://agendemais.s3-sa-east-1.amazonaws.com/email-icons/location-pin.png" style="width: 18px; height: 22px; margin-right: 5px; position: relative; top: -2px;">
                                    <span style="color: #676a6c;" class="content-text content-vertical-align">
                                        {{ attendance.place | address }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <hr>
                    </main>

                    <main *ngIf="!loading && isValidToken && attendance && madeRequest">
                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                                <img src="assets/img/success-icon.png" alt="" style="width: 120px;">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                                Sucesso!
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                                Prezado(a) <strong>{{ attendance.patient_name }},</strong>
                                <br>
                                o atendimento foi {{ actionMessage }} com sucesso!
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </main>
    </div>
</div>
