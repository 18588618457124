import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertMessageService, DeviceService, ResumeCheckoutService } from 'src/app/shared/services';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-modal-share-attendance-resume',
    templateUrl: './modal-share-attendance-resume.component.html',
    styleUrls: ['./modal-share-attendance-resume.component.scss']
})
export class ModalShareAttendanceResume implements OnInit {
    attendanceId;
    token;

    constructor(
        public bsModalRef: BsModalRef,
        private resumeCheckoutService: ResumeCheckoutService,
        private route: ActivatedRoute,
        private deviceService: DeviceService,
        private alertMessage: AlertMessageService
    ) { }

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams.token;
        this.attendanceId = this.resumeCheckoutService.getNewAttendanceId();
    }

    shareByWhatsapp() {
        const now = moment().locale('pt-br').format('LLL');
        const link = `https://test.agende.app.br/desktop/attendance-info/${this.attendanceId}?token=${this.token}`;
        const text = `Acesse o link a seguir para obter informações acerca do agendamento realizado no dia ${now}. ${link}`;
        let url = `https://web.whatsapp.com/send?l=en&text=${text}`;

        if (this.deviceService.isMobile()) {
            url = `whatsapp://send?l=en&text=${text}`;
        }

        if (this.deviceService.isMobile()) {
            return;
        }

        window.open(url, "_blank");
    }

    copyLink() {
        const link = `https://test.agende.app.br/desktop/attendance-info/${this.attendanceId}?token=${this.token}`;
        let input = document.createElement("input");

        input.value = link;
        document.body.appendChild(input);

        input.select();

        document.execCommand('copy');
        document.body.removeChild(input);
        this.alertMessage.open('Link copiado com sucesso', null, 'success');
    }
}
