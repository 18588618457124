import * as moment from 'moment-timezone';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';
import { DeviceDetectorModule } from 'ngx-device-detector';

// Http and Routing
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

//Services
import {
    AuthInterceptorService,
    DeviceService
} from './shared/services';

import { AppComponent } from './app.component';
import { MobileModule } from './mobile/mobile.module';
import { DesktopModule } from './desktop/desktop.module';
import { RouterModule } from '@angular/router';

registerLocaleData(localePT, 'pt', localeExtraPT);

moment.tz.setDefault('America/Recife');

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        RouterModule,
        AppRoutingModule,
        DeviceDetectorModule,
        DesktopModule,
        MobileModule,
    ],
    providers: [
        DeviceService,
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
