<header>
    <div class="container pt-3">
        <div class="d-flex justify-content-between align-items-center">
            <div class="col-3 pl-0">
                <a (click)="backToCardInfo()">
                    <svg-icon src="assets/svg/mobile-arrow-left.svg"></svg-icon>
                </a>
            </div>
        </div>
    </div>
</header>

<div class="container">
    <app-loading [hidden]="!loading"></app-loading>

    <main class="mt-4" [hidden]="loading">
        <div class="row">
            <div class="col-12" style="font-style: normal;font-weight: 400;font-size: 18px;line-height: 112%;">
                Agendamento para paciente
            </div>
        </div>

        <div class="row">
            <div class="col-12" style="font-style: normal;font-weight: 400;font-size: 20px;line-height: 112%;">
                {{ patient.name }}
            </div>
        </div>

        <hr>

        <div class="d-flex" style="margin-top: 16px; margin-right: 15px;" *ngIf="place">
            <div>
                <img src="{{ company_logo.url }}" alt="" *ngIf="company_logo && company_logo.url" style="width: 54px; height: 54px; margin-right: 15px;">
                <img src="assets/svg/company-blank.svg" alt="" *ngIf="!company_logo || !company_logo.url" style="width: 54px; height: 54px; margin-right: 15px;">
            </div>

            <div class="d-flex flex-column">
                <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">{{ place.company.name }}</div>

                <div style="font-weight: 500;font-size: 14px; margin-top: 8px;line-height: 115%;">
                    <span class="d-block" *ngIf="place.address && event && !event.is_tele">{{ place.address | address }}</span>
                    <span class="d-block" *ngIf="event && event.is_tele">(Atendimento online)</span>
                </div>
            </div>
        </div>

        <hr>

        <div class="d-flex flex-column">
            <div style="font-style: normal;font-weight: 400;font-size: 18px;">
                <svg-icon *ngIf="place && place.is_tele" src="assets/svg/telemedicine-blue.svg" style="margin-right: 5px;"></svg-icon>
                Tipo de atendimento
                <span style="margin-left: 3px;" [hidden]="!event">
                    <svg-icon src="assets/svg/verified-green.svg" class="icon-verified-green"></svg-icon>
                </span>
            </div>

            <div style="margin-top: 16px;" *ngIf="event">
                {{ event.name }}
            </div>
            <div style="margin-top: 8px;" *ngIf="slot.price && paymentType !== 'INSURANCE'">
                R$ {{ slot.price }}
            </div>
        </div>

        <hr>

        <div class="row pt-2 pb-2" *ngIf="tokenInfo && (tokenInfo.type !== 'canceled' && tokenInfo.type !== 'missed')">
            <div class="col-12 payment-select">
                <span *ngIf="!selectedPaymentData || (paymentType === 'INSURANCE' && selectedPaymentData && !selectedPaymentData.insurance)">Adicionar pagamento</span>

                <div *ngIf="selectedPaymentData" class="ds-flex ds-flex__align--center ds-flex__justify--space-between">
                    <!-- <span class="d-block ml-5" *ngIf="paymentType === 'CREDITCARD'">
                        <app-card-brand [cardBrand]="selectedPaymentData.card.brand" class="card-position"></app-card-brand>
                        <span>
                            {{ selectedPaymentData.card.brand | titlecase }}
                            <span class="d-block">xxxx xxxx xxxx {{ selectedPaymentData.card.cardNumber.substr(-4) }}</span>
                        </span>
                    </span>

                    <span class="d-block" *ngIf="paymentType === 'INSURANCE' && selectedPaymentData && selectedPaymentData.insurance">
                        {{ selectedPaymentData.insurance.name }}
                        <span class="d-block">xxxx xxxx xxxx {{ selectedPaymentData.cardNumber.substr(-4) }}</span>
                    </span> -->

                    <div style="font-weight: 500; font-size: 16px;">Forma de pagamento: </div>
                    <span class="d-block" *ngIf="paymentType && (paymentType !== 'INSURANCE' && paymentType !== 'CREDITCARD')">{{ selectedPaymentData.label }}</span>
                </div>
<!--
                <span class="d-flex justify-content-end">
                    <svg-icon src="assets/svg/arrow-right-gray.svg" *ngIf="!selectedPaymentData" class="payment-add-icon"></svg-icon>
                    <svg-icon src="assets/svg/arrow-right-gray.svg" [class.top-0]="paymentType === 'PARTICULAR'" *ngIf="selectedPaymentData" class="payment-add-icon-selected"></svg-icon>
                </span> -->
            </div>
        </div>

        <hr>

        <div class="row" [ngClass]="{ 'white-smoke-border-top': tokenInfo && (tokenInfo.type === 'canceled' || tokenInfo.type === 'missed') }" *ngIf="slot.price && paymentType !== 'INSURANCE'" style="padding: 12px 0;">
            <div class="col-12 payment-select price">
                <span style="font-weight: 500;font-size: 17px;line-height: 20px;color: #676A6C;">Total: </span>

                <span class="float-right" style="font-weight: 500;font-size: 17px;line-height: 20px;color: #676A6C;">
                    <span *ngIf="paymentType === 'CREDITCARD'">1x de </span>R$ {{ slot.price }}
                </span>
            </div>
        </div>

        <div style="margin-top: 24px;" [hidden]="loading">
            <button class="btn btn-primary btn-block" style="height: 48px;" [disabled]="((!selectedPaymentData || !paymentType) && (tokenInfo && tokenInfo.type !== 'missed' && tokenInfo.type !== 'canceled')) || (selectedPaymentData === 'creditcard' && !creditcardService.isValid)" (click)="schedule()">Concluir agendamento</button>
        </div>
    </main>
</div>
