import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-mobile-attendance-card',
    templateUrl: './mobile-modal-attendance-card.component.html',
    styleUrls: ['./mobile-modal-attendance-card.component.scss']
})
export class ModalMobileAttendanceCardComponent implements OnInit {
    schedule: any = {
        user: {
            id: '',
            name: '',
            council_name: '',
            council_number: ''
        },
        place: {
            address: {},
            name: '',
            id: '',
            is_tele: ''
        },
        price: '',
        name: '',
        place_name: '',
        event: {
            name: ''
        }
    };

    constructor(
        public bsModalRef: BsModalRef
    ) { }

    ngOnInit() {
    }
}
