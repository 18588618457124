import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CreditcardOwnerComponent } from './creditcard-owner.component';
import { NgxMaskModule } from 'ngx-mask';
import { LoadingModule } from 'src/app/shared/components';

@NgModule({
    declarations: [
        CreditcardOwnerComponent
    ],
    exports: [
        CreditcardOwnerComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        LoadingModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreditcardOwnerModule { }
