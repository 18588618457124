<div class="container container-padding-bottom">
    <form [formGroup]="cardOwnerForm">
        <div class="row mt-3">
            <div class="col-12">
                <span class="card-info">Informações pessoais do titular do cartão</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <label class="text-sm-left">Titular do cartão</label>
                <input class="form-control input-outline placeholder-light-gray"
                    placeholder="Nome do titular" type="text" maxlength="255" formControlName="name"
                    autocomplete="off">
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <label class="text-sm-left">Data de nascimento</label>
                <input class="form-control input-outline placeholder-light-gray" mask="00/00/0000"
                    type="text" formControlName="born" autocomplete="off" placeholder="09/12/2001"
                    [ngClass]="{ 'is-invalid': f.born.value && f.born.errors }">
                <span *ngIf="f.born.errors" class="invalid-feedback">Data de nascimento inválida</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <label class="text-sm-left">Número de celular</label>
                <input class="form-control input-outline placeholder-light-gray" mask="(00) 0 0000-0000"
                    type="text" formControlName="cellphone" autocomplete="off"
                    placeholder="(81) 99650-7676"
                    [ngClass]="{ 'is-invalid': f.cellphone.value && f.cellphone.errors }">
                <span class="invalid-feedback" *ngIf="f.cellphone.errors">Telefone inválido</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <label class="text-sm-left">CPF</label>
                <input class="form-control input-outline placeholder-light-gray" mask="000.000.000-00"
                    type="text" formControlName="cpf" autocomplete="off" placeholder="000.000.000-00"
                    [ngClass]="{ 'is-invalid': f.cpf.value && f.cpf.errors }">
                <div *ngIf="f.cpf.errors" class="invalid-feedback">CPF Inválido</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <label class="text-sm-left mt-1">Endereço</label>
                <input class="form-control input-outline placeholder-light-gray" type="text" maxlength="255"
                    placeholder="Avenida 17 de agosto" formControlName="address" autocomplete="off">
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <label class="text-sm-left mt-1">Complemento</label>
                <input class="form-control input-outline placeholder-light-gray" type="text" maxlength="255"
                    placeholder="Opcional" formControlName="complement" autocomplete="off">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-6">
                <label class="text-sm-left mt-1">Número</label>
                <input class="form-control input-outline placeholder-light-gray" type="text"
                    placeholder="123" formControlName="addressNumber" autocomplete="off">
            </div>
            <div class="col-6">
                <label class="text-sm-left mt-1">Bairro</label>
                <input class="form-control input-outline placeholder-light-gray" type="text"
                    placeholder="Casa Forte" maxlength="255" formControlName="district" autocomplete="off">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-6">
                <label class="text-sm-left mt-1">Cidade</label>
                <input class="form-control input-outline placeholder-light-gray" placeholder="Recife"
                    type="text" maxlength="255" formControlName="city" autocomplete="off">
            </div>
            <div class="col-6">
                <label class="text-sm-left mt-1">Estado</label>
                <select class="form-control input-outline placeholder-light-gray city-select"
                    formControlName="state">
                    <option value="">Selecione</option>
                    <option value="Acre">Acre</option>
                    <option value="Alagoas">Alagoas</option>
                    <option value="Amapá">Amapá</option>
                    <option value="Amazonas">Amazonas</option>
                    <option value="Bahia">Bahia</option>
                    <option value="Ceará">Ceará</option>
                    <option value="Distrito Federal">Distrito Federal</option>
                    <option value="Espírito Santo">Espírito Santo</option>
                    <option value="Goiás">Goiás</option>
                    <option value="Maranhão">Maranhão</option>
                    <option value="Mato Grosso">Mato Grosso</option>
                    <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                    <option value="Minas Gerais">Minas Gerais</option>
                    <option value="Pará">Pará</option>
                    <option value="Paraíba">Paraíba</option>
                    <option value="Paraná">Paraná</option>
                    <option value="Pernambuco">Pernambuco</option>
                    <option value="Piauí">Piauí</option>
                    <option value="Rio de Janeiro">Rio de Janeiro</option>
                    <option value="Rio Grande do Norte">Rio Grande do Norte</option>
                    <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                    <option value="Rondônia">Rondônia</option>
                    <option value="Roraima">Roraima</option>
                    <option value="Santa Catarina">Santa Catarina</option>
                    <option value="São Paulo">São Paulo</option>
                    <option value="Sergipe">Sergipe</option>
                    <option value="Tocantins">Tocantins</option>
                </select>
            </div>
        </div>
    </form>
</div>
