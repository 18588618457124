import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { HttpClientModule } from '@angular/common/http';

//Utils services
import { SuggestCitiesComponent } from './suggest-cities.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ng2-tooltip-directive';


@NgModule({
    declarations: [
        SuggestCitiesComponent
    ],
    exports: [
        SuggestCitiesComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSvgIconModule,
        AutocompleteLibModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CommonModule,
        NgxMaskModule,
        TooltipModule,
        BsDatepickerModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SuggestCitiesModule { }
