import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

//Utils services
import { DefaultHeaderModule } from '../../components';
import { ScheduledSuccessfullyPage } from './scheduled-successfully-page.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AddressPipeModule } from 'src/app/shared/pipes';
import { DoctorCardModule } from 'src/app/mobile/components';
import { LoadingModule } from 'src/app/shared/components';

@NgModule({
    declarations: [
        ScheduledSuccessfullyPage
    ],
    exports: [
        ScheduledSuccessfullyPage,
    ],
    imports: [
        BrowserModule,
        DefaultHeaderModule,
        AngularSvgIconModule,
        LoadingModule,
        AddressPipeModule,
        DoctorCardModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ScheduledSuccessfullyPageModule { }
