import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CheckoutService, AlertMessageService, CompanyService } from 'src/app/shared/services';
import { MobileCheckoutService } from '../../services';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'app-mobile-select-payment-page',
    templateUrl: './select-payment-page.component.html',
    styleUrls: ['./select-payment-page.component.scss']
})
export class MobileSelectPaymentPageComponent implements OnInit {
    url;
    paymentsAllowed = null;
    token = null;
    loading: boolean = false;
    paymentMethod = null;
    slot = null;
    selectedInsurancePlan = null;
    selectedInsuranceGroup = null;
    firstInsuranceGroupState = null;
    firstInsurancePlanState = null;
    insurancePlans = [];
    firstPaymentMethodState = null;
    itemInfo;
    settings = null;

    constructor (
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private checkoutService: CheckoutService,
        private mobileCheckoutService: MobileCheckoutService,
        private alertMessageService: AlertMessageService,
        private companyService: CompanyService
    ) { }

    ngOnInit(): void {
        this.paymentMethod = this.checkoutService.getPayment();

        this.firstPaymentMethodState = this.paymentMethod;
        this.selectedInsurancePlan = this.checkoutService.getInsurancePlan();
        this.selectedInsuranceGroup = this.checkoutService.getInsurance();

        this.firstInsuranceGroupState = this.selectedInsuranceGroup;
        this.firstInsurancePlanState = this.selectedInsurancePlan;

        this.itemInfo = this.checkoutService.getItemInfo();

        this.token = this.route.snapshot.queryParams.token;
        this.url = this.router.url;
        this.slot = this.checkoutService.getSlot();

        if (!this.token || !this.itemInfo) {
            this.location.back();
            return;
        }

        if (this.selectedInsurancePlan && this.selectedInsuranceGroup && this.itemInfo && this.itemInfo.insurance_plans) {
            this.insurancePlans = this.itemInfo.insurance_plans.filter(item => item.health_insurance_group_id === this.selectedInsuranceGroup.id);
        }

        if (this.route.snapshot.queryParams.wasDesktop) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    wasDesktop: ''
                },
                queryParamsHandling: 'merge'
            });

            this.checkoutService.setSlot(null);
            this.navigateByUrl('/mobile/card-info');
            return;
        }

        this.getSettings();
        this.location.onUrlChange((url) => this.url = url);
    }

    selectInsuranceGroup(group) {
        if (this.selectedInsuranceGroup && this.selectedInsuranceGroup.id === group.id) {
            this.selectedInsuranceGroup = null;
            this.selectedInsurancePlan = null;
            return;
        }

        this.selectedInsuranceGroup = group;
        this.selectedInsurancePlan = null;

        this.insurancePlans = this.itemInfo.insurance_plans.filter(item => item.health_insurance_group_id === group.id);
    }

    selectInsurancePlan(insurance) {
        if (this.selectedInsurancePlan && this.selectedInsurancePlan.id === insurance.id) {
            this.selectedInsurancePlan = null;
            return;
        }

        this.selectedInsurancePlan = insurance;
    }

    changePaymentMethod(method) {
        if (this.paymentMethod === method) {
            this.paymentMethod = null;
            this.selectedInsurancePlan = null;
            this.selectedInsuranceGroup = null;

            return;
        }

        this.paymentMethod = method;
    }

    getSettings() {
        const data = {
            token: this.token
        };

        this.loading = true;

        return this.companyService.getSettings(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'warning');
                return;
            }

            this.settings = resp;
        });
    }

    backToResume() {
        this.router.navigate(['/mobile/card-info'], { queryParamsHandling: 'preserve' });
    }

    // backToStep(step) {
    //     this.router.navigate([`/mobile/select-payment/creditcard/${step}`], { queryParamsHandling: 'preserve' });
    // }

    cleanScheduleInfo() {
        if (!this.itemInfo.isDoctor) {
            this.checkoutService.setDoctor(null);
        }

        this.checkoutService.setSlot(null);
    }

    submit() {
        if (!this.paymentMethod) {
            return this.alertMessageService.open('Selecione uma forma da pagamento', null, 'warning');
        }

        if (this.paymentMethod !== this.firstPaymentMethodState) {
            this.cleanScheduleInfo();
        }

        this.checkoutService.setPayment(this.paymentMethod);

        if (this.paymentMethod === 'Particular') {
            this.checkoutService.setInsurance(null);
            this.checkoutService.setInsurancePlan(null);
            this.router.navigate(['/mobile/card-info'], { queryParamsHandling: 'merge', queryParams: { paymentType: '' } });
            return;
        }

        if (!this.selectedInsuranceGroup || !this.selectedInsurancePlan) {
            return this.alertMessageService.open('Selecione o convênio e o plano corretamente.', null, 'warning');
        }

        if (this.firstInsurancePlanState && this.firstInsurancePlanState.id !== this.selectedInsurancePlan.id) {
            this.cleanScheduleInfo();
        }

        this.mobileCheckoutService.selectedPayment = true;
        this.mobileCheckoutService.payment = this.paymentMethod;

        this.checkoutService.setInsurancePlan(this.selectedInsurancePlan);
        this.checkoutService.setInsurance(this.selectedInsuranceGroup);

        this.router.navigate(['/mobile/card-info'], { queryParamsHandling: 'merge', queryParams: { paymentType: 'PARTICULAR' } });
    }

    navigateByUrl(url) {
        this.router.navigate([url], { queryParamsHandling: 'preserve' });
    }
}
