<main *ngIf="doctorData && doctorData.name">
    <div class="d-flex flex-row">
        <div class="doctor-img">
            <img style="object-fit: cover;" *ngIf="doctorData.thumb_url" src="{{ doctorData.thumb_url }}" class="rounded">
            <img style="object-fit: cover;" *ngIf="!doctorData.thumb_url" src="assets/img/user-profile.png" class="rounded">
        </div>
        <div class="doctor-info d-block text-truncate">
            <span class="doctor-name text-truncate">{{ doctorData.name }}</span>
            <span class="doctor-label d-block text-truncate" *ngIf="doctorData.council_name">
                {{ doctorData.council_name }}
                <span *ngIf="doctorData.council_number">{{ doctorData.council_number }}</span>
                <span *ngIf="doctorData.council_number && doctorData.specialty">
                    -  {{ doctorData.specialty }}
                </span>
            </span>
            <span class="telemedicine" *ngIf="doctorData.council_name && doctorData.has_tele">
                <svg-icon src="assets/svg/telemedicine.svg" class="mr-1"></svg-icon>
                TELEMEDICINA
            </span>
            <span class="telemedicine telemedicine-without-council d-block" *ngIf="!doctorData.council_name && doctorData.has_tele">
                <svg-icon src="assets/svg/telemedicine.svg" class="mr-1"></svg-icon>
                TELEMEDICINA
            </span>
        </div>
    </div>
</main>
