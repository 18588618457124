import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

//Utils services
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DefaultHeaderComponent } from './default-header.component';

@NgModule({
    declarations: [
        DefaultHeaderComponent
    ],
    exports: [
        DefaultHeaderComponent,
    ],
    imports: [
        AngularSvgIconModule,
        RouterModule,
        BrowserModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DefaultHeaderModule { }
