import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService, BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import * as moment from 'moment-timezone';
import { AlertMessageService, SlotService, CheckoutService, TimeGridService } from 'src/app/shared/services';
import { ActivatedRoute } from '@angular/router';
import * as lodash from 'lodash';

import { mountSlotShift, getArrivalShift } from '../../../shared/utils/index';

@Component({
    selector: 'app-mobile-calendar',
    templateUrl: './mobile-calendar.component.html',
    styleUrls: ['./mobile-calendar.component.scss']
})
export class MobileCalendarComponent implements OnInit {
    @Input() slots;
    @Input() selectedEvent;
    @Input() doctor;
    @Input() company;
    @Input() selectedPlace;
    @Output() selectedSlotEvent = new EventEmitter();

    openSlots: boolean = false;
    selectedDate;
    isInit: boolean = true
    loading: boolean = false;
    dateCustomClasses: DatepickerDateCustomClasses[] = [];
    enabledDatesLoading: boolean = false;
    datepickerInitialized: boolean = true;
    listedSlots = {};
    now = moment();
    enabledDates = [moment('2001-09-22')];
    bsConfig: Partial<BsDatepickerConfig>;
    config = {
        isAnimated: true,
        containerClass: 'card-theme',
        customTodayClass: 'current-day',
        showWeekNumbers: false,
        selectFromOtherMonth: false
    };
    options = {
        locale: 'pt-br',
        minDate: this.now.clone().add(1, 'day').toDate(),
        maxDate: this.now.clone().add(90, 'days').toDate()
    };
    dateValue: Date = this.now.clone().add(1, 'day').toDate();
    slotsGroupedByShift = [];
    selectedSlot;
    selectedInsurancePlan;
    paymentMethod;

    constructor(
        private localeService: BsLocaleService,
        private checkoutService: CheckoutService,
        private slotService: SlotService,
        private route: ActivatedRoute,
        private alertMessageService: AlertMessageService,
        private TimeGridService: TimeGridService
    ) {
        this.localeService.use(this.options.locale);
        ptBrLocale.weekdaysShort = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
        defineLocale('pt-br', ptBrLocale);
        this.bsConfig = Object.assign({}, this.config);
    }

    ngOnInit(): void {
        this.getCalendarConfig();

        this.selectedSlot = this.checkoutService.getSlot();
        this.selectedInsurancePlan = this.checkoutService.getInsurancePlan();
        this.paymentMethod = this.checkoutService.getPayment();

        if (this.doctor.enabledDates || (this.selectedSlot && this.selectedSlot.enabledDates)) {
            this.enabledDates = this.doctor.enabledDates ? this.doctor.enabledDates : (this.selectedSlot.enabledDates ? this.selectedSlot.enabledDates : []);
            this.setPreviousEnabledDates(this.enabledDates);

            if (this.selectedSlot) {
                this.selectedDate = moment(this.selectedSlot.start_date).toDate();
                this.getUserSlots(this.selectedDate);
            }

            return;
        }

        this.getEnabledDates();
    }

    getCalendarConfig() {
        const info = this.company || this.doctor;

        this.options.minDate = info.allow_schedule_for_current_day ? this.now.clone().toDate() : this.now.clone().add(1, 'day').toDate();
        this.options.maxDate = this.now.clone().add(info.scheduler_max_available_days, 'days').toDate();

        this.dateValue = this.options.minDate;
    }

    getEnabledDates() {
        const data = {
            month_key: moment().format('YYYY-MM'),
            event_id: this.selectedEvent.id,
            company_id: this.selectedPlace.company_id,
            user_id: this.doctor.id,
            place_id: this.selectedPlace.id,
            insurance_id: null,
            token: this.route.snapshot.queryParams.token
        };

        if (this.paymentMethod === 'Convênio' && this.selectedInsurancePlan && this.selectedInsurancePlan.id) {
            data.insurance_id = this.selectedInsurancePlan.id;
        }

        this.enabledDatesLoading = true;
        this.datepickerInitialized = false;

        if (this.selectedPlace.is_tele) {
            data.event_id = this.selectedPlace.id;
            data.place_id = this.selectedPlace.place.id;
        }

        this.slotService.calendar(data, (error, resp) => {
            this.enabledDatesLoading = false;
            this.datepickerInitialized = true;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.enabledDates = [];

                return;
            }

            this.setCustomDates(resp);
        });
    }

    setPreviousEnabledDates(dates) {
        this.dateCustomClasses = [];
        this.enabledDates = [];

        dates.forEach((date) => {
            const dateObject = {
                date: moment(date).endOf('day').toDate(),
                classes: ['enabled-date']
            };

            this.enabledDates.push(moment(date).endOf('day'));
            this.dateCustomClasses.push(dateObject);
        });

        if (!this.enabledDates.length) {
            this.enabledDates.push(moment('2001-09-22'));
        }
    }

    setCustomDates(dates) {
        this.dateCustomClasses = [];
        this.enabledDates = [];

        dates.forEach((date) => {
            const now = moment();
            const dateObject = {
                date: moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).endOf('day').toDate(),
                classes: ['enabled-date']
            };

            if (moment(date, 'DD/MM/YYYY').isBefore(moment(now).clone().format('YYYY-MM-DD'))) {
                return;
            }

            this.enabledDates.push(moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).endOf('day'));
            this.dateCustomClasses.push(dateObject);
        });

        if (!this.enabledDates.length) {
            this.enabledDates.push(moment('2001-09-22'));
        }
    }

    onValueChange(value: Date) {
        if (value === this.dateValue || this.isInit) {
            this.isInit = false;

            return;
        }

        this.dateValue = value;
        this.getUserSlots(value);
        this.openSlots = true;
    }

    getTelemedicineCalendar(data) {
        const filter = {
            ...data,
            is_scheduler: true,
            is_microservice: true
        };

        this.slotService.showAllTelemedicineSlots(filter, (error, resp) => {
            this.enabledDatesLoading = false;
            this.datepickerInitialized = true;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.enabledDates = [];

                return;
            }

            const response = Object.keys(resp) || [];

            const dates = response.filter(date => resp[date] === 'AVAILABLE').map(date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'));

            this.setCustomDates(dates || []);
        })
    }

    getTelemedicineSlots(date, filterData) {
        filterData.is_scheduler = true;

        this.loading = true;

        this.slotService.getTelemedicineSlots(filterData, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            if (!resp.length) {
                this.slots = [];

                return;
            }

            if (!this.listedSlots[this.selectedPlace.id]) {
                this.listedSlots[this.selectedPlace.id] = {};
            }

            if (!this.listedSlots[this.selectedPlace.id][this.selectedEvent.id]) {
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id] = {};
            }

            this.openSlots = true;
            this.slots = lodash.uniqBy(resp, (item) => item.id);

            if (!this.slots.length) {
                this.alertMessageService.open('Não há horários disponíveis no dia selecionado!', null, 'warning');
                this.openSlots = false;
            }

            this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')] = this.slots;
        });
    }

    getUserSlots(date) {
        if (this.listedSlots[this.selectedPlace.id] && this.listedSlots[this.selectedPlace.id][this.selectedEvent.id] && this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')]) {
            this.slots = this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')]

            return;
        }

        this.loading = true;

        this.slotService.showAll(this.getFilter(date), (error, doctorSlots) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');

                return;
            }

            if (!doctorSlots.length) {
                this.slots = [];

                return;
            }

            if (!this.listedSlots[this.selectedPlace.id]) {
                this.listedSlots[this.selectedPlace.id] = {};
            }

            if (!this.listedSlots[this.selectedPlace.id][this.selectedEvent.id]) {
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id] = {};
            }

            this.openSlots = true;

            const slots = lodash.uniqBy(doctorSlots, item => item.id);

            if (this.doctor.config_arrival_order) {
                this.slots = [];

                const slotsGrouped = mountSlotShift(slots);

                this.slotsGroupedByShift = Object.values(slotsGrouped);
            } else {
                this.slots = slots;
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')] = slots;
            }
        });
    }

    getFilter(date) {
        const filter = {
            company_id: this.selectedPlace.company_id,
            user_id: this.doctor.id,
            place_id: this.selectedPlace.id,
            event_id: this.selectedEvent.id,
            start_date: moment(date).format('YYYY-MM-DD'),
            token: this.route.snapshot.queryParams.token,
            insurance_id: null
        };

        if (this.paymentMethod === 'Convênio' && this.selectedInsurancePlan && this.selectedInsurancePlan.id) {
            filter.insurance_id = this.selectedInsurancePlan.id;
        }

        if (this.selectedPlace.is_tele) {
            filter.place_id = this.selectedPlace.place.id;
            filter.event_id = this.selectedPlace.id;
        }

        return filter;
    }

    setSlot(slot) {
        this.selectedSlot = slot;
        this.doctor.enabledDates = this.enabledDates;

        slot.enabledDates = this.enabledDates;

        this.checkoutService.setDoctor(this.doctor);
        this.selectedSlotEvent.emit(slot);
    }

    getTimegridInfo(data) {
        return new Promise((resolve, reject) => {
            this.TimeGridService.timeGridInfo(data, (error, timeGrid) => {
                resolve(timeGrid)
            })
        });
    }

    async setShift(shift) {
        const slot = shift.slots[0];
        const timegridInfo = await this.getTimegridInfo({id: slot.timegrid_id});
        const shiftAttandenceInfo = getArrivalShift(timegridInfo, shift.label);

        slot.shift = shiftAttandenceInfo;

        this.selectedSlot = slot;
        this.doctor.enabledDates = this.enabledDates;

        slot.enabledDates = this.enabledDates;

        this.checkoutService.setDoctor(this.doctor);
        this.selectedSlotEvent.emit(slot);
    }
}
