import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'payment'
})
export class PaymentAvailablePipe implements PipeTransform {
    transform(paymentMethods: any) {
		const payments = paymentMethods || [];
		let stringView = '';
		const keyMap = {
			'INSURANCE': 'Convênio',
			'CREDITCARD': 'Cartão de crédito',
			'PARTICULAR': 'Particular'
		};

		if (!payments || !payments.length) {
			return 'INDISPONÍVEL';
		}

		if (payments.length === 1) {
			stringView = keyMap[payments[0]];
		}

		if (payments.length === 2) {
			stringView = `${keyMap[payments[0]]} e ${keyMap[payments[1]]}.`;
		}

		if (payments.length === 3) {
			stringView = `${keyMap[payments[0]]}, ${keyMap[payments[1]]} e ${keyMap[payments[2]]}.`;
		}

		return stringView;
    }
}
