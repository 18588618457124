<app-default-header></app-default-header>

<main class="bg">
    <div class="container container-background enter-left-bounce" *ngIf="searchPageService.loading || loading" style="margin-top: 40px;">
        <app-loading></app-loading>
    </div>

    <div class="no-results container container-background" *ngIf="(!searchPageService.item && !searchPageService.loading && !loading && onboardType) || (!onboardType && !searchPageService.loading && !loading)">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="text-center">
                    <img src="assets/img/not-found.png">
                    <p class="not-found--text">Nenhum resultado encontrado</p>
                    <span class="not-found-label">Token inválido ou já utilizado</span>
                </div>
            </div>
        </div>
    </div>

    <div class="container container-background" *ngIf="searchPageService.item && !searchPageService.loading && !loading && onboardType">
        <div class="d-flex flex-column text-center" style="margin-top: 16px; margin-bottom: 16px;">
            <div style="font-style: normal;font-weight: bold;font-size: 24px;line-height: 115%;">
                Que bom te ver aqui, {{ patientName }}!
            </div>

            <div style="font-style: normal;font-weight: normal;font-size: 16px;line-height: 150%;">
                Agende seu atendimento.
            </div>
        </div>

        <app-web-company-card [company]="searchPageService.item" *ngIf="!searchPageService.isDoctor"></app-web-company-card>

        <app-doctor-card-slots [doctor]="searchPageService.item" *ngIf="searchPageService.isDoctor"></app-doctor-card-slots>
    </div>
</main>
