import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';

import { CheckoutService, AlertMessageService } from './../../../shared/services';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-mobile-calendar-page',
    templateUrl: './calendar-page.component.html',
    styleUrls: ['./calendar-page.component.scss'],
})
export class CalendarPageComponent implements OnInit {
    doctorData;
    place;
    slot;
    company;
    event;
    dateCustomClasses: DatepickerDateCustomClasses[] = [];
    enabledDates = [
        moment('2001-09-16')
    ];
    slotSelected;

    constructor(
        private route: ActivatedRoute,
        public checkoutService: CheckoutService,
        private router: Router,
        private alertMessageService: AlertMessageService
    ) { }

    ngOnInit() {
        this.company = this.checkoutService.getCompany();
        this.place = this.checkoutService.getPlace();
        this.event = this.checkoutService.getEvent();
        this.slot = this.checkoutService.getSlot();

        if (!this.checkoutService.getDoctor() || (this.checkoutService.getPlace() && !this.checkoutService.getPlace().is_tele && !this.checkoutService.getEvent()) || !this.checkoutService.getPlace()) {
            this.redirectToCardInfo();

            return;
        }

        if (this.slot) {
            this.slotSelected = {...this.slot};
        }

        this.doctorData = this.checkoutService.getDoctor();
    }

    saveSlot() {
        if (!this.slotSelected) {
            this.alertMessageService.open('Selecione um horário para prosseguir!', null, 'warning');

            return;
        }

        this.checkoutService.setSlot(this.slotSelected);
        this.redirectToCardInfo();
    }

    redirectToCardInfo() {
        this.router.navigate(['/mobile/card-info/'], { queryParamsHandling: 'preserve' });
    }

    selectedSlot(event) {
        this.slotSelected = event;
    }
}
