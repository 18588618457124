export const pagseguroMessages = {
    10000: 'A marca do cartão de crédito é obrigatória.',
    10001: 'O comprimento do email está inválido.',
    10003: 'Email inválido.',
    10005: 'As contas de vendedor e comprador não podem se relacionar.',
    10009: 'Método de pagamento indisponível.',
    10020: 'Método de pagamento inválido.',
    10021: 'Erro ao buscar as informações da conta do vendedor.',
    10023: 'Método de pagamento indisponível.',
    10024: 'O comprador precisa estar registrado.',
    10025: 'Nome do comprador não pode estar vazio.',
    10026: 'Email do comprador não pode estar vazio.',
    10049: 'Nome do comprador é obrigatório.',
    10050: 'Email do comprador é obrigatório.',
    11002: 'O comprimento da conta do vendedor está inválido.',
    // 11006: 'redirectURL invalid length:',
    // 11007: 'redirectURL invalid value:',
    11008: 'O comprimento da referência está inválido.',
    11013: 'Código de área do comprador inválido.',
    11014: 'Telefone do comprador inválido.',
    11027: 'Quantidade de itens fora do alcance.',
    11028: 'Valor do item é obrigatório.',
    11040: 'Idade máxima precisa ser um número inteiro',
    11041: 'Idade máxima fora do intervalo.',
    // 11042: 'maxUses invalid pattern:. Must be an integer.',
    // 11043: 'maxUses out of range:',
    // 11054: 'abandonURL/reviewURL invalid length:',
    // 11055: 'abandonURL/reviewURL invalid value:',
    11071: 'Data inicial da pré aprovação está inválida.',
    11072: 'Data final da pré aprovação está inválida.',
    11084: 'O vendedor não possui a opção de cartão de crédito ativa.',
    11101: 'Data da pré aprovação é obrigatória.',
    11163: 'Você precisa configurar a URL de notificações de transações antes de utilizar este serviço.',
    11211: 'Pré aprovação não pode ser paga duas vezes no mesmo dia.',
    13005: 'Data inicial deve ser menor que a o limite permitido.',
    13006: 'Data inicial não deve ser maior que 180 dias.',
    13007: 'Data inicial deve ser menor ou igual a data final.',
    13008: 'Intervalo de pesquisa deve ser menor ou igual a 30 dias.',
    13009: 'Data final deve ser menor que o limite permitido.',
    13010: 'O formato da data inicial está inválido.',
    13011: 'O formato da data final está inválido.',
    13014: 'Máxmio de resultados por página. Deve ser entre 1 a 1000.',
    13017: 'Data inicial e data final são obrigatórias quando se pesquisa por intervalos.',
    13018: 'O intervalo deve ser entre 1 e 30.',
    13019: 'O intervalo de notificação é obrigatório.',
    13020: 'Página é maior que o total do número de páginas retornadas.',
    13023: 'O comprimento mínimo da referência é 1.',
    13024: 'O comprimento máximo da referência é 255.',
    17008: 'Pré aprovação não foi encontrada.',
    17022: 'Status da pré aprovação está inválido.',
    17023: 'Vendedor não possui a opção de cartão de crédito.',
    17032: 'Vendedor inválido para realizar o checkout. Verifique a conta do vendedor e se é, de fato, uma conta de vendedor.',
    17033: 'preApproval.paymentMethod isnt must be the same from pre-approval.',
    17035: 'Formato dos dias de pagamento está inválido.',
    17036: 'Valor dos dias de pagamento inválido. Qualquer valor de 1 a 120 é permitido.',
    17037: 'Dias de pagamento precisam ser menores que os dias de expiração.',
    17038: 'Formato dos dias de expiração estão inválidos.',
    17039: 'Valor da expiração inválido. Qualquer valor de 1 a 120 é permitido.',
    17061: 'O plano não foi encontrado.',
    17065: 'Documentos são obrigatórios.',
    17066: 'Quantidade de documentos inválida.',
    17067: 'Tipo de método de pagamento é obrigatório.',
    17068: 'Tipo de método de pagamento está inválido.',
    17069: 'Telefone é obrigatório',
    17070: 'Endereço é obrigatório',
    17071: 'Comprador é obrigatório.',
    17072: 'Método de pagamento é obrigatório.',
    17073: 'Cartão de crédito é obrigatório.',
    17074: 'Proprietário do cartão de crédito é obrigatório.',
    17075: 'Token do cartão de crédito inválido.',
    17078: 'Data de expiração alcançada.',
    17079: 'Limite de usos excedida.',
    17080: 'A pré aprovação está suspensa.',
    17081: 'O pedido de pré aprovação não foi encontrado.pre-approval payment order not found.',
    17082: 'O pedido de pré aprovação de pagamento inválido. Peça o status, a fim de excetuar a operação requerida.',
    // 17083: 'Pre-approval is already.',
    17093: 'O hash do comprador é obrigatório.',
    17094: 'Não pode haver novas inscrições a um plano invativo.',
    19001: 'Código postal inválido.',
    19002: 'O comprimento do nome da rua está inválido.',
    19003: 'O comprimento do número do imóvel está inválido.',
    19004: 'O comprimento do complemento está inválido.',
    19005: 'O comprimento do nome do bairro está inválido.',
    19006: 'O comprimento do nome da cidade está inválido.',
    19007: 'O estado está inválido. Deve-se seguir o padrão (e. g. "SP").',
    19008: 'O comprimento do nome do país está inválido.',
    19014: 'Telefone do comprador inválido.',
    19015: 'País inválido.',
    50103: 'O código postal é obrigatório.',
    50105: 'O número do imóvel é obrigatório.',
    50106: 'O bairro é obrigatório.',
    50107: 'O país é obrigatório.',
    50108: 'A cidade é obrigatória.',
    50131: 'O endereço de IP está inválido.',
    50134: 'A rua precisa ser preenchida.',
    53033: 'Estado é obrigatório',
    53037: 'O token do cartão de crédito é obrigatório.',
    53042: 'O nome do proprietário do cartão é obrigatório.',
    53047: 'A data de nascimento do proprietário do cartão é obrigatória.',
    53048: 'Data de nascimento do proprietário do cartão está inválida.',
    53064: 'Estado de cobrança é obrigatório.',
    53084: 'Recebedor inválido! Verifique se é uma conta de vendedor.',
    53142: 'Token do cartão de crédito inválido.',
    53151: 'Valor do desconto não pode ficar vazio.',
    53152: 'Valor do desconto fora de alcance. Para descontos percentuais, o valor precisa ser maior ou igual a 0.00 e menor ou igual a 100.',
    53153: 'Não foi encontrado o próximo pagamento para esta pré aprovação.',
    53154: 'O status não pode estar vazio.',
    53155: 'Tipo de desconto é obrigatório.',
    53156: 'Tipo de desconto está inválido.',
    53157: 'Desconto inválido. O desconto precisa ser maior ou igual a 0.00 e menor ou igual ao valor total do pagamento.',
    53158: 'Valor do desconto é obrigatório.',
    53091: 'Hash do comprador inválido.',
    57038: 'Estado é obrigatório.',
    61007: 'Tipo do documento é obrigatório.',
    61008: 'Tipo do documento é inválido.',
    61009: 'Valor do documento é obrigatório.',
    61010: 'Valor do documento inválido',
    61011: 'CPF inválido',
    61012: 'CNPJ inválido.'
};
