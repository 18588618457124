import { FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';

// custom validator to check that two fields match
export function DateCard(controlName: string) {
    return (formGroup: FormGroup) => {
        const today = moment().format('MMYY')
        const control = formGroup.controls[controlName];
            if (control.errors && !control.errors.isInvalidDateCard) {
                return
            }

            if (control.value && (control.value < today)) {
                control.setErrors({
                    isInvalidDateCard: true
                });
                return;
            }

            control.setErrors(null);
    }
}
