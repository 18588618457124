<div class="company-card company-card-list">
    <div class="col-md-1">
        <img src="https://via.placeholder.com/50x50" class="company-card-header-logo">
    </div>
    <div class="row">
        <span class="margin-left company-name-style text-truncate">{{ company.name }}</span>
        <span class="verified-icon"><img src="assets/img/verified-icon.png"></span>
    </div>
    <div class="row mb-4">
        <div class="col-md-12 pl-0 mb-2">
            <span class="address">{{ company | address }}</span>
        </div>
    </div>
    <div class="row company-card-footer justify-content-between">
        <div class="col-12 col-sm-8 col-md-12 col-lg-8 text-lg-left text-sm-left text-md-center text-center">
            <p class="company-card-footer-doctors">
                <span *ngIf="company.total_members">
                    {{ company.total_members }}

                    profissiona<span *ngIf="company.total_members === 1">l</span><span *ngIf="company.total_members > 1">is</span>
                </span>
                <span *ngIf="!company.total_members">Nenhum profissional</span>
                    nesta unidade
            </p>
        </div>
        <div class="select col-12 col-sm-4 col-md-12 col-lg-4 text-center text-sm-right text-lg-right text-md-center">
            <a [routerLink]="['/c', company.company_url]">
                <button type="button" class="btn btn-primary p-0">Selecionar</button>
            </a>
        </div>
    </div>
</div>
