import { NgModule } from '@angular/core';
import { SanitizeHtmlPipe } from './sanitize.pipe';

@NgModule({
    declarations: [
        SanitizeHtmlPipe,
    ],
    exports: [
        SanitizeHtmlPipe,
    ]
})
export class SanitizePipeModule { }
