import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskModule } from 'ngx-mask';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ng2-tooltip-directive';

import {
    UtilsFormValidator
} from './helpers';

import {
    AlertMessageService, SearchService, GlobalService, UtilsService,
    CheckoutService, CompanyService, SlotService,
    DependentsService, AuthService, AttendanceService,
    CloseModalService, MapsService, CepService, SuggestCitiesService, ResumeCheckoutService,
    ExternalService, PatientService, LeadService, TimeGridService
} from './services';

import {
    LayoutDefaultModule, SuggestCitiesModule
} from './components';

@NgModule({
    declarations: [
        // Components
    ],
    imports: [
        CommonModule,

        // Libs Modules
        ToastrModule.forRoot({
            maxOpened: 2,
            preventDuplicates: true,
            closeButton: true
        }),
        NgxMaskModule.forRoot(),
        AngularSvgIconModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AutocompleteLibModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        TooltipModule,
        BsDatepickerModule,
        LayoutDefaultModule
    ],
    exports: [
        // Components
        SuggestCitiesModule,

        // Angular Modules
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,

        // Libs Modules
        ToastrModule,
        NgxMaskModule,
        AngularSvgIconModule,
        AutocompleteLibModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        TooltipModule,
        BsDatepickerModule
    ],
    providers: [
        AuthService,
        AlertMessageService,
        SearchService,
        GlobalService,
        UtilsService,
        UtilsFormValidator,
        CheckoutService,
        CompanyService,
        SlotService,
        DependentsService,
        BsModalService,
        AttendanceService,
        CloseModalService,
        MapsService,
        CepService,
        SuggestCitiesService,
        ResumeCheckoutService,
        ExternalService,
        PatientService,
        LeadService,
        TimeGridService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
