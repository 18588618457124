import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CheckoutService {
    private doctor;
    private event;
    private place;
    private slot;
    private payment;
    private patient;
    private company;
    private itemInfo;
    private insurance;
    private plan;
    private credit_card = {
        card: {},
        owner: {},
        address: {}
    };

    constructor() {
    }

    clearAllCheckoutService() {
        this.setEvent(null)
        this.setPlace(null);
        this.setDoctor(null);
        this.setInsurance(null);
        this.setCreditCardData(null)
        this.setCreditCardOwner(null)
        this.setCreditCardOwnerAddress(null);
        this.setCompany(null);
        this.setInsurancePlan(null);
        this.setSlot(null);
        this.setPatient(null);
    }

    getSlot() {
        let slot = this.slot;

        if (!slot) {
            try {
                slot = JSON.parse(localStorage.getItem('slot'));
            } catch (err) {}
        }

        return slot;
    }

    getItemInfo() {
        let itemInfo = this.itemInfo;

        if (!itemInfo) {
            try {
                itemInfo = JSON.parse(localStorage.getItem('itemInfo'));
            } catch (err) {}
        }

        return itemInfo;
    }

    getEvent() {
        let event = this.event;

        if (!event) {
            try {
                event = JSON.parse(localStorage.getItem('event'));
            } catch (err) {}
        }

        return event;
    }

    getPlace() {
        let place = this.place;

        if (!place) {
            try {
                place = JSON.parse(localStorage.getItem('place'));
            } catch (err) {}
        }

        return place;
    }

    getDoctor() {
        let doctor = this.doctor;

        if (!doctor) {
            try {
                doctor = JSON.parse(localStorage.getItem('doctor'));
            } catch (err) {}
        }

        return doctor;
    }

    getPayment() {
        let payment = this.payment;

        if (!payment) {
            try {
                payment = JSON.parse(localStorage.getItem('payment'));
            } catch (err) {}
        }

        return payment;
    }

    getPatient() {
        let patient = this.patient;

        if (!patient) {
            try {
                patient = JSON.parse(localStorage.getItem('patient'));
            } catch (err) {}
        }

        return patient;
    }

    getCompany() {
        let company = this.company;

        if (!company) {
            try {
                company = JSON.parse(localStorage.getItem('company'));
            } catch (err) {}
        }

        return company;
    }

    getInsurance() {
        let insurance = this.insurance;

        if (!insurance) {
            try {
                insurance = JSON.parse(localStorage.getItem('insurance'));
            } catch (err) {}
        }

        return insurance;
    }

    getInsurancePlan() {
        let plan = this.plan;

        if (!plan) {
            try {
                plan = JSON.parse(localStorage.getItem('plan'));
            } catch (err) {}
        }

        return plan;
    }

    getCreditCard() {
        return this.credit_card;
    }

    setSlot(slot) {
        this.slot = slot;

        localStorage.setItem('slot', JSON.stringify(slot));
    }

    setEvent(event) {
        this.event = event;

        localStorage.setItem('event', JSON.stringify(event));
    }

    setPlace(place) {
        this.place = place;

        localStorage.setItem('place', JSON.stringify(place));
    }

    setDoctor(doctor) {
        this.doctor = doctor;

        localStorage.setItem('doctor', JSON.stringify(doctor));
    }

    setItemInfo(itemInfo) {
        this.itemInfo = itemInfo;

        localStorage.setItem('itemInfo', JSON.stringify(itemInfo));
    }

    setPayment(payment) {
        this.payment = payment;

        localStorage.setItem('payment', payment);
    }

    setPatient(patient) {
        this.patient = patient;

        localStorage.setItem('patient', JSON.stringify(patient));
    }

    setInsurance(insurance) {
        this.insurance = insurance;

        localStorage.setItem('insurance', JSON.stringify(insurance));
    }

    setInsurancePlan(plan) {
        this.plan = plan;

        localStorage.setItem('plan', JSON.stringify(plan));
    }

    setCreditCardData(cardData) {
        this.credit_card.card = cardData;
    }

    setCreditCardOwner(owner) {
        this.credit_card.owner = owner;
    }

    setCreditCardOwnerAddress(address) {
        this.credit_card.address = address;
    }

    setCompany(company) {
        this.company = company;

        localStorage.setItem('company', JSON.stringify(company));
    }
}
