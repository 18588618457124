import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {
    transform(paymentMethod: any) {
        const keyMap = {
            FREE: 'Gratuito',
            PAY_ON_DAY: 'Pagar no dia',
            INSURANCE: 'Convênio',
            CREDITCARD: 'Cartão de Crédito',
            PARTICULAR: 'Particular'
        };

        return keyMap[paymentMethod];
    }
}
