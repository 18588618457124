import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { isAfterDate, IsValidDate } from '../../../shared/helpers';
import { Router } from '@angular/router';

import { MobileCheckoutService } from './../../services/checkout.service';
import { AlertMessageService, CheckoutService } from 'src/app/shared/services';

@Component({
    selector: 'app-mobile-insurance-form',
    templateUrl: './insurance.component.html',
    styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent implements OnInit {
    loading: boolean = false;
    insuranceCardInfoForm: FormGroup;
    selectedInsurance;
    selectedInsurancePlan;
    insurances = [];
    insurancePlans = {};
    insurancesByKeys = {};
    company_id;
    doctor;
    insurancesById = {};
    previouslySelectedInsurance;

    constructor(
        private fb: FormBuilder,
        private checkoutService: CheckoutService,
        public alertMessageService: AlertMessageService,
        private router: Router,
        private mobileCheckoutService: MobileCheckoutService
    ) {}

    ngOnInit(): void {
        this.doctor = this.checkoutService.getDoctor();
        this.company_id = this.checkoutService.getPlace().company_id;
        this.previouslySelectedInsurance = this.checkoutService.getInsurance();

        this.renderForm();
        this.getDoctorKeys();

        this.insurancesByKeys = this.doctor.insurances_by_keys || {};

        if (!this.previouslySelectedInsurance || !this.previouslySelectedInsurance.insurance) {
            return;
        }

        this.setPreviousData();
    }

    setPreviousData() {
        const insuranceForm = this.mountObjectData();

        this.insuranceCardInfoForm.setValue(insuranceForm);
    }

    mountObjectData() {
        this.selectedInsurance = this.insurances.find((item) => item.id = this.previouslySelectedInsurance.plan);
        this.selectedInsurancePlan = this.insurancesByKeys[this.selectedInsurance.id].find((item) => item.id = this.previouslySelectedInsurance.insurance.id);

        return {
            insurance_id: this.previouslySelectedInsurance.plan,
            plan_id: this.previouslySelectedInsurance.insurance.id,
            card_number: this.previouslySelectedInsurance.cardNumber,
            expiration_date: this.previouslySelectedInsurance.expirationDate
        };
    }

    getDoctorKeys() {
        this.insurances = this.doctor.keys.map((key) => {
            return {
                id: key,
                key: key
            }
        });
    }

    renderForm() {
        this.insuranceCardInfoForm = this.fb.group({
            insurance_id: ['', [Validators.required]], //amil
            plan_id: ['', Validators.required], // 384
            card_number: ['', [Validators.required]],
            expiration_date: ['', [Validators.required]]
        }, {
            validators: [
                IsValidDate('expiration_date'),
                isAfterDate('expiration_date')
            ]
        });

        this.insuranceCardInfoForm.valueChanges.subscribe((status) => {
            this.checkoutService.setInsurance(null);
        });
    }

    get f() {
        return this.insuranceCardInfoForm.controls;
    }

    getSelectedInsurance() {
        const mountedInsurance = {
            insurance: null,
            plan: null,
            cardNumber: null,
            expirationDate: null
        };

        if (!this.insuranceCardInfoForm.valid) {
            this.alertMessageService.open('Preencha todos os campos!', null, 'warning');

            return;
        }

        mountedInsurance.insurance = this.insurancesByKeys[this.insuranceCardInfoForm.value.insurance_id].find((insurance) => insurance.id === this.insuranceCardInfoForm.value.plan_id);
        mountedInsurance.plan = this.insuranceCardInfoForm.value.insurance_id;
        mountedInsurance.cardNumber = this.insuranceCardInfoForm.value.card_number;
        mountedInsurance.expirationDate = this.insuranceCardInfoForm.value.expiration_date;

        return mountedInsurance;
    }

    selectInsurance(insurance) {
        if (this.selectedInsurance && this.selectedInsurance.key === insurance.key) {
            this.selectedInsurance = null;
            this.selectedInsurancePlan = null;

            return;
        }

        if (this.selectedInsurance && insurance.key !== this.selectedInsurance.key) {
            this.selectedInsurancePlan = null;
        }

        this.selectedInsurance = insurance;
        this.insuranceCardInfoForm.controls.insurance_id.setValue(insurance.id);
    }

    selectInsurancePlan(plan) {
        this.selectedInsurancePlan = plan;
        this.insuranceCardInfoForm.controls.plan_id.setValue(plan.id);
    }

    submit() {
        if (!this.insuranceCardInfoForm.valid) {
            return;
        }

        this.mobileCheckoutService.selectedPayment = true;
        this.mobileCheckoutService.payment = 'INSURANCE';
        this.checkoutService.setInsurance(this.getSelectedInsurance());

        this.router.navigate(['/mobile/resume'], { queryParamsHandling: 'merge', queryParams: { paymentType: 'INSURANCE' } });
    }
}
