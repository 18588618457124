import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CheckoutService } from 'src/app/shared/services';
import { SearchPageService } from 'src/app/desktop/services';

@Component({
    selector: 'app-company-list-page',
    templateUrl: './company-list-page.component.html'
})
export class CompanyListPageComponent implements OnInit {
    url;
    hasFilter;
    type;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public checkoutService: CheckoutService,
        public searchPageService: SearchPageService
    ) {
        // this.searchPageService.hasFilteredItemsSubject.subscribe((hasFilter) => this.hasFilter = hasFilter);
    }

    ngOnInit() {
        this.type = this.route.snapshot.queryParams.type;

        if (!this.type) {
            this.type = 'doctor';
        }

        this.listCompany();
        this.url = this.router.url;
    }

    listCompany() {
        // this.searchPageService.listByCompany(null, (error, resp) => {
        //     if (error) {
        //         this.searchPageService.items = [];

        //         return;
        //     }
        // });
    }

    goToCompany(company) {
        this.checkoutService.setCompany(company);

        this.router.navigate([`/mobile/card-info/${company.id}`]);
    }
}
