<div class="container" *ngIf="user">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12 close-icon" [routerLink]="['/mobile']">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </div>
            </div>

            <div class="row">
                <div class="col-12 hello-user-label">
                    <span>
                        Olá, {{ user.name }}
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="config-select-label c-pointer config-select-label-my-data" [routerLink]="['/mobile/configuration/user-edit']">
                        <svg-icon class="ml-2 mr-2" src="assets/svg/mobile-config-profile.svg"></svg-icon>
                        Seus dados
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="config-select-label" [routerLink]="['/mobile/configuration/dependents']">
                        <svg-icon class="ml-2 mr-2" src="assets/svg/dependents.svg"></svg-icon>
                        Dependentes
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="config-select-label c-pointer" [routerLink]="['/mobile/configuration/attendance-history']">
                        <svg-icon class="ml-2 mr-2" src="assets/svg/attendance-history.svg"></svg-icon>
                        Histórico de agendamentos
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12" (click)="logout()">
                    <div class="config-select-label c-pointer">
                        <svg-icon class="ml-2 mr-2" src="assets/svg/sign-out.svg"></svg-icon>
                        Sair da conta
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
