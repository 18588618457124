import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertMessageService, AuthService } from '../../services';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    loading: boolean = false;
    loginForm: FormGroup;

    constructor(
        public fb: FormBuilder,
        public router: Router,
        public route: ActivatedRoute,
        public alertMessageService: AlertMessageService,
        public authService: AuthService,
        public recaptchaService: ReCaptchaV3Service
    ) { }

    ngOnInit(): void {
        if (this.authService.isLogged()) {
            this.router.navigate(['/']);
            return;
        }

        this.renderForm();
    }

    renderForm() {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
            recaptcha: ['', [Validators.required]]
        });

        this.getRecaptchaToken('login');
    }

    get f() {
        return this.loginForm.controls;
    }

    getRecaptchaToken(action) {
        this.recaptchaService.execute(action).subscribe((token) => this.loginForm.controls.recaptcha.setValue(token));
    }

    submit() {
        let backUrl;

        if (!this.loginForm.valid) {
            this.alertMessageService.open('Informe seu email e senha!', null, 'error');
            return;
        }

        this.loading = true;

        this.authService.login(this.loginForm.value, (error, resp) => {
            if (error) {
                this.loading = false;
                this.alertMessageService.open(error.message, null, error.status);

                return;
            }

            this.authService.setUserData(resp);

            backUrl = this.route.snapshot.queryParams.backUrl;

            if (backUrl) {
                this.router.navigateByUrl(backUrl);
                return;
            }

            this.router.navigate(['/']);
        });
    }

    resolved(captchaResponse: string) {
        return captchaResponse;
    }
}
