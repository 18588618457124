<div [ngClass]="{  'height-vh': isWeb, 'ds-flex': isWeb, 'mobile-font': !isWeb, 'container': isWeb, 'bg-size': isWeb, 'bg-contain': !isWeb }" style="width: 100%; justify-content: center; align-items: center; background-color: #fff; padding: 16px;">
    <div [hidden]="!loading" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <app-loading></app-loading>
    </div>

	<div *ngIf="!loading && isValidToken && !hasSubmited" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
		<div class="m-t-16 text-center" style="overflow-wrap: break-word">
			<span style="font-size: 28px; font-weight: 700;">{{ setting && setting.name }}</span>
		</div>

		<div class="m-t-16" [ngClass]="{  'mobile-font--big': !isWeb }" style="word-break: break-word; overflow-wrap: break-word;border-bottom: 1px solid #DFE6EC; padding-bottom: 16px; text-align: center; font-size: 16px;">Preencha as informações abaixo para validarmos seu cadastro e agendar o seu atendimento</div>

		<div class="m-t-16">
			<label [ngClass]="{ 'mobile-font--big': !isWeb }" for="name">Nome</label>
			<input autocomplete="off" [(ngModel)]="patient.name" class="form-control" type="text" placeholder="Digite seu nome completo" id="name">
		</div>

        <div class="m-t-16 ds-flex flex-column input-group m-b" style="flex: 1;">
            <label [ngClass]="{ 'mobile-font--big': !isWeb, 'danger-color': errors && errors.cpf && cpfFlow === 'HIDE' }" for="cpf">CPF</label>

            <div style="position: relative;">
                <input autocomplete="off" [ngClass]="{ 'danger-input': errors && errors.cpf && cpfFlow === 'HIDE' }" [(ngModel)]="patient.cpf" mask="000.000.000-00"  class="form-control" type="text" placeholder="000.000.000-00" id="cpf" (keyup)="cpfChange()">
                <span style="position: absolute;right: 15px;top: -3px;width: 113px;display: flex;z-index: 100000;">
                    <label style="position: absolute;font-size: 11px;top: 13px;right: 16px;" for="noCpf">Cliente sem CPF</label>
                    <input *ngIf="cpfFlow === 'SHOW'" style="position: absolute;width: 15px;right: -7px;top: 3px;" id="noCpf" type="radio" [(ngModel)]="cpfFlow" value="SHOW" (click)="changeCpfFlow('HIDE', 'cpf')" class="form-control">
                    <input *ngIf="cpfFlow === 'HIDE'" style="position: absolute;width: 15px;right: -7px;top: 3px;" id="noCpf" type="radio" [(ngModel)]="cpfFlow" value="" (click)="changeCpfFlow('SHOW', 'responsible')" class="form-control">
                </span>
            </div>
        </div>

        <div class="m-t-16 ds-flex flex-column" [hidden]="cpfFlow !== 'SHOW'">
            <label [ngClass]="{ 'mobile-font--big': !isWeb, 'danger-color': errors && errors.cpf_responsible && cpfFlow === 'SHOW' }" for="responsible">CPF do responsável</label>

			<div class="input-group m-b" style="flex: 1;">
				<input type="text" [(ngModel)]="patient.cpf_responsible" [ngClass]="{ 'danger-input': errors && errors.cpf_responsible && cpfFlow === 'SHOW' }" id="responsible" mask="000.000.000-00" placeholder="000.000.000-00" (keyup)="cpfChange()" class="form-control">
			</div>
        </div>

        <div class="m-t-16">
            <label [ngClass]="{ 'mobile-font--big': !isWeb }" for="born">Data de nascimento</label>
            <input autocomplete="off" [(ngModel)]="patient.born" mask="00/00/0000"  class="form-control" type="text" placeholder="DD/MM/AAAA" id="born">
        </div>

        <div class="m-t-16">
            <label for="alergic-no" style="margin: 0" class="mr-3">
                <input style="position: relative;top: 1px; margin-right: 4px;" [(ngModel)]="patient.gender" value="Masculino" id="gender-male" type="radio" name="gender">

                Masculino
            </label>

            <label for="alergic-yes" style="margin: 0" class="mr-3">
                <input style="position: relative;top: 1px; margin-right: 4px;" [(ngModel)]="patient.gender" value="Feminino" id="gender-female" type="radio" name="gender">

                Feminino
            </label>
        </div>

        <div class="m-t-16">
            <label [ngClass]="{ 'mobile-font--big': !isWeb }" for="phone">Telefone</label>
            <input autocomplete="off" [(ngModel)]="patient.contact_cellphone" mask="(00) 00000-0000"  class="form-control" type="text" placeholder="(11) 11111-1111" id="phone">
        </div>

		<div class="m-t-16">
			<label [ngClass]="{  'mobile-font--big': !isWeb }" for="email">Email</label>
			<input autocomplete="off" [(ngModel)]="patient.email" class="form-control" type="text" placeholder="exemplo@amigoapp.com.br" id="email">
		</div>

        <div class="ds-flex m-t-20">
            <a class="text-underline" href="https://amigoapp.tech/termos-de-uso" target="_blank" style="margin-right: 16px;">
                Termos de uso
            </a>

            <a class="text-underline" href="https://amigoapp.tech/politica-de-privacidade" target="_blank">
                Política de Privacidade
            </a>
        </div>

		<div class="m-t-16 ds-flex" [ngClass]="{  'mobile-font--small': !isWeb }" style="word-break: break-word; overflow-wrap: break-word;font-size: 12px; color: #676a6c; border-bottom: 1px solid #f0f0f0; padding-bottom: 16px;">
            <input type="checkbox" name="" [(ngModel)]="patient.toggle_active" class="form-control" style="width: 16px; height: 16px;" id="accept_terms">

            <label for="accept_terms" style="margin-left: 10px; font-size: 13px;">
                Aceito os termos de uso e consentimento.
            </label>
		</div>

		<div class="m-t-16 d-flex" style="justify-content: flex-end;">
			<button class="ds-button ds-button--primary" *ngIf="isWeb" [disabled]="!patient.toggle_active" (click)="submit()">Próximo</button>
			<button class="ds-button btn-block ds-button--primary" style="height: 48px; font-size: 17px;" *ngIf="!isWeb" [disabled]="!patient.toggle_active" (click)="submit()">Próximo</button>
		</div>
	</div>

	<div *ngIf="!loading && !isValidToken" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <div class="row">
            <div class="col-md-12 col-12 text-center">
                <img src="assets/img/error-icon.png" alt="" style="width: 120px;">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                Erro!
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                Token inválido!
            </div>
        </div>
	</div>

	<div *ngIf="!loading && isValidToken && hasSubmited" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <div class="row">
            <div class="col-md-12 col-12 text-center">
                <img src="assets/img/success-icon.png" style="width: 120px;">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                Sucesso!
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                Seu cadastro foi realizado com sucesso!
            </div>
        </div>
	</div>
</div>
