<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12" style="padding-bottom: 24px; padding-top: 18px;">
                    <svg-icon src="assets/svg/close.svg" style="padding: 5px; padding-left: 0 !important;" (click)="bsModalRef.hide()"></svg-icon>
                </div>
           </div>

            <div class="row">
                <div class="col-12" style="padding-bottom: 8px;">
                    <svg-icon src="assets/svg/attendance-canceled.svg" class="icon" *ngIf="schedule.status === 'CANCELED' || schedule.status === 'MISSED'"></svg-icon>
                    <svg-icon src="assets/svg/attendance-done.svg" class="icon" *ngIf="schedule.status === 'DONE'"></svg-icon>
                    <svg-icon src="assets/svg/scheduled.svg" class="icon" *ngIf="schedule.status === 'SCHEDULED'"></svg-icon>
                    <span class="status-label" [class.green]="schedule.status === 'DONE'" [class.gray]="schedule.status === 'SCHEDULED'" [class.canceled]="schedule.status === 'MISSED' || schedule.status === 'CANCELED'" style="margin-left: 22px;">{{ schedule.status | attendance | uppercase }}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div style="border-bottom: 1px solid #f0f0f0; padding-bottom: 24px;">
                        <div class="attendance-to-label">{{ schedule.event.name }} para {{ schedule.name }}</div>
                        <div class="attendance-date-label">
                            {{ schedule.start_date | date: 'EEEE' | titlecase }},
                            {{ schedule.start_date | date: 'd' }} de {{ schedule.start_date | date: 'MMMM' }}
                            ás {{ schedule.start_date | date: 'HH:mm' : '-03:00' }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div style="padding-top: 24px; padding-bottom: 24px; border-bottom: 1px solid #f0f0f0;">
                        <app-doctor-card [doctorData]="schedule.user"></app-doctor-card>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="schedule.place">
                <div class="col-12 text-truncate" *ngIf="schedule.place && schedule.event.is_tele">
                    <div class="text-truncate" style="padding-top: 24px; padding-bottom: 24px; border-bottom: 1px solid #f0f0f0;">
                        <svg-icon src="assets/svg/telemedicine.svg" style="position: absolute; top: 24px;"></svg-icon>
                        <span class="place-address text-truncate" style="margin-left: 25px;">
                            Atendimento online
                        </span>
                    </div>
                </div>
                <div class="col-12 text-truncate" title="{{ schedule.place | address }}" *ngIf="schedule.place && !schedule.event.is_tele">
                    <div class="text-truncate" style="padding-top: 24px; padding-bottom: 24px; border-bottom: 1px solid #f0f0f0;">
                        <svg-icon src="assets/svg/location-gray.svg" style="position: absolute; top: 26px;"></svg-icon>
                        <span class="place-address text-truncate" style="margin-left: 25px;">
                            {{ schedule.place | address }}
                        </span>
                        <div class="d-block place-name text-truncate">
                            {{ schedule.company.name }} - {{ schedule.place_name }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="schedule.price">
                <div class="col-12">
                    <div class="attendance-total" style="padding-top: 24px; padding-bottom: 24px; border-bottom: 1px solid #f0f0f0;">
                        <svg-icon src="assets/svg/money.svg" style="position: absolute;"></svg-icon>
                        <span style="margin-left: 25px;">
                            Total ● R$ {{ schedule.price.toFixed(2) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
