import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IsValidDate, IsValidCPF, isBeforeDate, isAfterDate } from '../../../shared/helpers';
import { PagSeguroService, AlertMessageService, CreditcardService, CepService, CheckoutService } from '../../../shared/services';

@Component({
    selector: 'app-card-info',
    templateUrl: './card-info.component.html',
    styleUrls: ['./card-info.component.scss']
})
export class CardInfoComponent implements OnInit {
    companyUrl;
    tooltipOptions = {
        'placement': 'top',
        'show-delay': 100,
        'theme': "dark"
    };
    loading: boolean = false;
    cardInfoForm: FormGroup;
    expirationDate: Date;
    cvv_mask = '000';
    cvv_placeholder = '000';
    lastCardNumber = null;
    company_id = null;
    token = null;
    errorText = '';
    slot;

    constructor(
        private fb: FormBuilder,
        private pagSeguroService: PagSeguroService,
        private creditcardService: CreditcardService,
        private route: ActivatedRoute,
        private alertMessageService: AlertMessageService,
        private checkoutService: CheckoutService,
        private cepService: CepService
    ) { }

    ngOnInit(): void {
        this.slot = this.checkoutService.getSlot();
        this.token = this.route.snapshot.queryParams.token;
        this.getSession();
        this.renderForm();
    }

    renderForm() {
        this.cardInfoForm = this.fb.group({
            senderHash: ['', [Validators.required]],
            cardNumber: ['', [Validators.required]],
            expirationDate: ['', [Validators.required]],
            cvv: ['', [Validators.required]],
            name: ['', [Validators.required]],
            born: ['', [Validators.required]],
            cep: ['', [Validators.required]],
            address: ['', [Validators.required]],
            district: ['', [Validators.required]],
            addressNumber: ['', [Validators.required]],
            complement: [''],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            cpf: ['', [Validators.required]],
            cellphone: ['', [Validators.required]],
            brand: ['', [Validators.required]],
            cardToken: ['']
        }, {
            validators: [
                IsValidCPF('cpf'),
                IsValidDate('born'),
                IsValidDate('expirationDate', { pattern: 'MMYY' }),
                isAfterDate('expirationDate', { pattern: 'MMYY' }),
                isBeforeDate('born')
            ]
        });

        this.getSenderHash();

        this.cardInfoForm.valueChanges.subscribe((status) => {
            if (this.creditcardService.isValid && !this.cardInfoForm.valid) {
                this.creditcardService.isValid = false;
            }

            if (this.cardInfoForm.valid && !this.cardInfoForm.value.cardToken && ((this.cardInfoForm.value.cardNumber && !this.lastCardNumber) || (this.lastCardNumber && this.cardInfoForm.value.cardNumber && this.cardInfoForm.value.cardNumber !== this.lastCardNumber))) {
                this.lastCardNumber = this.cardInfoForm.value.cardNumber;

                this.pagSeguroService.getCreditCardHash(this.cardInfoForm.value, (error, resp) => {
                    if (error) {
                        // this.alertMessageService.open(this.pagSeguroService.getParsedError(error) || 'Não foi possível gerar o hash deste cartão de crédito, algo de errado aconteceu.', null, 'warning');
                        // this.errorText = 'Forma de pagamento indisponível, selecione outra.';
                        this.creditcardService.hasError = true;
                        this.creditcardService.isValid = true;

                        return;
                    }

                    this.cardInfoForm.controls.cardToken.setValue(resp);
                    this.creditcardService.isValid = this.cardInfoForm.valid;
                });
            }

            if (!this.cardInfoForm.value.cep || (this.cepService.currentCep === this.cardInfoForm.value.cep)) {
                return;
            }

            if (!this.cepService.isValidCep(this.cardInfoForm.value.cep)) {
                return;
            }

            this.cepService.getAddressByCep(this.cardInfoForm.value.cep).subscribe((cep) => {
                if (cep['erro']) {
                    this.cleanAddressValues();
                    this.alertMessageService.open('O endereço do cep digitado não foi encontrado!', null, 'warning');

                    return;
                }

                this.completeAddressValues(cep);
            });
        });
    }

    cleanAddressValues() {
        this.cardInfoForm.controls.address.setValue('');
        this.cardInfoForm.controls.district.setValue('');
        this.cardInfoForm.controls.addressNumber.setValue('');
        this.cardInfoForm.controls.complement.setValue('');
        this.cardInfoForm.controls.state.setValue('');
        this.cardInfoForm.controls.city.setValue('');
    }

    completeAddressValues(cep) {
        this.cardInfoForm.controls.address.setValue(cep['logradouro']);
        this.cardInfoForm.controls.district.setValue(cep['bairro']);
        this.cardInfoForm.controls.complement.setValue(cep['complemento']);
        this.cardInfoForm.controls.city.setValue(cep['localidade']);
        this.cardInfoForm.controls.state.setValue(this.cepService.parseStateName(cep['uf']));
    }

    formatCreditcardForm() {
        const creditcard = {
            card: {
                senderHash: this.cardInfoForm.value.senderHash,
                expirationDate: this.cardInfoForm.value.expirationDate,
                brand: this.cardInfoForm.value.brand,
                cvv: this.cardInfoForm.value.cvv,
                cardToken: this.cardInfoForm.value.cardToken
            },
            address: {
                cep: this.cardInfoForm.value.cep,
                address: this.cardInfoForm.value.address,
                district: this.cardInfoForm.value.district,
                addressNumber: this.cardInfoForm.value.addressNumber,
                complement: this.cardInfoForm.value.complement,
                city: this.cardInfoForm.value.city,
                state: this.cardInfoForm.value.state
            },
            owner: {
                name: this.cardInfoForm.value.name,
                born: this.cardInfoForm.value.born,
                cellphone: this.cardInfoForm.value.cellphone,
                cpf: this.cardInfoForm.value.cpf
            }
        };

        return creditcard;
    }

    getSession() {
        const data = {
            token: this.token
        };

        this.pagSeguroService.getSessionId(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.errorText = 'Forma de pagamento indisponível, selecione outra.';
                this.creditcardService.hasError = true;
                return;
            }

            this.pagSeguroService.setSessionId(resp);
        });
    }

    getSenderHash() {
        this.pagSeguroService.getSenderHashReady((resp) => {
            if (!resp) {
                return;
            }

            this.cardInfoForm.controls.senderHash.setValue(resp);
        });
    }

    get f() {
        return this.cardInfoForm.controls;
    }

    onCardNumberChange() {
        const number = this.f.cardNumber.value;
        let cardBrand;
        const brandRegex = {
            visa: '^4[0-9]{12}(?:[0-9]{3})',
            mastercard: '^5[1-5][0-9]{14}',
            diners: '^3(?:0[0-5]|[68][0-9])[0-9]{11}',
            elo: '^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))',
            amex: '^3[47][0-9]{13}',
            jcb: '^(?:2131|1800|35[0-9]{3})[0-9]{11}',
            hipercard: '^(606282[0-9]{10}([0-9]{3})?)|(3841[0-9]{15})',
        };

        Object.keys(brandRegex).some(function (brand) {
            const regex = new RegExp(brandRegex[brand], 'gi');

            if (regex.test(number)) {
                cardBrand = brand;
                return true;
            }

            return false;
        });

        this.cardInfoForm.controls.brand.setValue(cardBrand);
        this.cvv_mask = cardBrand === 'amex' ? '0000' : '000';
        this.cvv_placeholder = cardBrand === 'amex' ? '0000' : '000';
    }
}
