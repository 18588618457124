import { CheckoutService } from './../../../shared/services/checkout.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService, BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import * as moment from 'moment-timezone';
import { AlertMessageService, CloseModalService, SlotService } from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as lodash from 'lodash';

@Component({
    selector: 'app-modal-calendar',
    templateUrl: './modal-calendar.component.html',
    styleUrls: ['./modal-calendar.component.scss']
})
export class ModalMobileCalendarComponent extends CloseModalService implements OnInit {
    openSlots: boolean = false;
    loading: boolean = false;
    datepickerInitialized: boolean = true;
    datepickerInitializedLoading: boolean = false;
    isInit: boolean = true;

    doctor;
    event;
    place;
    insuranceInfo;
    slots = [];
    selectedSlot;
    paymentMethod;
    slot;
    selectedDate;
    now = moment();
    enabledDates = [
        moment('2001-09-19'),
    ];
    dateCustomClasses: DatepickerDateCustomClasses[] = [];
    bsConfig: Partial<BsDatepickerConfig>;
    config = {
        isAnimated: true,
        containerClass: 'card-theme',
        customTodayClass: 'current-day',
        showWeekNumbers: false,
        selectFromOtherMonth: false
    };
    options = {
        locale: 'pt-br',
        minDate: this.now.clone().add(1, 'day').toDate(),
        maxDate: this.now.clone().add(3, 'months').toDate()
    };
    dateValue: Date = this.now.clone().add(1, 'day').toDate();
    listedSlots = {};

    constructor(
        private route: ActivatedRoute,
        private localeService: BsLocaleService,
        private checkoutService: CheckoutService,
        private slotService: SlotService,
        private alertMessageService: AlertMessageService,
        public bsModalRef: BsModalRef
    ) {
        super(bsModalRef);

        this.localeService.use(this.options.locale);
        this.bsConfig = Object.assign({}, this.config);

        ptBrLocale.weekdaysShort = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
        defineLocale('pt-br', ptBrLocale);
    }

    ngOnInit(): void {
        this.selectedSlot = this.checkoutService.getSlot();
        this.event = this.checkoutService.getEvent();
        this.place = this.checkoutService.getPlace();
        this.doctor = this.checkoutService.getDoctor();
        this.insuranceInfo = this.checkoutService.getInsurancePlan();
        this.paymentMethod = this.checkoutService.getPayment();

        if (!this.event || !this.place || !this.doctor || !this.selectedSlot) {
            this.bsModalRef.hide();
            return;
        }

        this.selectedDate = moment(this.selectedSlot.start_date).toDate();
        this.setCustomDates(this.selectedSlot.enabledDates);
        this.getUserSlots(this.selectedDate);
    }

    setCustomDates(dates) {
        this.dateCustomClasses = [];
        this.enabledDates = [];

        dates.forEach((date) => {
            const dateObject = {
                date: moment(date).endOf('day').toDate(),
                classes: ['enabled-date']
            };

            this.enabledDates.push(moment(date).endOf('day'));
            this.dateCustomClasses.push(dateObject);
        });

        if (!this.enabledDates.length) {
            this.enabledDates.push(moment('2001-09-22'));
        }
    }

    onValueChange(value: Date) {
        if (this.dateValue === value) {
            return;
        }

        this.dateValue = value;
        this.getUserSlots(this.dateValue);
    }

    getFilter(date) {
        const filter = {
            company_id: this.place.company_id,
            user_id: this.doctor.id,
            place_id: this.place.id,
            event_id: this.event.id,
            start_date: moment(date).format('YYYY-MM-DD'),
            insurance_id: (this.insuranceInfo && this.insuranceInfo.insurance_id) || null,
            token: this.route.snapshot.queryParams.token
        };

        return filter;
    }

    showInexistentEventMessage() {
        this.alertMessageService.open('Selecione uma unidade antes do tipo de atendimento', null, 'warning');

        return;
    }

    getUserSlots(date) {
        this.openSlots = true;

        if (this.listedSlots[moment(date).format('YYYY-MM-DD')]) {
            this.slots = this.listedSlots[moment(date).format('YYYY-MM-DD')];
            return;
        }

        setTimeout(() => this.datepickerInitializedLoading = true);

        if (this.isInit) {
            this.datepickerInitialized = false;
        }

        this.slotService.showAll(this.getFilter(date), (error, doctorSlots) => {
            if (this.isInit) {
                this.datepickerInitialized = true;
            }

            this.datepickerInitializedLoading = false;

            if (error) {
                return;
            }

            if (!doctorSlots.length) {
                this.slots = [];

                return;
            }

            this.isInit = false;

            this.slots = lodash.uniqBy(doctorSlots, (item) => item.id) || [];
            this.listedSlots[moment(date).format('YYYY-MM-DD')] = this.slots;
        });
    }

    setSlot(slot) {
        const userId = this.doctor.id;
        slot.user_id = userId;
        slot.enabledDates = this.selectedSlot.enabledDates;
        this.selectedSlot = slot;
    }

    saveSlot() {
        this.checkoutService.setSlot(this.selectedSlot);
        this.hideModal(this.selectedSlot);
    }
}
