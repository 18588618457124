<svg-icon src="assets/svg/mobile-arrow-left.svg" style="padding: 15px;" [routerLink]="['/mobile/select-payment']" queryParamsHandling="merge"></svg-icon>
<div class="container">
    <div class="row">
        <div class="col-12">
            <form [formGroup]="insuranceCardInfoForm">
                <div class="row mt-3">
                    <div class="col-12">
                        <span class="insurance-info">Adicionar informações do convênio</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3 d-block insurance-title">Convênio</div>
                        <div class="dropdown mt-2">
                            <button class="btn text-left dropdown-select" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="dropdown-text" *ngIf="!selectedInsurance">Selecione o convênio</span>
                                <span class="dropdown-text" *ngIf="selectedInsurance">{{ selectedInsurance.key }}</span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdown">
                                <button class="dropdown-button dropdown-item" *ngFor="let insurance of insurances" (click)="selectInsurance(insurance)" [ngClass]="{ 'selected': selectedInsurance && selectedInsurance.key === insurance.key }">
                                    <span class="d-block text-truncate">
                                        <span class="insurance-name text-truncate" title="{{ insurance.key }}">{{ insurance.key }}</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3 d-block insurance-plan">Plano</div>
                        <div class="dropdown mt-2">
                            <button class="btn text-left dropdown-select" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="selectedInsurance && (insurancesByKeys[selectedInsurance.key] && insurancesByKeys[selectedInsurance.key].length)">
                                <span class="dropdown-text" *ngIf="!selectedInsurancePlan">Selecione o plano do seu convênio</span>
                                <span class="dropdown-text text-truncate" *ngIf="selectedInsurancePlan">{{ selectedInsurancePlan.name }}</span>
                            </button>
                            <button class="btn text-left dropdown-select" type="button" *ngIf="!selectedInsurance || (selectedInsurance && (insurancesByKeys[selectedInsurance.key] && !insurancesByKeys[selectedInsurance.key].length)) || (selectedInsurance && !insurancesByKeys[selectedInsurance.key])" (click)="alertMessageService.open('Não há planos disponíveis para o filtro selecionado.', null, 'warning')">
                                <span class="dropdown-text" *ngIf="!selectedInsurancePlan">Selecione o plano do seu convênio</span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdown" *ngIf="selectedInsurance">
                                <button class="dropdown-button dropdown-item"  *ngFor="let plan of insurancesByKeys[selectedInsurance.id]" (click)="selectInsurancePlan(plan)" [ngClass]="{ 'selected': selectedInsurancePlan && selectedInsurancePlan.id === plan.id }">
                                <span class="d-block">
                                        <span class="insurance-name">{{ plan.name }}</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="text-sm-left mt-3 insurance-card-number">Número da carteirinha</label>
                        <input class="form-control input-outline placeholder-white-smoke pl-0" type="text" maxlength="255" formControlName="card_number" placeholder="xxxx xxxx xxxx xxxx" autocomplete="off">
                        <div class="invalid-feedback d-block" *ngIf="f.card_number.value && f.card_number.errors">Número da carteirinha inválido</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <label class="text-sm-left mt-3 insurance-card-expiration-date">Validade</label>
                        <input class="form-control input-outline placeholder-white-smoke pl-0 input-outline-expiration-date" formControlName="expiration_date"
                            mask="00/00/0000" type="text" placeholder="00/00/0000" autocomplete="off">
                        <div *ngIf="f.expiration_date.value && f.expiration_date.errors" class="invalid-feedback d-block">Validade inválida</div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row" style="margin-top: 24px;">
        <div class="col-12">
            <button class="btn btn-primary btn-block" (click)="submit()" [disabled]="!insuranceCardInfoForm.valid">Adicionar forma de pagamento</button>
            <svg-icon src="assets/svg/arrow-right-white.svg" class="next-step-arrow-left"></svg-icon>
        </div>
    </div>
</div>