<main *ngIf="companyData && companyData.name">
    <div class="d-flex flex-row mt-2">
        <div>
            <img src="{{ companyData.thumb_url }}" style="width: 80px;height: 80px;;object-fit: cover;" alt="" *ngIf="companyData.thumb_url">
            <img src="assets/svg/company-blank.svg" style="height: 80px; width: 80px; object-fit: cover;" *ngIf="!companyData.thumb_url">
        </div>
        <div class="company-info d-block text-truncate">
            <span class="company-name">{{ companyData.name }}</span>
            <span class="company-label d-block" *ngIf="companyData.subscription">{{ companyData.subscription }}</span>
            <span class="telemedicine telemedicine-without-council d-block" *ngIf="companyData.has_tele">
                <svg-icon src="assets/svg/telemedicine.svg" class="mr-1"></svg-icon>
                TELEMEDICINA
            </span>
        </div>
    </div>
</main>
