<div class="row card-row" style="width: 100%; height: 100vh">
    <div class="offset-md-2 col-md-8 col-12 card-column" style="padding: 0 !important; border-radius: 8px; display: flex; justify-content: center; align-items: center;">
        <div class="card" style="width: 100%; max-width: 800px;">
            <div class="card-body">
                <app-loading [hidden]="!loading"></app-loading>

                <div *ngIf="formStep && !loading && isValidToken">
                    <div class="row">
                        <div class="offset-md-3 col-md-6 col-12 text-center" style="margin-top: 40px;">
                            <img src="assets/img/unsubscribe-envelop.png" style="width: 120px;">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008cff; padding-top: 10px; padding-bottom: 20px;">
                            Cancelar inscrição
                        </div>
                    </div>

                    <div class="row">
                        <div class="offset-md-3 col-md-6 col-12 text-center" style="margin-top: 10px; font-size: 16px;line-height: 150%;color: #676A6C;">
                            Olá, <strong>{{ patient.name }}!</strong>
                            <br>
                            Estamos tristes em saber que você não quer mais receber nossos emails.
                        </div>
                    </div>

                    <div class="row">
                        <div class="offset-md-3 col-md-6 col-12 text-center mt-4 mb-3">
                            <button class="btn btn-primary submit-button" (click)="submit()">Cancelar envio</button>
                        </div>
                    </div>
                </div>

                <main *ngIf="!formStep && !isValidToken && !loading">
                    <div class="row">
                        <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                            <img src="assets/img/error-icon.png" alt="" style="width: 120px;">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                            Erro!
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 col-12 text-center" style="margin-top: 40px; margin-bottom: 40px;">
                            <span style="font-size: 17px; color: #676a6c; font-weight: 400;">Seu token está inválido ou expirado!</span>
                        </div>
                    </div>
                </main>

                <main *ngIf="!formStep && !loading && isValidToken">
                    <div class="row">
                        <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                            <img src="assets/img/success-icon.png" alt="" style="width: 120px;">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                            Sucesso!
                        </div>
                    </div>

                    <div class="row">
                        <div class="offset-md-3 col-md-6 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 24px; font-size: 17px;line-height: 150%;color: #676A6C;">
                            Prezado(a) <strong>
                                {{ patient.name }},
                            </strong>
                            <br>
                            o cancelamento da sua inscrição do email foi realizada com sucesso!
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</div>
