<header class="d-flex justify-content-center align-items-center" style="background-color: white !important; padding: 16px 0; border-bottom: 1px solid #DFE6EC;">
    <div class="container">
        <div class="d-flex align-items-center">
            <div style="flex: 0.5;" (click)="backToCard()">
                <svg-icon src="assets/svg/history-back-arrow-left-gray.svg" class="mr-2 c-pointer"></svg-icon>
                <a class="history-back">Voltar para a página anterior</a>
            </div>

            <div>
                <svg-icon src="assets/svg/amigo-logo.svg" style="fill: #008cff;" [svgClass]="'amigo-logo'"></svg-icon>
            </div>
        </div>
    </div>
</header>


<app-loading [hidden]="!loading"></app-loading>

<div class="container" style="margin-bottom: 16px;" [hidden]="loading">

    <!-- <label for="creditcard" class="mr-3" *ngIf="doctor && doctor.payment_methods && doctor.payment_methods.includes('CREDITCARD') && slot && slot.price && (settings && settings.pagseguro_email && settings.pagseguro_token)">
        <input style="position: relative;top: 1px; margin-right: 3px;" id="creditcard" [(ngModel)]="inputValue" value="creditcard" type="radio" name="input-group" (change)="togglePayment('1x no cartão de crédito')">
        Cartão (rotativo)
    </label> -->

    <div class="d-flex ds-flex__justify--center justify-content-center" [ngClass]="{ 'justify-content-center': tokenInfo && (tokenInfo.type === 'missed' || tokenInfo.type === 'canceled') }" style="justify-content: center;">
        <div class="d-flex flex-column justify-content-center" style="flex: 0.5; margin-top: 32px;">
            <div style="font-weight: bold;text-align: center; font-size: 16px;line-height: 112%;">Resumo do agendamento</div>

            <div class="d-flex flex-column" style="width: 100%; padding: 24px; background-color: #f2f5f7; border-radius: 8px; margin-top: 16px;">
                <div class="d-flex flex-column">
                    <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">
                        Localização
                    </div>

                    <div class="d-flex" style="margin-top: 16px;" *ngIf="place">
                        <div style="margin-right: 16px;">
                            <img src="{{ company_logo.url }}" alt="" *ngIf="company_logo" style="width: 54px; height: 54px; object-fit: scale-down;">
                            <img src="assets/svg/company-blank.svg" alt="" *ngIf="!company_logo" style="width: 54px; height: 54px; object-fit: scale-down;">
                        </div>
                        <div class="d-flex flex-column">
                            <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">{{ place.company.name }}</div>

                            <div style="margin-top: 8px;" style="font-weight: 500;font-size: 14px;line-height: 115%;">
                                <span class="d-block" *ngIf="place.address && event && !event.is_tele">{{ place.address | address }}</span>
                                <span class="d-block" *ngIf="event && event.is_tele">Atendimento online</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column" style="margin-top: 16px;">
                    <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">
                        Profissional
                    </div>

                    <div class="d-flex text-truncate" style="max-width: 500px; margin-top: 16px;">
                        <app-doctor-card [doctorData]="doctor"></app-doctor-card>
                    </div>
                </div>

                <div class="d-flex justify-content-between" style="margin-top: 16px;">
                    <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">
                        Data
                    </div>

                    <div style="font-size: 15px;">{{ slot.start_date | date: 'dd' }} de {{ slot.start_date | date: 'MMMM' | titlecase }}<label *ngIf="!slot.shift">&nbsp;às {{ slot.start_date | date: 'HH:mm' : '-03:00' }}</label></div>
                </div>

                <div class="d-flex justify-content-between" style="margin-top: 16px;" *ngIf="slot.shift">
                    <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">
                        Horário
                    </div>

                    <div style="font-size: 15px;" *ngIf="slot.shift"> A partir das {{ slot.shift.attendance_start }} às {{ slot.shift.attendance_end }}</div>
                </div>

                <div class="d-flex justify-content-between align-items-center" style="margin-top: 16px;">
                    <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;" *ngIf="event">
                        Tipo de atendimento
                    </div>

                    <div class="d-flex justify-content-between align-items-center" style="font-weight: 300;font-size: 16px;line-height: 112%;" [ngClass]="{ 'jusitfy-content-end': !inputValue }">
                        {{ event.name }}
                    </div>
                </div>

                <div class="d-flex flex-column" style="margin-top: 16px;">
                    <div class="d-flex" style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;" [ngClass]="{ 'justify-content-between': paymentMethod !== 'Convênio' && !slot.price }">
                        <div>
                            Pagamento
                        </div>

                        <div *ngIf="paymentMethod !== 'Convênio' && !slot.price" style="font-size: 14px; font-weight: 300;">
                            {{ paymentMethod }}
                        </div>
                    </div>

                    <div class="d-flex justify-content-between text-truncate" style="max-width: 510px; margin-top: 16px;" *ngIf="paymentMethod === 'Convênio' || slot.price">
                        <div>{{ paymentMethod }}</div>
                        <div *ngIf="paymentMethod === 'Convênio' && insuranceInfo">{{ insuranceInfo.name }}</div>
                        <div *ngIf="paymentMethod !== 'Convênio' && slot.price">{{ slot.price | currency: 'BRL' }}</div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end" [hidden]="loading || !tokenInfo" style="margin-top: 34px;">
                <div class="d-flex flex-row justify-content-end" [hidden]="paymentLoading">
                </div>

                <div class="d-flex flex-row justify-content-end" [hidden]="paymentLoading">
                </div>

                <div [hidden]="tokenInfo && (tokenInfo.type === 'missed' || tokenInfo.type === 'canceled')">
                    <button class="btn btn-primary" *ngIf="inputValue && inputValue !== 'particular'" [disabled]="!inputValue || (inputValue === 'insurance') || (inputValue === 'creditcard' && !creditcardService.isValid)" (click)="submit()">
                        <span *ngIf="inputValue === 'creditcard'">
                            Pagar e agendar
                        </span>

                        <span *ngIf="inputValue !== 'creditcard'">
                            Agendar
                        </span>
                    </button>

                    <button class="btn btn-primary" *ngIf="inputValue && inputValue === 'particular'" [disabled]="!inputValue" (click)="submit()">Agendar</button>
                </div>

                <button class="btn btn-primary" *ngIf="tokenInfo && (tokenInfo.type === 'missed' || tokenInfo.type === 'canceled')" [disabled]="!tokenInfo" (click)="submit()">
                    Agendar
                </button>
            </div>
        </div>
    </div>
</div>
