<div class="d-flex" style="width: 100%;">
    <div class="card" [ngClass]="{'increase-width': openSlots }" style="margin: 0 auto; min-width: 720px;min-height: 370px;border: none !important;box-shadow: 2px 4px 14px #d7d2d2;">
        <div class="card-body" style="padding: 0;">
            <div style="width: 100%;" class="d-flex justify-content-center align-items-center">
                <app-loading style="margin-top: 40px;" [hidden]="!loading"></app-loading>
            </div>

            <div class="d-flex">
                <div class="py-4 pl-3" [hidden]="loading">
                    <div class="d-flex">
                        <div>
                            <img src="{{ company.thumb_url }}" alt="" *ngIf="company.thumb_url" style="object-fit: contain;width: 80px; height: 80px;">
                            <img src="assets/svg/company-blank.svg" style="object-fit: contain; width: 80px; height: 80px;" alt="" *ngIf="!company.thumb_url">
                        </div>
                        <div class="company-info text-truncate">
                            <span class="company-name">{{ company.name }}</span>
                            <span class="mb-2 align-middle d-block" *ngIf="company.description">{{ company.description }}</span>
                            <span class="d-block telemedicine" *ngIf="company.has_tele">
                                <svg-icon src="assets/svg/telemedicine.svg" class="button-icon"></svg-icon>
                                Telemedicina disponível
                            </span>
                        </div>
                    </div>

                    <div>
                        <div class="mt-2 d-block place">Convênio ou Particular?</div>

                        <div class="dropdown" style="width: 400px;">
                            <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #f2f5f7; color: #676a6c; border: none;">
                                <span class="dropdown-text">{{ selectedPaymentMethod || 'Selecione' }}</span>
                            </button>

                            <div class="dropdown-menu" style="padding: 10px;" aria-labelledby="dropdown">
                                <button class="dropdown-button dropdown-item" style="margin-bottom: 5px; border-radius: 5px;" (click)="changePaymentMethod('Particular')" [ngClass]="{ 'selected': selectedPaymentMethod && selectedPaymentMethod === 'Particular' }">
                                    <span class="d-block text-truncate">
                                        <span class="place place-name">Particular</span>
                                    </span>
                                </button>

                                <button class="dropdown-button dropdown-item" style="margin-bottom: 5px; border-radius: 5px;" (click)="changePaymentMethod('Convênio')" [ngClass]="{ 'selected': selectedPaymentMethod && selectedPaymentMethod === 'Convênio' }" *ngIf="company.insurance_groups && company.insurance_groups.length">
                                    <span class="d-block text-truncate">
                                        <span class="place place-name">Convênio</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectedPaymentMethod === 'Convênio' && company.insurance_groups && company.insurance_groups.length" class="d-flex" style="width: 97%;">
                        <div style="flex: 1;">
                            <div class="mt-2 d-block place">Selecione o convênio</div>

                            <div class="dropdown">
                                <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #f2f5f7; color: #676a6c; border: none;">
                                    <span class="dropdown-text">{{ (selectedInsuranceGroup && selectedInsuranceGroup.name) || 'Selecione' }}</span>
                                </button>

                                <div class="dropdown-menu" style="padding: 10px;" aria-labelledby="dropdown">
                                    <button class="dropdown-button dropdown-item" title="{{ group.name }}" style="margin-bottom: 5px; border-radius: 5px;" *ngFor="let group of company.insurance_groups" (click)="selectInsuranceGroup(group)" [ngClass]="{ 'selected': selectedInsuranceGroup && selectedInsuranceGroup.id === group.id }">
                                        <span class="d-block text-truncate">
                                            <span class="place place-name">{{ group.name }}</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style="flex: 1;">
                            <div class="mt-2 d-block place">Selecione o Plano</div>

                            <div class="dropdown">
                                <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #f2f5f7; color: #676a6c; border: none;">
                                    <span class="dropdown-text">{{ (selectedInsurancePlan && selectedInsurancePlan.name) || 'Selecione' }}</span>
                                </button>

                                <div class="dropdown-menu" style="padding: 10px;" aria-labelledby="dropdown">
                                    <button class="dropdown-button dropdown-item" title="{{ plan.preview_name || plan.name }}" style="margin-bottom: 5px; border-radius: 5px;" *ngFor="let plan of insurancePlans" (click)="selectInsurancePlan(plan)" [ngClass]="{ 'selected': selectedInsurancePlan && selectedInsurancePlan.id === plan.id }">
                                        <span class="d-block text-truncate">
                                            <span class="place place-name">{{ plan.preview_name || plan.name }}</span>
                                        </span>
                                    </button>

                                    <button class="dropdown-button dropdown-item" style="margin-bottom: 5px; border-radius: 5px;" *ngIf="!insurancePlans.length">
                                        <span class="d-block text-truncate">
                                            <span class="place place-name">Não há planos disponíveis para o filtro selecionado</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="mt-2 d-block place">Unidade</div>
                        <div class="dropdown" style="width: 400px;">
                            <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #F2F5F7; color: #676a6c; border: none;">
                                <span class="dropdown-text" *ngIf="!selectedPlace">Selecione a clínica de sua preferência</span>
                                <span class="dropdown-text" *ngIf="selectedPlace">
                                    {{ selectedPlace.name }}
                                    <span *ngIf="selectedPlace.address && selectedPlace.address.address_address && !selectedPlace.is_tele"> - {{ selectedPlace.address | address }}</span>
                                    <span *ngIf="selectedPlace.is_tele">- Atendimento online</span>
                                </span>
                            </button>

                            <div class="dropdown-menu" aria-labelledby="dropdown" style="padding: 10px;">
                                <button class="dropdown-button dropdown-item text-truncate" title="{{ place.name }}" *ngFor="let place of company.places" (click)="selectPlace(place)" [ngClass]="{ 'selected': selectedPlace && selectedPlace.id === place.id }" style="margin-bottom: 5px; border-radius: 4px;">
                                    <span class="d-block text-truncate">
                                        <svg-icon src="assets/svg/location-gray.svg" class="mr-1" *ngIf="!place.is_tele"></svg-icon>
                                        <svg-icon src="assets/svg/telemedicine.svg" class="mr-1" *ngIf="place.is_tele"></svg-icon>
                                        <span class="place place-name">{{ place.name }}</span>
                                    </span>

                                    <span class="place-address" *ngIf="place.address && place.address.address_address">{{ place.address | address }}</span>
                                    <span class="place-address" *ngIf="(!place.address || place.address && !place.address.address_address) && !place.is_tele">Endereço não informado</span>
                                    <span class="place-address" *ngIf="(!place.address || place.address && !place.address.address_address) && place.is_tele">Atendimento online</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="mt-2 d-block event">Tipo de atendimento</div>
                        <div class="dropdown" style="width: 400px;">
                            <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" *ngIf="events.length && selectedPlace" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #F2F5F7; color: #676a6c; border: none;">
                                <span class="dropdown-text" *ngIf="!selectedEvent">Selecione o tipo de atendimento</span>
                                <span class="dropdown-text text-truncate" *ngIf="selectedEvent && !selectedPlace.is_tele">{{ selectedEvent.name }}</span>
                                <span class="dropdown-text text-truncate" *ngIf="selectedEvent && selectedPlace.is_tele">Teleatendimento</span>
                            </button>

                            <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" *ngIf="!events.length || !selectedPlace" type="button" (click)="showInexistentEventMessage()" style="background-color: #F2F5F7; color: #676a6c; border: none;">
                                <span class="dropdown-text" *ngIf="!selectedEvent">Selecione o tipo de atendimento</span>
                                <span class="dropdown-text text-truncate" *ngIf="selectedEvent && selectedPlace && !selectedPlace.is_tele">{{ selectedEvent.name }}</span>
                                <span class="dropdown-text text-truncate" *ngIf="selectedEvent && selectedPlace && selectedPlace.is_tele">Teleatendimento</span>
                            </button>

                            <div class="dropdown-menu dropdown-menu-event" aria-labelledby="dropdown" *ngIf="events.length && selectedPlace && !selectedPlace.is_tele" style="padding: 10px; max-height: 133px !important;">
                                <button class="dropdown-button dropdown-item text-truncate" title="{{ event.name }}" *ngFor="let event of events" (click)="selectEvent(event)" [ngClass]="{ 'selected': selectedEvent && selectedEvent.id === event.id }" style="margin-bottom: 5px; border-radius: 4px;">
                                    <span class="d-block text-truncate">
                                        <span class="event event-name text-truncate">{{ event.name }}</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="mt-2 d-block event">Profissional</div>
                        <div class="dropdown" style="width: 400px;">
                            <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" *ngIf="doctors.length && (selectedPlace && selectedEvent)" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #F2F5F7; color: #676a6c; border: none;">
                                <span class="dropdown-text" *ngIf="!selectedDoctor">Selecione o profissional</span>
                                <span class="dropdown-text text-truncate" *ngIf="selectedDoctor">{{ selectedDoctor.name }}</span>
                            </button>

                            <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" *ngIf="!doctors.length || !selectedPlace || !selectedEvent" type="button" (click)="showInexistentDoctorMessage()" style="background-color: #F2F5F7; color: #676a6c; border: none;">
                                <span class="dropdown-text" *ngIf="!selectedDoctor">Selecione o profissional</span>
                            </button>

                            <div class="dropdown-menu dropdown-menu-doctor" aria-labelledby="dropdown" *ngIf="doctors.length && (selectedPlace && selectedEvent)" style="padding: 10px;">
                                <button class="dropdown-button dropdown-item text-truncate p-3" title="{{ doctor.name }}" *ngFor="let doctor of doctors" (click)="selectDoctor(doctor)" [ngClass]="{ 'selected': selectedDoctor && doctor.id === selectedDoctor.id }" style="margin-bottom: 5px; border-radius: 4px;">
                                    <span class="d-block">
                                        <app-doctor-card [doctorData]="doctor"></app-doctor-card>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="py-4 pr-3" [hidden]="loading">
                        <bs-datepicker-inline class="datepicker" (bsValueChange)="onValueChange($event)"
                        bsDatepicker [minDate]="options.minDate" [(ngModel)]="dateValue"
                        name="dateValue" ngDefaultControl [dateCustomClasses]="dateCustomClasses"
                        [maxDate]="options.maxDate" [bsConfig]="bsConfig" [datesEnabled]="enabledDates" *ngIf="datepickerInitialized">
                        </bs-datepicker-inline>
                    </div>

                    <div [hidden]="loading" *ngIf="openSlots && !loading && (slots.length || slotsGroupedByShift.length)" style="padding: 20px 10px; background: #f0f0f0; border-top-right-radius: 8px; border-bottom-right-radius: 8px;">
                        <div class="mb-3">
                            <span style="font-weight: bold;font-size: 16px;line-height: 112%;color: #676A6C;">{{ dateValue | date: 'EEE' | titlecase }} {{ dateValue | date: 'dd'}} de {{ dateValue | date: 'LLLL' | titlecase }}</span>
                        </div>

                        <div class="slot-group" *ngIf="selectedDoctor.config_arrival_order">
                            <div *ngFor="let shift of slotsGroupedByShift" style="max-width: 150px;">
                                <button type="button" class="btn button-slot btn-secondary" *ngIf="!selectedSlot || (selectedSlot && shift.id !== selectedSlot.id)" (click)="confirmSchedule(shift)">{{ shift.label }}</button>

                                <div class="d-flex justify-content-between" *ngIf="selectedSlot && selectedSlot.id === shift.id" style="width: 148px; margin-bottom: 10px;">
                                    <button type="button" style="width: 50%; margin-right: 5px; font-size: 10px;" class="btn button-confirm-slot" (click)="confirmSchedule(shift)">{{ shift.label }}</button>
                                    <button type="button" style="width: 50%; font-size: 10px;" class="btn btn-primary button-confirm" (click)="scheduleByShift(shift)">Confirmar</button>
                                </div>
                            </div>
                        </div>

                        <div class="slot-group" *ngIf="!selectedDoctor.config_arrival_order">
                            <div *ngFor="let slot of slots">
                                <button type="button" class="btn button-slot btn-secondary" *ngIf="!selectedSlot || (selectedSlot && slot.id !== selectedSlot.id)" (click)="confirmSchedule(slot)">{{ slot.start_date | date: 'HH:mm' : '-03:00' }}</button>

                                <div class="d-flex justify-content-between" *ngIf="selectedSlot && selectedSlot.id === slot.id" style="max-width: 148px; margin-bottom: 10px;">
                                    <button type="button" style="width: 50%; margin-right: 5px; font-size: 10px;" class="btn button-confirm-slot" (click)="confirmSchedule(slot)">{{ slot.start_date | date: 'HH:mm' : '-03:00' }}</button>
                                    <button type="button" style="width: 50%; font-size: 10px;" class="btn btn-primary button-confirm" (click)="schedule(slot)">Confirmar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
