import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CheckoutService, SearchService, AlertMessageService } from '../../../shared/services';
import { MobileSearchService } from '../../services';

@Component({
    selector: 'app-mobile-select-event-page',
    templateUrl: './select-event-page.component.html',
    styleUrls: ['./select-event-page.component.scss']
})
export class SelectEventPageComponent implements OnInit {
    selectedEvent;
    inputValue = '';
    events = [];
    backUrl;
    listEventsView = [];
    isDoctor;
    id;

    constructor(
        private checkoutService: CheckoutService,
        private mobileSearchService: MobileSearchService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private alertMessageService: AlertMessageService,
        private searchService: SearchService
    ) {}

    ngOnInit(): void {
        if (this.checkoutService.getEvent()) {
            this.selectedEvent = this.checkoutService.getEvent().id;
        }

        if (!this.checkoutService.getPlace()) {
            this.redirectToLastStep();
            return;
        }

        this.getEventList();
    }

    applyFilter() {
        if (this.inputValue) {
            this.listEventsView = this.events.filter((event) => event.name.toUpperCase().indexOf(this.inputValue.toUpperCase()) !== -1);

            return;
        }

        this.listEventsView = [...this.events];
    }

    getEventList() {
        const data = {
            place_id: this.checkoutService.getPlace().id,
            company_id: this.checkoutService.getCompany().id,
            ...this.checkoutService.getInsurancePlan()?.id ? { insurance_id: this.checkoutService.getInsurancePlan().id } : {}
        };

        this.searchService.listEvents(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'warning');
                return;
            }

            this.events = resp;
            this.selectedEvent = null;
            this.listEventsView = [...this.events];
        });
    };

    setEvent(event) {
        this.selectedEvent = event.id;
        this.checkoutService.setSlot(null);

        if (this.checkoutService.getCompany()) {
            this.checkoutService.setDoctor(null);
        }

        if (this.checkoutService.getEvent()) {
            if (this.selectedEvent === this.checkoutService.getEvent().id) {
                this.checkoutService.setEvent(null);
            } else {
                this.checkoutService.setEvent(event);
            }

            this.redirectToLastStep();

            return;
        }

        this.checkoutService.setEvent(event);
        this.redirectToLastStep();
    }

    redirectToLastStep() {
        this.router.navigate(['/mobile/card-info/'], { queryParamsHandling: 'preserve' });
    }
}
