import { DeviceService } from './../../../shared/services/device.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExternalService } from 'src/app/shared/services';

@Component({
    selector: 'app-unsubscribe-page',
    templateUrl: './unsubscribe-page.component.html',
    styleUrls: ['./unsubscribe-page.component.scss']
})
export class UnsubscribePageComponent implements OnInit {
    formStep = true;
    email = null;
    loading = false;
    isValidToken = true;
    patient = {
        name: ''
    };

    constructor(
        private route: ActivatedRoute,
        private externalService: ExternalService,
        private deviceService: DeviceService
    ) {}

    ngOnInit() {
        this.email = this.route.snapshot.queryParams.email;

        if (this.deviceService.isWeb()) {
            this.setBodyStyle();
        }

        if (!this.email) {
            this.formStep = false;
            this.isValidToken = false;
            return;
        }

        this.validateToken();
    }

    validateToken() {
        const data = {
            email: this.email
        };

        this.loading = true;

        this.externalService.validateUnsubscribeToken(data, (error, resp) => {
            this.loading = false;

            if (error || !resp || !resp.name) {
                this.formStep = false;
                this.isValidToken = false;
                return;
            }

            this.isValidToken = true;
            this.patient = resp;
        });
    }

    setBodyStyle() {
        document.querySelector('body').style.backgroundColor = '#E5E5E5';
    }

    submit() {
        const data = {
            email: this.email
        };

        if (!this.email) {
            return;
        }

        this.loading = true;

        this.externalService.unsubscribeEmail(data, (error, resp) => {
            this.loading = false;
            this.formStep = false;

            if (error) {
                this.isValidToken = false;
                return;
            }

            this.isValidToken = true;
        });
    }
}
