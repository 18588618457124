import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AddressPipeModule, PaymentAvailablePipeModule } from 'src/app/shared/pipes';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoadingModule } from 'src/app/shared/components';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SearchPageService } from '../../services/search-page.service';
import { WebCompanyCardComponent } from './web-company-card.component';
import { DoctorCardModule } from '../../../mobile/components/doctor-card/doctor-card.module';

@NgModule({
    declarations: [
        WebCompanyCardComponent
    ],
    exports: [
        WebCompanyCardComponent
    ],
    imports: [
        RouterModule,
        BrowserModule,
        BsDatepickerModule,
        LoadingModule,
        AngularSvgIconModule,
        AddressPipeModule,
        DoctorCardModule,
        PaymentAvailablePipeModule
    ],
    providers: [
        SearchPageService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CompanyCardModule { }
