import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CheckoutService, AlertMessageService, SearchService, AttendanceService, PatientService, ResumeCheckoutService, ExternalService, CompanyService } from '../../../shared/services';
import { CreditCardService, MobileCheckoutService } from '../../services';
import { forkJoin } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalMobileCalendarComponent } from '../../modals';

@Component({
    selector: 'app-mobile-resume-checkout-page',
    templateUrl: './resume-checkout-page.component.html',
    styleUrls: ['./resume-checkout-page.component.scss']
})
export class MobileResumeCheckoutPageComponent implements OnInit {
    loading: boolean = false;
    paymentType = 'PARTICULAR';
    procedurePayments;
    selectedPaymentData;
    company_logo = null;
    doctor = null;
    event = null;
    token = null;
    place = null;
    slot = null;
    tokenInfo = null;
    patient = {
        id: null,
        name: '',
        email: '',
        user_name: ''
    };

    constructor (
        private checkoutService: CheckoutService,
        private mobileCheckoutService: MobileCheckoutService,
        private alertMessageService: AlertMessageService,
        public creditcardService: CreditCardService,
        private searchService: SearchService,
        private router: Router,
        private route: ActivatedRoute,
        private attendanceService: AttendanceService,
        private bsModalService: BsModalService,
        private patientService: PatientService,
        private resumeCheckoutService: ResumeCheckoutService,
        private externalService: ExternalService,
        private companyService: CompanyService
    ) { }

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams.token;
        this.doctor = this.checkoutService.getDoctor();
        this.place = this.checkoutService.getPlace();
        this.slot = this.checkoutService.getSlot();
        this.event = this.checkoutService.getEvent();
        this.company_logo = this.companyService.getCompanyLogo();
        this.getSelectedPayment();

        if (!this.doctor || !this.place || !this.slot || !this.event || !this.token) {
            this.router.navigate(['/mobile/card-info'], { queryParamsHandling: 'preserve' });
            return;
        }

        if (this.route.snapshot.queryParams.wasDesktop) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    wasDesktop: ''
                },
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });

            this.checkoutService.setSlot(null);
            this.router.navigate(['/mobile/card-info'], { queryParamsHandling: 'preserve' });
            return;
        }

        this.loading = true;

        this.getTokenInfo();
        this.getPatientInfo();
        this.getProceduresPrice();
        this.getPayments()
    }

    getTokenInfo() {
        const data = {
            token: this.token
        };

        return this.externalService.getTokenInfo(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'warning');
                return
            }

            this.tokenInfo = resp;
        });
    }

    getPatientInfo() {
        const data = {
            token: this.token
        };

        this.loading = true;

        this.patientService.patientInfo(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'danger');
                return;
            }

            this.patient = resp;
            this.checkoutService.setPatient(this.patient);
        });
    }

    backToCardInfo() {
        this.router.navigate(['/mobile/card-info'], { queryParamsHandling: 'preserve' });
    }

    navigateByUrl(url, hasQueryParams?, queryParams?) {
        const options = {
            queryParams: {}
        };

        if (hasQueryParams) {
            Object.keys(queryParams).forEach((key) => {
                options.queryParams[key] = queryParams[key];
            });
        }

        this.router.navigate([url], { queryParamsHandling: hasQueryParams ? 'merge' : 'preserve', queryParams: options.queryParams });
    }

    getProceduresPrice() {
        const data = {
            event_id: this.event.id
        };

        this.searchService.getEventsProcedures(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.procedurePayments = resp;
            this.slot.price = this.procedurePayments.price;
            this.checkoutService.setSlot(this.slot);
        });
    }

    openChangeSlotsModal() {
        this.bsModalService.show(ModalMobileCalendarComponent).content.onClose.subscribe((resp) => {
            let newSlot = {
                price: this.slot.price,
            };

            if (!resp) {
                return;
            }

            newSlot = Object.assign(resp, newSlot);

            this.slot = newSlot;
            this.checkoutService.setSlot(this.slot);
        });
    }

    getPayments() {
        const data = {
            company_id: this.place.company_id,
            event_id: this.event.id
        };

        this.searchService.listPayments(data, (error, resp) => {
            if (error) {
                this.doctor.payment_methods = [];
                this.doctor.keys = [];
                this.checkoutService.setDoctor(this.doctor);

                return;
            }

            this.doctor.keys = resp.keys;
            this.doctor.payment_methods = resp.payment_methods;
            this.doctor.insurances_by_keys = resp.insurances_by_keys;
            this.checkoutService.setDoctor(this.doctor);
        });
    }

    getSelectedPayment() {
        if (!this.paymentType) {
            return;
        }

        if (this.paymentType == 'CREDITCARD') {
            this.selectedPaymentData = this.mobileCheckoutService.getCreditCard();
            this.paymentType = 'CREDITCARD';
        }

        if (this.paymentType === 'INSURANCE') {
            this.selectedPaymentData = this.checkoutService.getInsurance();
            this.paymentType = 'INSURANCE';
        }

        if (this.paymentType === 'PARTICULAR') {
            this.selectedPaymentData = {
                label: 'Particular'
            };

            this.paymentType = 'PARTICULAR';
        }
    }

    schedule() {
        const data = {
            token: this.token,
            attendance: {
                patient: this.patient,
                place: this.place,
                event: this.event,
                company_id: this.place.company_id,
                user_id: this.doctor.id,
                slot: this.slot,
                price: this.slot.price || 0,
                description: this.event.name
            },
            payment: {
                payment_method: null
            }
        };

        // if (this.paymentType === 'CREDITCARD') {
        //     data['cardInfo'] = this.mobileCheckoutService.getCreditCard();
        //     data['cardInfo'].address.cep = data['cardInfo'].card.cep;
        //     data.payment.payment_method = 'CREDITCARD';
        // }

        if (this.paymentType === 'INSURANCE') {
            data.payment.payment_method = 'INSURANCE';
            data['insuranceCardInfo'] = this.checkoutService.getInsurance();
        }

        if (this.paymentType === 'PARTICULAR' || this.paymentType === 'CREDITCARD' || (this.tokenInfo && ['missed', 'canceled'].includes(this.tokenInfo.type))) {
            data.payment.payment_method = 'PARTICULAR';
            data.payment['missed'] = (this.tokenInfo && ['missed', 'canceled'].includes(this.tokenInfo.type)) ? true : false;
        }

        this.loading = true;

        this.attendanceService.addAttendance(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.resumeCheckoutService.attendanceId = resp.attendance_id;
            this.resumeCheckoutService.setNewAttendanceId(resp.attendance_id);
            this.router.navigate(['/mobile/scheduled-successfully/'], { queryParamsHandling: 'preserve' });
        });
    }
}
