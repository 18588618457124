import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ModalOnboardComponent } from 'src/app/desktop/modals/onboard/modal-onboard.component';
import { SearchPageService } from './../../../desktop/services/search-page.service';
import { CheckoutService, AlertMessageService, SearchService, ExternalService, ResumeCheckoutService, AttendanceService, PatientService } from '../../../shared/services';

@Component({
    selector: 'app-card-info-page',
    templateUrl: './card-info-page.component.html',
    styleUrls: ['./card-info-page.component.scss']
})
export class CardInfoPageComponent implements OnInit {
    company = null;
    place = null;
    tokenInfo = null;
    doctor = null;
    event = null;
    slot = null;
    patient = null;
    item = null;
    loading = true;
    token = null;
    paymentMethod = null;
    insuranceInfo = null;
    cardInfo = {
        places: [],
        events_by_place_id: {}
    };
    user;
    listedPaymentsProcedures = {};
    procedurePayments;
    onboardType;
    patientName = '';

    constructor (
        private router: Router,
        private route: ActivatedRoute,
        public checkoutService: CheckoutService,
        public searchService: SearchService,
        private alertMessageService: AlertMessageService,
        public searchPageService: SearchPageService,
        private bsModalService: BsModalService,
        private externalService: ExternalService,
        private resumeCheckoutService: ResumeCheckoutService,
        private attendanceService: AttendanceService,
        private patientService: PatientService
    ) { }

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;

        if (!this.token) {
            this.alertMessageService.open('Token inválido ou já utilizado.');
            return;
        }

        this.getOnboardType();
    }

    getOnboardType() {
        const data = {
            token: this.token
        };

        this.externalService.getOnboardType(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                // this.hideModal('');
                return;
            }

            this.onboardType = resp.type;
            this.patientName = resp.patient_name;
            this.listItem();
            this.getTokenInfo();
            this.getPatientInfo();
        });
    }

    openOnboardModal() {
        const modal = this.bsModalService.show(ModalOnboardComponent, {
            class: 'modal-onboard-mobile',
            backdrop: 'static',
            ignoreBackdropClick: true
        });

        modal.content.onClose.subscribe((resp) => {
            this.onboardType = resp;

            if (!this.onboardType) {
                this.onboardType = 'not available';
                return;
            }

            this.listItem();
        });
    }

    listItem() {
        this.loading = true;

        this.searchPageService.list((error, resp) => {
            this.loading = false;

            if (error) {
                return;
            }

            this.item = resp;

            this.checkoutService.setItemInfo(this.item);

            if (!this.checkoutService.getPlace() && !this.checkoutService.getEvent() && this.item.isDoctor && !this.searchPageService.hasSelectedPreviousData) {
                this.setLastAttendanceData();
                this.searchPageService.hasSelectedPreviousData = true;
                return
            }

            this.getSelectedData();
        });
    }

    setLastAttendanceData() {
        const lastAttendanceInfo = this.item.lastAttendanceInfo;

        this.doctor = this.item;

        this.checkoutService.setDoctor(this.doctor);

        if (!this.doctor.places) {
            return;
        }

        if (lastAttendanceInfo.is_tele) {
            this.place = this.doctor.places.find((item) => item.id === lastAttendanceInfo.event_id);

            this.event = this.place.place;
        } else {
            if (this.doctor.places.length) {
                this.place = this.doctor.places.find((item) => item.id === lastAttendanceInfo.place_id);
            }

            if (!this.place || !this.place.events || !this.place.events.length) {
                return;
            }

            this.event = this.place.events.find((item) => item.id === lastAttendanceInfo.event_id);
        }

        this.checkoutService.setPlace(this.place);

        if (!this.event) {
            return;
        }

        this.checkoutService.setEvent(this.event);
        this.getProceduresPrice();
    };

    getProceduresPrice() {
        const data = {
            event_id: this.event.id
        };

        if (this.place.is_tele) {
            data.event_id = this.place.id;
        }

        if (this.listedPaymentsProcedures[data.event_id]) {
            this.procedurePayments = this.listedPaymentsProcedures[data.event_id];

            return;
        }

        this.loading = true;

        this.searchService.getEventsProcedures(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.listedPaymentsProcedures[data.event_id] = resp;
            this.procedurePayments = resp;
        });
    }

    getSelectedData() {
        const searchAction = this.item.isDoctor ? 'doctor' : 'company';

        if (searchAction === 'doctor') {
            this.doctor = this.item;
            this.checkoutService.setDoctor(this.doctor);
        } else {
            this.company = this.item;
            this.checkoutService.setCompany(this.company);
        }

        this.event = this.checkoutService.getEvent();
        this.place = this.checkoutService.getPlace();

        if (this.route.snapshot.queryParams.wasDesktop) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    wasDesktop: ''
                },
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });

            this.checkoutService.setSlot(null);
        }

        this.slot = this.checkoutService.getSlot();

        this.doctor = this.checkoutService.getDoctor();
        this.company = this.checkoutService.getCompany();
        this.paymentMethod = this.checkoutService.getPayment();
        this.insuranceInfo = this.checkoutService.getInsurancePlan();

        if (this.place && this[searchAction].places.length) {
            const hasPlace = this[searchAction].places.some((place) => place.id === this.place.id);

            if (!hasPlace) {
                this.place = null;
                this.checkoutService.setPlace(this.place);
            }
        }

        if (this.place && this.event && this.place.events && this.place.events.length) {
            const hasEvent = this.place.events.some((event) => event.id === this.event.id);

            if (!hasEvent) {
                this.event = null;
                this.checkoutService.setEvent(this.event);
            }
        }

        if (this.place && this.event && this.doctor) {
            this.getProceduresPrice();
        }
    }

    showEvents() {
        if (!this.place) {
            this.alertMessageService.open('É necessário selecionar uma unidade antes do tipo de atendimento!', null, 'warning');
            return;
        }

        if (this.place.is_tele) {
            this.alertMessageService.open(`"Teleatendimento" já está selecionado!`, null, 'warning');
            return;
        }

        this.router.navigate(['/mobile/select-event'], { queryParamsHandling: 'preserve' });
    }

    redirectToSelectAddress() {
        this.router.navigate(['/mobile/select-address'], { queryParamsHandling: 'preserve' });
    }

    showDoctors() {
        if (!this.checkoutService.getPlace() || (!this.checkoutService.getEvent() && this.checkoutService.getPlace() && !this.checkoutService.getPlace().is_tele)) {
            this.alertMessageService.open('Para selecionar o profissional, é necessário escolher a unidade e o tipo de atendimento!', null, 'warning');

            return;
        }

        this.router.navigate(['/mobile/select-doctor'], { queryParamsHandling: 'preserve' });
    }

    showPaymentMethods() {
        this.router.navigate(['/mobile/select-payment'], { queryParamsHandling: 'preserve' });
    }

    showSlots() {
        if (!this.checkoutService.getPlace() || (this.checkoutService.getPlace() && !this.checkoutService.getPlace().is_tele && !this.checkoutService.getEvent()) || !this.checkoutService.getDoctor()) {
            this.alertMessageService.open('Preencha os campos anteriores, a fim de escolher o horário!', null, 'warning');

            return;
        }

        this.router.navigate(['/mobile/select-slot'], { queryParamsHandling: 'preserve' });
    }

    schedule() {
        if (this.item.isDoctor && (!this.doctor || !this.place || (this.place && !this.place.is_tele && !this.event))) {
            this.alertMessageService.open('Selecione o tipo de atendimento, a unidade e a data do atendimento para prosseguir!', null, 'warning');

            return;
        }

        if (this.place.is_tele) {
            const place = {...this.place};

            this.event = {...place};

            this.place = {...place.place};

            this.checkoutService.setPlace(this.place);
            this.checkoutService.setEvent(this.event);
        }

        // this.router.navigate(['/mobile/resume'], { queryParamsHandling: 'preserve' });

        const data = {
            token: this.token,
            attendance: {
                patient: this.patient,
                place: this.place,
                event: this.event,
                company_id: this.place.company_id,
                user_id: this.doctor.id,
                slot: this.slot,
                price: this.slot.price || 0,
                description: this.event.name,
                insurance_id: null
            },
            payment: {
                payment_method: null
            }
        };

        data.payment.payment_method = 'PARTICULAR';

        data.payment['missed'] = (this.tokenInfo && ['missed', 'canceled'].includes(this.tokenInfo.type)) ? true : false;

        if (this.insuranceInfo && this.paymentMethod === 'Convênio') {
            data.attendance.insurance_id = this.insuranceInfo.id;
            data.payment.payment_method = 'INSURANCE';
        }

        this.loading = true;

        this.attendanceService.addAttendance(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.resumeCheckoutService.attendanceId = resp.attendance_id;
            this.resumeCheckoutService.setNewAttendanceId(resp.attendance_id);
            this.router.navigate(['/mobile/scheduled-successfully/'], { queryParamsHandling: 'preserve' });
        });
    }

    getTokenInfo() {
        const data = {
            token: this.token
        };

        return this.externalService.getTokenInfo(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'warning');
                return
            }

            this.tokenInfo = resp;
        });
    }

    getPatientInfo() {
        const data = {
            token: this.token
        };

        this.patientService.patientInfo(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'danger');
                return;
            }

            this.patient = resp;
            this.checkoutService.setPatient(this.patient);
        });
    }

    isValid() {
        let isValid = true;

        if (!this.checkoutService.getPlace() || !this.checkoutService.getDoctor() || !this.checkoutService.getSlot()) {
            isValid = false;
        }

        return isValid;
    };
}
