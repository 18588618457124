<div [ngClass]="{ 'height-vh': isWeb, 'align-second-step': !isWeb && step === 2, 'mobile-font': !isWeb, 'container': isWeb, 'bg-size': isWeb, 'bg-contain': !isWeb }" style="width: 100%; display: flex; justify-content: center; align-items: center; padding: 16px;" [ngStyle]="{ 'background-color': !isWeb && '#fff', 'margin-top': isWeb && '50px' }">
    <div [hidden]="!loading" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <app-loading></app-loading>
    </div>

    <div *ngIf="!loading && !isValidToken" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <div class="row">
            <div class="col-md-12 col-12 text-center">
                <img src="assets/img/error-icon.png" alt="" style="width: 120px;">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                Erro!
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                Token inválido!
            </div>
        </div>
	</div>

    <div *ngIf="!formStep && !loading && isValidToken" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <div class="row">
            <div class="col-md-12 col-12 text-center">
                <img src="assets/img/success-icon.png" style="width: 120px;">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                Sucesso!
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                Prezado(a) <strong>{{ patient.name }},</strong>
                <br>
                a sua atualização de cadastro foi concluída com sucesso!
            </div>
        </div>
	</div>

    <div *ngIf="formStep && !loading && isValidToken" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <div [hidden]="loading">
            <div (click)="changeStep('decrease')" *ngIf="step === 2" style="margin-bottom: 10px;">
                <svg-icon src="assets/svg/history-back-arrow-left-gray.svg" class="mr-2 c-pointer"></svg-icon>
                <a class="history-back">Voltar</a>
            </div>

            <div class="row">
                <div class="col-md-12 col-12 text-left mt-3">
                    <h2 class="mobile-font mobile-font--title" style="color: #008cff;">
                        {{ companyName }}
                    </h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-12 text-left mt-4">
                    <h2 class="mobile-font" [ngClass]="{ 'mobile-font--web': isWeb, 'mobile-font--big': !isWeb }" style="color: #676a6c; font-weight: 500 !important;">
                        Atualize seus dados e
                        <br>
                        agilize seu atendimento
                    </h2>
                </div>
            </div>

            <main *ngIf="step === 1">
                <div class="row">
                    <div class="col-md-12 col-12" style="margin-top: 10px;">
                        <label for="name">Nome</label>
                        <input placeholder="Nome" type="text" class="form-control" id="name" [(ngModel)]="patient.name" autocomplete="off">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-6" style="margin-top: 10px;">
                        <label [ngClass]="{ 'text-danger': errors && errors.born }" for="born">Data de Nasc.</label>
                        <input (keyup)="changeBorn()" [ngClass]="{ 'danger-input': errors && errors.born }" class="form-control" mask="00/00/0000" type="text" [(ngModel)]="patient.born" autocomplete="off" placeholder="DD/MM/AAAA" id="born">
                    </div>

                    <div class="col-md-6 col-6" style="margin-top: 10px;">
                        <label [ngClass]="{ 'text-danger': errors && errors.cpf }" for="cpf">CPF</label>
                        <input [ngClass]="{ 'danger-input': errors && errors.cpf }" (keyup)="changeCpf()" class="form-control" mask="000.000.000-00" type="text" [(ngModel)]="patient.cpf" placeholder="000.000.000-00" id="cpf">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 col-12" style="margin-top: 10px;">
                        <label for="email">Email</label>
                        <input type="text" placeholder="exemplo@email.com" class="form-control" id="email" [(ngModel)]="patient.email" autocomplete="off">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 col-12" style="margin-top: 10px;">
                        <label for="contact_phone">Celular</label>
                        <input placeholder="(99) 9 9999-9999" type="text" class="form-control" id="contact_phone" mask="(00) 0 0000-0000" [(ngModel)]="patient.contact_cellphone" autocomplete="off">
                    </div>

                    <!-- <div class="col-md-12 col-" style="margin-top: 10px;">
                        <label for="gender">Gênero</label>
                        <select type="text" class="form-control" id="gender" formControlName="gender" autocomplete="off">
                            <option value="">Prefiro não informar</option>
                            <option value="Feminino">Feminino</option>
                            <option value="Masculino">Masculino</option>
                        </select>
                    </div> -->
                </div>

                <div class="row">
                    <div class="col-md-12 col-12" style="margin-top: 10px; display: flex; flex-direction: column;">
                        <label>Alérgico</label>

                        <div class="d-flex">
                            <label for="alergic-no" class="mr-3">
                                <input (change)="toggleAllergy('no')" style="position: relative;top: 1px; margin-right: 3px;" [(ngModel)]="patient.alergic" id="alergic-no" value="no" type="radio" name="input-group">
                                Não
                            </label>

                            <label for="alergic-yes" class="mr-3">
                                <input style="position: relative;top: 1px; margin-right: 3px;" [(ngModel)]="patient.alergic" id="alergic-yes" value="yes" type="radio" name="input-group">
                                Sim
                            </label>
                        </div>
                    </div>

                </div>

                <div class="row" *ngIf="patient.alergic === 'yes'">
                    <div class="col-md-12 col-12" style="margin-top: 10px;">
                        <label for="alergic">Alérgico a</label>
                        <textarea id="alergic" maxlength="255" class="form-control" [(ngModel)]="patient.allergy" style="color: #676a6c;"></textarea>
                    </div>
                </div>
            </main>

            <main *ngIf="step === 2" style="margin-top: 10px;">
                <div style="margin-top: 10px;" class="d-flex flex-column">
                    <div class="row">
                        <div class="col-12">
                            <label class="text-sm-left mt-1">CEP</label>
                            <input class="form-control input-outline placeholder-light-gray" [(ngModel)]="patient.address_cep" type="text" maxlength="255"
                                mask="00000-000" autocomplete="off" (keyup)="changeCep()">
                        </div>
                    </div>

                    <div class="row" style="margin-top: 16px;" *ngIf="addressFound && !loadingAddress">
                        <div class="col-md-12 flex-column" style="display: flex; padding-bottom: 16px; border-bottom: 1px solid #f0f0f0;">
                            <strong style="font-size: 16px;">Endereço encontrado <svg-icon src="assets/svg/verified-green.svg" style="margin-left: 5px;" class="icon-verified-green"></svg-icon></strong>

                            <div style="margin-top: 16px;" style="font-size: 15px; color: #A7B1C2">
                                {{ addressFound | address }}
                            </div>
                        </div>
                    </div>

                    <div class="row" [hidden]="true">
                        <div class="col-12 mt-3">
                            <label class="text-sm-left mt-1">Endereço</label>
                            <input class="form-control input-outline placeholder-light-gray" type="text" maxlength="255"
                            [(ngModel)]="patient.address_address" autocomplete="off">
                        </div>
                    </div>

                    <div class="row" [hidden]="loadingAddress" *ngIf="addressFound && patient.address_cep && patient.address_cep.length === 8">
                        <div class="col-12 mt-3" style="margin-top: 10px !important;">
                            <label class="text-sm-left mt-1">Número</label>
                            <input class="form-control input-outline placeholder-light-gray" type="text" [(ngModel)]="patient.address_number" autocomplete="off">
                        </div>
                    </div>

                    <div class="row" [hidden]="loadingAddress" *ngIf="addressFound && patient.address_cep && patient.address_cep.length === 8">
                        <div class="col-12 mt-3" style="margin-top: 10px !important;">
                            <label class="text-sm-left mt-1">Complemento</label>
                            <input class="form-control input-outline placeholder-light-gray" type="text" [(ngModel)]="patient.address_complement" autocomplete="off">
                        </div>
                    </div>

                    <!-- <div class="row mt-3" [hidden]="true">
                        <!-- <div class="col-6">
                            <label class="text-sm-left mt-1">Número</label>
                            <input class="form-control input-outline placeholder-light-gray" type="text"
                                placeholder="123" formControlName="addressNumber" autocomplete="off">
                        </div> -->
                        <!-- <div class="col-6">
                            <label class="text-sm-left mt-1">Bairro</label>
                            <input class="form-control input-outline placeholder-light-gray" type="text"
                                placeholder="Casa Forte" maxlength="255" formControlName="address_district" autocomplete="off">
                        </div> -->
                    <!-- </div> -->

                    <!-- <div class="row mt-3" [hidden]="true">
                        <div class="col-6">
                            <label class="text-sm-left mt-1">Cidade</label>
                            <input class="form-control input-outline placeholder-light-gray" placeholder="Recife"
                                type="text" maxlength="255" formControlName="address_city" autocomplete="off">
                        </div>

                        <div class="col-6">
                            <label class="text-sm-left mt-1">Estado</label>
                            <select class="form-control input-outline placeholder-light-gray city-select"
                                formControlName="address_state">
                                <option value="">Selecione</option>
                                <option value="Acre">Acre</option>
                                <option value="Alagoas">Alagoas</option>
                                <option value="Amapá">Amapá</option>
                                <option value="Amazonas">Amazonas</option>
                                <option value="Bahia">Bahia</option>
                                <option value="Ceará">Ceará</option>
                                <option value="Distrito Federal">Distrito Federal</option>
                                <option value="Espírito Santo">Espírito Santo</option>
                                <option value="Goiás">Goiás</option>
                                <option value="Maranhão">Maranhão</option>
                                <option value="Mato Grosso">Mato Grosso</option>
                                <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                                <option value="Minas Gerais">Minas Gerais</option>
                                <option value="Pará">Pará</option>
                                <option value="Paraíba">Paraíba</option>
                                <option value="Paraná">Paraná</option>
                                <option value="Pernambuco">Pernambuco</option>
                                <option value="Piauí">Piauí</option>
                                <option value="Rio de Janeiro">Rio de Janeiro</option>
                                <option value="Rio Grande do Norte">Rio Grande do Norte</option>
                                <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                                <option value="Rondônia">Rondônia</option>
                                <option value="Roraima">Roraima</option>
                                <option value="Santa Catarina">Santa Catarina</option>
                                <option value="São Paulo">São Paulo</option>
                                <option value="Sergipe">Sergipe</option>
                                <option value="Tocantins">Tocantins</option>
                            </select>
                        </div>
                    </div> -->
                </div>
            </main>

            <div class="row">
                <div class="col-md-12 col-12 text-center mt-3" *ngIf="step === 1">
                    <button class="btn btn-primary btn-mobile" (click)="changeStep('increase')">Próximo</button>
                </div>

                <div class="col-md-12 col-12 text-center mt-3" *ngIf="step === 2" style="margin-top: 24px;">
                    <button class="btn btn-primary btn-mobile" (click)="submit()">Atualizar</button>
                </div>
            </div>
        </div>
    </div>
</div>
