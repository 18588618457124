import { Component, OnInit, Input } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment-timezone';
import * as lodash from 'lodash';
import { BsLocaleService, BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

import { CheckoutService, SlotService, AlertMessageService, SearchService, AuthService, UtilsService, TimeGridService } from '../../../shared/services';

import { mountSlotShift, getArrivalShift } from '../../../shared/utils/index';

@Component({
    selector: 'app-doctor-card-slots',
    templateUrl: './doctor-card-slots.component.html',
    styleUrls: ['./doctor-card-slots.component.scss']
})
export class DoctorCardSlotsComponent implements OnInit {
    @Input() doctor;

    datepickerInitialized: boolean = true;
    enabledDatesLoading: boolean = false;
    openSlots: boolean = false;
    loading: boolean = false;

    enabledDates = [
        moment('2001-09-22')
    ];
    dateCustomClasses: DatepickerDateCustomClasses[] = [];
    selectedEvent;
    selectedDate;
    selectedPlace;
    placeWidth;
    selectedInsurancePlan = null;
    insurancePlans = [];
    selectedInsuranceGroup = null;
    selectedPaymentMethod = null;
    selectedSlot;
    slots;
    slotsGroupedByShift = [];
    companyUrl;
    procedurePayments;
    now = moment();
    bsConfig: Partial<BsDatepickerConfig>;
    config = {
        isAnimated: true,
        containerClass: 'card-theme',
        customTodayClass: 'current-day',
        showWeekNumbers: false,
        selectFromOtherMonth: false
    };
    options = {
        locale: 'pt-br',
        minDate: null,
        maxDate: null
    };
    dateValue: Date = null;
    listedPaymentsProcedures = {};
    listedSlots = {};
    user;

    constructor(
        private localeService: BsLocaleService,
        private checkoutService: CheckoutService,
        private slotService: SlotService,
        private router: Router,
        private route: ActivatedRoute,
        private alertMessageService: AlertMessageService,
        private searchService: SearchService,
        private Utils: UtilsService,
        private TimeGridService: TimeGridService
    ) {
        this.localeService.use(this.options.locale);
        ptBrLocale.weekdaysShort = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
        defineLocale('pt-br', ptBrLocale);
        this.bsConfig = Object.assign({}, this.config);
    }

    ngOnInit(): void {
        this.setLastAttendanceInfo();
        this.getCalendarConfig();
    }

    getCalendarConfig() {
        this.options.minDate = this.doctor.allow_schedule_for_current_day ? this.now.clone().toDate() : this.now.clone().add(1, 'day').toDate();
        this.options.maxDate = this.now.clone().add(this.doctor.scheduler_max_available_days, 'days').toDate();

        this.dateValue = this.options.minDate;
    }

    setLastAttendanceInfo() {
        const lastAttendanceInfo = this.doctor.lastAttendanceInfo;

        if (!lastAttendanceInfo) {
            return;
        }

        this.selectedPaymentMethod = 'Particular';

        if (lastAttendanceInfo.is_tele) {
            if (this.doctor.places.length) {
                this.selectedPlace = this.doctor.places.find((item) => item.id === lastAttendanceInfo.event_id);
            }

            if (!this.selectedPlace || !this.selectedPlace.place.events || !this.selectedPlace.place.events.length) {
                return;
            }

            this.selectedEvent = this.selectedPlace.place.events.find((item) => item.id = lastAttendanceInfo.place_id)
        } else {
            if (this.doctor.places.length) {
                this.selectedPlace = this.doctor.places.find((item) => item.id === lastAttendanceInfo.place_id);
            }

            if (!this.selectedPlace || !this.selectedPlace.events || !this.selectedPlace.events.length) {
                return;
            }

            this.selectedEvent = this.selectedPlace.events.find((item) => item.id === lastAttendanceInfo.event_id);
        }

        if (!this.selectedEvent) {
            return;
        }

        this.getEnabledDates();
        this.getProceduresPrice();
    }

    selectInsuranceGroup(group) {
        this.cleanSlotsInfo();

        if (this.selectedInsuranceGroup && this.selectedInsuranceGroup.id === group.id) {
            this.selectedInsuranceGroup = null;
            this.selectedInsurancePlan = null;
            return;
        }

        this.selectedInsuranceGroup = group;
        this.selectedInsurancePlan = null;

        this.insurancePlans = this.doctor.insurance_plans.filter(item => item.health_insurance_group_id === group.id);
    }

    selectInsurancePlan(insurance) {
        this.cleanSlotsInfo();

        if (this.selectedInsurancePlan && this.selectedInsurancePlan.id === insurance.id) {
            this.selectedInsurancePlan = null;
            return;
        }

        this.selectedInsurancePlan = insurance;
    }

    cleanSlotsInfo() {
        this.slots = [];
        this.dateCustomClasses = [];
        this.enabledDates = [moment('2001-09-22')];
        this.selectedSlot = null;
        this.selectedEvent = null;
        this.dateValue = null;
        this.openSlots = false;
        this.datepickerInitialized = false;

        setTimeout(() => this.datepickerInitialized = true);
    }

    changePaymentMethod(method) {
        this.cleanSlotsInfo();

        if (this.selectedPaymentMethod === method) {
            this.selectedPaymentMethod = null;
            this.selectedInsurancePlan = null;
            this.selectedInsuranceGroup = null;
            return;
        }

        this.selectedPaymentMethod = method;
    }

    getEnabledDates() {
        const data = {
            month_key: moment().format('YYYY-MM'),
            event_id: this.selectedEvent.id,
            company_id: this.selectedPlace.company_id,
            user_id: this.doctor.id,
            place_id: this.selectedPlace.id,
            insurance_id: this.selectedInsurancePlan && this.selectedInsurancePlan.id && this.selectedPaymentMethod === 'Convênio' ? this.selectedInsurancePlan.id : null,
            token: this.route.snapshot.queryParams.token
        };

        this.loading = true;
        this.datepickerInitialized = false;

        if (this.selectedPlace.is_tele) {
            data.event_id = this.selectedPlace.id;
            data.place_id = this.selectedPlace.place.id;
        }

        this.slotService.calendar(data, (error, resp) => {
            this.datepickerInitialized = true;
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.enabledDates = [];

                return;
            }

            this.setCustomDates(resp || {});
        });
    }

    setCustomDates(dates) {
        this.dateCustomClasses = [];
        this.enabledDates = [];

        dates.forEach((date) => {
            const now = moment();
            const dateObject = {
                date: moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).endOf('day').toDate(),
                classes: ['enabled-date']
            };

            if (moment(date, 'DD/MM/YYYY').isBefore(moment(now).clone().format('YYYY-MM-DD'))) {
                return;
            }

            this.enabledDates.push(moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).endOf('day'));
            this.dateCustomClasses.push(dateObject);
        });

        if (!this.enabledDates.length) {
            this.enabledDates.push(moment('2001-09-22'));
        }
    }

    onValueChange(value: Date) {
        if (this.dateValue === value) {
            return;
        }

        this.dateValue = value;
        this.getUserSlots(this.dateValue);
    }

    getSelectedSlotId() {
        const selectedSlot = this.checkoutService.getSlot();

        if (!selectedSlot) {
            return '';
        }

        return selectedSlot.id;
    }

    getFilter(date) {
        const filter = {
            company_id: this.selectedPlace.company_id,
            user_id: this.doctor.id,
            place_id: this.selectedPlace.id,
            insurance_id: this.selectedInsurancePlan && this.selectedInsurancePlan.id && this.selectedPaymentMethod === 'Convênio' ? this.selectedInsurancePlan.id : null,
            event_id: this.selectedEvent.id,
            start_date: moment(date).format('YYYY-MM-DD'),
            token: this.route.snapshot.queryParams.token
        };

        if (this.selectedPlace.is_tele) {
            filter.place_id = this.selectedPlace.place.id;
            filter.event_id = this.selectedPlace.id;
        }

        return filter;
    }

    showInexistentEventMessage() {
        if (this.selectedPlace.is_tele) {
            this.alertMessageService.open(`O tipo de atendimento "Teleatendimento" já está selecionado!`, null, 'warning');
            return;
        }

        if (!this.selectedPlace.events || !this.selectedPlace.events.length) {
            this.alertMessageService.open('Não há tipos de atendimento disponíveis nesta unidade', null, 'warning');
            return;
        }

        this.alertMessageService.open('Selecione uma unidade antes do tipo de atendimento', null, 'warning');
    }

    getTelemedicineCalendar(data) {
        const filter = {
            ...data,
            is_scheduler: true,
            is_microservice: true
        };

        this.slotService.showAllTelemedicineSlots(filter, (error, resp) => {
            this.loading = false;
            this.datepickerInitialized = true;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.enabledDates = [];

                return;
            }

            const response = Object.keys(resp) || [];

            const dates = response.filter(date => resp[date] === 'AVAILABLE').map(date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'));

            this.setCustomDates(dates || []);
        });
    }

    getTelemedicineSlots(date, filterData) {
        filterData.is_scheduler = true;

        this.loading = true;

        this.slotService.getTelemedicineSlots(filterData, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            if (!resp.length) {
                this.slots = [];

                return;
            }

            if (!this.listedSlots[this.selectedPlace.id]) {
                this.listedSlots[this.selectedPlace.id] = {};
            }

            if (!this.listedSlots[this.selectedPlace.id][this.selectedEvent.id]) {
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id] = {};
            }

            this.openSlots = true;
            this.slots = lodash.uniqBy(resp, (item) => item.id);

            if (!this.slots.length) {
                this.alertMessageService.open('Não há horários disponíveis no dia selecionado!', null, 'warning');
                this.openSlots = false;
            }

            this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')] = this.slots;
        });
    }

    getUserSlots(date) {
        if (this.listedSlots[this.selectedPlace.id] && this.listedSlots[this.selectedPlace.id][this.selectedEvent.id] && this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')]) {
            this.datepickerInitialized = false;
            this.openSlots = true;
            this.datepickerInitialized = true;

            if (this.doctor.config_arrival_order) {
                this.slotsGroupedByShift = this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')];
            } else {
                this.slots = this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')];
            }

            return;
        }

        this.loading = true;

        this.slotService.showAll(this.getFilter(date), (error, doctorSlots) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');

                return;
            }

            if (!doctorSlots.length) {
                this.slots = [];

                return;
            }

            if (!this.listedSlots[this.selectedPlace.id]) {
                this.listedSlots[this.selectedPlace.id] = {};
            }

            if (!this.listedSlots[this.selectedPlace.id][this.selectedEvent.id]) {
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id] = {};
            }

            this.openSlots = true;

            const slots = lodash.uniqBy(doctorSlots, item => item.id);

            if (this.doctor.config_arrival_order) {
                this.slots = [];

                this.slotsGroupedByShift = mountSlotShift(slots);

                this.slotsGroupedByShift = Object.values(this.slotsGroupedByShift);
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')] = this.slotsGroupedByShift;
            } else {
                this.slots = slots;
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')] = slots;
            }

            if (!slots.length) {
                this.alertMessageService.open('Não há horários disponíveis no dia selecionado!', null, 'warning');
            }
        });
    }

    schedule(slot) {
        const userId = this.doctor.id;
        let slotData = slot;
        const items = [this.selectedPlace, this.selectedEvent];
        let company;

        if (!slot || !this.selectedPlace || !this.selectedEvent) {
            return;
        }

        slotData = Object.assign(slotData, {
            event_id: this.selectedEvent.id,
            place_id: this.selectedPlace.id,
            user_id: userId,
        });

        company = {
            id: this.selectedPlace.company_id,
            company_name: this.selectedPlace.company_name,
            user_id: userId
        };

        if (this.selectedPlace.is_tele) {
            company.company_name = this.selectedPlace.company.name;
            slotData.place_id = this.selectedPlace.place.id;
            slotData.event_id = this.selectedPlace.id;
            this.selectedEvent = this.selectedPlace;
            this.selectedPlace = this.selectedPlace.place;
        }

        items.forEach((item) => item.user_id = userId);

        slot.price = this.procedurePayments.price;
        slot.payment_methods = this.procedurePayments.payment_methods;

        slot.insurance_id = (this.selectedInsurancePlan && this.selectedInsurancePlan.id) || null;

        this.doctor.enabledDates = this.enabledDates;
        this.doctor.paymentData = this.procedurePayments;

        this.setData(slot, this.doctor, this.selectedPlace, this.selectedEvent, company);

        this.router.navigate(['/desktop/resume'], { queryParamsHandling: 'preserve' });
    }

    async getTimegridInfo(data) {
        const response = await new Promise((resolve, reject) => {
            this.TimeGridService.timeGridInfo(data, (error, timeGrid) => {
                resolve(timeGrid)
            })
        });

        return response;
    }

    async scheduleByShift(shift) {
        const userId = this.doctor.id;
        let shiftData = shift.slots[0];
        const items = [this.selectedPlace, this.selectedEvent];
        let company;

        if (!shift || !this.selectedPlace || !this.selectedEvent) {
            return;
        }

        shiftData = Object.assign(shiftData, {
            event_id: this.selectedEvent.id,
            place_id: this.selectedPlace.id,
            user_id: userId,
        });

        company = {
            id: this.selectedPlace.company_id,
            company_name: this.selectedPlace.company_name,
            user_id: userId
        };

        if (this.selectedPlace.is_tele) {
            company.company_name = this.selectedPlace.company.name;
            shiftData.place_id = this.selectedPlace.place.id;
            shiftData.event_id = this.selectedPlace.id;
            this.selectedEvent = this.selectedPlace;
            this.selectedPlace = this.selectedPlace.place;
        }

        items.forEach((item) => item.user_id = userId);

        shiftData.price = this.procedurePayments.price;
        shiftData.payment_methods = this.procedurePayments.payment_methods;

        shiftData.insurance_id = (this.selectedInsurancePlan && this.selectedInsurancePlan.id) || null;

        this.doctor.enabledDates = this.enabledDates;
        this.doctor.paymentData = this.procedurePayments;

        this.loading = true;

        const timegridInfo = await this.getTimegridInfo({id: shiftData.timegrid_id});

        const shiftAttandenceInfo = getArrivalShift(timegridInfo, shift.label);

        this.loading = false;

        shiftData.shift = shiftAttandenceInfo

        this.setData(shiftData, this.doctor, this.selectedPlace, this.selectedEvent, company);

        this.router.navigate(['/desktop/resume'], { queryParamsHandling: 'preserve' });
    }

    setData(slot, doctor, place, event, company) {
        this.checkoutService.setSlot(slot);
        this.checkoutService.setDoctor(doctor);
        this.checkoutService.setPlace(place);
        this.checkoutService.setEvent(event);
        this.checkoutService.setCompany(company);
        this.checkoutService.setPayment(this.selectedPaymentMethod);
        this.checkoutService.setInsurancePlan(this.selectedPaymentMethod === 'Convênio' ? this.selectedInsurancePlan : null);
    }

    confirmSchedule(id) {
        if (this.selectedSlot && this.selectedSlot === id) {
            this.selectedSlot = null;

            return;
        }

        this.selectedSlot = id;
    }

    selectEvent(event) {
        if (this.selectedEvent && this.selectedEvent.id === event.id) {
            this.datepickerInitialized = false;
            this.selectedEvent = '';
            this.procedurePayments = null;
            this.enabledDates = [moment('2001-09-22')];
            this.dateCustomClasses = [];
            this.slots = [];
            this.selectedSlot = null;
            this.openSlots = false;

            setTimeout(() => this.datepickerInitialized = true, 100);
            return;
        }

        if (this.selectedEvent && this.selectedEvent.id !== event.id) {
            this.selectedEvent = '';
            this.procedurePayments = null;
            this.enabledDates = [moment('2001-09-22')];
            this.dateCustomClasses = [];
            this.slots = [];
            this.selectedSlot = null;
            this.openSlots = false;
        }

        this.selectedEvent = event;
        this.getEnabledDates();
        this.getProceduresPrice();
    }

    getProceduresPrice() {
        const data = {
            event_id: this.selectedEvent.id
        };

        if (this.listedPaymentsProcedures[data.event_id]) {
            this.procedurePayments = this.listedPaymentsProcedures[data.event_id];

            return;
        }

        if (this.selectedPlace.is_tele) {
            data.event_id = this.selectedPlace.id;
        }

        this.loading = true;

        this.searchService.getEventsProcedures(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.listedPaymentsProcedures[data.event_id] = resp;

            this.procedurePayments = resp;
        });
    }

    selectPlace(place) {
        if (this.selectedPlace && this.selectedPlace.id === place.id) {
            this.selectedPlace = '';
            this.selectedEvent = '';
            this.slots = [];
            this.selectedSlot = null;
            this.enabledDates = [moment('2001-09-22')];
            this.openSlots = false;
            this.dateCustomClasses = [];
            this.datepickerInitialized = false;
            this.procedurePayments = null;

            setTimeout(() => this.datepickerInitialized = true, 500);

            return;
        }

        if (this.selectedPlace && this.selectedPlace.id !== place.id) {
            this.selectedEvent = '';
            this.slots = [];
            this.selectedSlot = null;
            this.enabledDates = [moment('2001-09-22')];
            this.openSlots = false;
            this.dateCustomClasses = [];
            this.datepickerInitialized = false;
            this.procedurePayments = null;

            setTimeout(() => this.datepickerInitialized = true, 500);
        }

        if (place.is_tele) {
            this.selectedPlace = place;
            this.selectEvent(place);

            return;
        }

        this.selectedPlace = place;
    }
}
