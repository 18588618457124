import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { LoadingModule } from 'src/app/shared/components';

//Utils services
import { ModalMobileAttendanceCardComponent } from './mobile-modal-attendance-card.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DoctorCardModule } from '../../components';
import { AddressPipeModule, AttendancePipeModule } from 'src/app/shared/pipes';

@NgModule({
    declarations: [
        ModalMobileAttendanceCardComponent
    ],
    exports: [
        ModalMobileAttendanceCardComponent,
    ],
    imports: [
        RouterModule,
        BrowserModule,
        LoadingModule,
        AngularSvgIconModule,
        DoctorCardModule,
        AddressPipeModule,
        AttendancePipeModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MobileModalAttendanceCardModule { }
