import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CreditcardCardComponent } from './creditcard-card.component';
import { CardBrandModule } from '../../../desktop/components';
import { NgxMaskModule } from 'ngx-mask';
import { LoadingModule } from 'src/app/shared/components';

@NgModule({
    declarations: [
        CreditcardCardComponent
    ],
    exports: [
        CreditcardCardComponent
    ],
    imports: [
        BrowserModule,
        AngularSvgIconModule,
        FormsModule,
        ReactiveFormsModule,
        CardBrandModule,
        NgxMaskModule,
        LoadingModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreditcardCardModule { }
