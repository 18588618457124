import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { AlertMessageService, AuthService, AttendanceService } from '../../../shared/services';
import { Router } from '@angular/router';
import { ModalMobileAttendanceCardComponent } from '../../modals';

@Component({
    selector: 'app-attendance-history-page',
    templateUrl: './attendance-history-page.component.html',
    styleUrls: ['./attendance-history-page.component.scss']
})
export class MobileAttendanceHistoryPage implements OnInit {
    loading: boolean = false;
    schedules = [];

    constructor(
        private attendanceService: AttendanceService,
        public alertMessageService: AlertMessageService,
        private bsModalService: BsModalService,
        public location: Location,
        private authService: AuthService,
        private router: Router
    ) { }

    ngOnInit(): void {
        if (!this.authService.getUser() || !this.authService.getToken() || !this.authService.getAuth()) {
            this.router.navigate(['/mobile/']);
        }

        this.listAll();
    }

    listAll() {
        this.loading = true;

        this.attendanceService.listAll({}, (error, resp) => {
            this.loading = false;

            if (error) {
                return;
            }

            this.schedules = resp;
        });

    }

    openAttendance(schedule) {
        const initialState = {
            schedule: schedule
        };

        this.bsModalService.show(ModalMobileAttendanceCardComponent, { initialState });
    }
}
