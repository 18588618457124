import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilsFormValidator } from '../../../shared/helpers';
import { CompanyService, CheckoutService } from '../../../shared/services';

@Component({
    selector: 'app-company-page',
    templateUrl: './company-page.component.html',
    styleUrls: ['./company-page.component.scss']
})
export class CompanyPageComponent implements OnInit {
    companyUrl;
    companyInfo;
    companyEventPayments;
    companyForm: FormGroup;
    loading: boolean = true;
    eventsById = {};
    placesById = {};

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private utilsFormValidator: UtilsFormValidator,
        private companyService: CompanyService,
        private checkoutService: CheckoutService
    ) { }

    renderForm() {
        this.companyForm = this.fb.group({
            place_id: ['', [Validators.required]],
            event_id: ['', [Validators.required]]
        });
    }

    ngOnInit(): void {
        this.companyUrl = this.route.snapshot.params['company-url'];

        if (!this.companyUrl) {
            this.router.navigate(['/']);
            return;
        }

        this.renderForm();

        forkJoin([this.getCompany(), this.getCompanyEventPayments()]).subscribe((results: any) => {
            this.companyInfo = results[0].data;
            this.companyEventPayments = (results[1].data || []);

            this.formatData();

            this.loading = false;
        }, () => this.router.navigate(['/']));
    }

    get f() {
        return this.companyForm.controls;
    }

    formatData() {
        this.companyEventPayments.forEach((event) => {
            this.eventsById[event.id] = event;
        });

        if (this.companyInfo.company && this.companyInfo.company.places) {
            this.companyInfo.company.places.forEach((place) => {
                this.placesById[place.id] = place;
            });
        }
    }

    getCompany() {
        return this.companyService.get({
            company_url: this.companyUrl
        });
    }

    getCompanyEventPayments() {
        return this.companyService.getEventPayments({
            company_url: this.companyUrl
        });
    }

    submit() {
        let event;
        let place;

        if (!this.utilsFormValidator.isValid(this.companyForm)) {
            return;
        }

        event = this.eventsById[this.f.event_id.value];
        place = this.placesById[this.f.place_id.value];

        this.checkoutService.setEvent(event);
        this.checkoutService.setPlace(place);

        this.router.navigate([`c/${this.companyUrl}/slots`]);
    }
}
