import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable()
export class SlotService {
    showAll = (data, callback) => this.globalService.mountResource('GET', { url: '/slots' }, data, callback);
    calendar = (data, callback) => this.globalService.mountResource('GET', { url: '/slots/calendar' }, data, callback);
    showAllTelemedicineSlots = (data, callback) => this.globalService.mountResource('GET', { url: '/api/external/tele-calendar', micro_service: 'agendemais' }, data, callback);
    getTelemedicineSlots = (data, callback) => this.globalService.mountResource('GET', { url: '/api/external/tele-available-slots', micro_service: 'agendemais' }, data, callback);

    constructor(protected globalService: GlobalService) { }
}
