<main class="main">
    <app-loading [hidden]="!loading"></app-loading>
    <form [formGroup]="cardInfoForm" [hidden]="loading">
        <div class="row mt-3">
            <div class="col-md-12">
                <span *ngIf="(slot && slot.price)">Pagamento em 1x de <strong>{{ slot.price | currency: 'BRL' }}</strong></span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-12">
                <label class="text-sm-left">Número da cartão</label>
                <input class="form-control" (keyup)="onCardNumberChange()" type="text"
                    maxlength="19" formControlName="cardNumber" placeholder="0000 0000 0000 0000"
                    mask="0000 0000 0000 0000"
                    autocomplete="off" [ngClass]="{ 'is-valid': f.cardNumber.value && !f.cardNumber.errors && f.cardNumber.value.length > 14 && f.brand.value }">
                <app-card-brand class="card-position" *ngIf="f.cardNumber.value && f.brand.value" [cardBrand]="f.brand.value"></app-card-brand>
                <span *ngIf="f.cardNumber.value && !f.cardNumber.errors && !f.brand.value" class="invalid-feedback d-block">Número de cartão inválido.</span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-6">
                <label class="text-sm-left">Data de validade</label>
                <input class="form-control" type="text" mask="00/00" formControlName="expirationDate"
                placeholder="MM/AA" autocomplete="off" [ngClass]="{ 'is-valid': f.expirationDate.value && !f.expirationDate.errors }">
                <span *ngIf="f.expirationDate.value && f.expirationDate.errors" class="invalid-feedback d-block">Data de validade inválida!</span>
            </div>
            <div class="col-md-6">
                <label class="text-sm-left">
                    CVV
                    <span class="card-badge-info" tooltip="Código de segurança atrás do cartão" [options]="tooltipOptions">?</span>
                </label>
                <input class="form-control" [mask]="cvv_mask" type="text" formControlName="cvv" [placeholder]="cvv_placeholder" autocomplete="off" [ngClass]="{ 'is-valid': f.cvv.value && !f.cvv.errors }">
                <span class="invalid-feedback d-block" *ngIf="f.cvv.value && !f.cvv.errors"></span>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <label class="text-sm-left">Nome impresso no cartão</label>
                <input class="form-control name-input" type="text" maxlength="255" formControlName="name" autocomplete="off" [ngClass]="{ 'is-valid': f.name.value }">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <label class="text-sm-left">
                    Data de Nascimento
                    <span class="card-badge-info" tooltip="Data de nascimento do proprietário do cartão" [options]="tooltipOptions">?</span>
                </label>
                <input class="form-control" mask="00/00/0000" type="text" formControlName="born" autocomplete="off" placeholder="DD/MM/AAAA"
                [ngClass]="{ 'is-valid': f.born.value && !f.born.errors }">
                <span *ngIf="f.born.value && f.born.errors" class="invalid-feedback d-block">Data de nascimento inválida</span>
            </div>
            <div class="col-md-6">
                <label class="text-sm-left">Telefone</label>
                <input class="form-control" mask="(00) 0 0000-0000" type="text" formControlName="cellphone" autocomplete="off" placeholder="(00) 0 0000-0000" [ngClass]="{ 'is-valid': f.cellphone.value && !f.cellphone.errors }">
                <div *ngIf="f.cellphone.value && f.cellphone.errors" class="invalid-feedback d-block">Telefone inválido</div>
            </div>
        </div>
        <div class="row mt-3" style="padding-bottom: 13px;">
            <div class="col-md-12">
                <label class="text-sm-left">
                    CPF do titular
                    <span class="card-badge-info" tooltip="Número de CPF do titular do cartão" [options]="tooltipOptions">?</span>
                </label>
                <input class="form-control" mask="000.000.000-00" type="text" formControlName="cpf" autocomplete="off" placeholder="000.000.000-00" [ngClass]="{ 'is-valid': f.cpf.value && !f.cpf.errors }">
                <div *ngIf="f.cpf.value && f.cpf.errors" class="invalid-feedback d-block">CPF Inválido</div>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-md-4">
                <label class="text-sm-left mt-1">CEP</label>
                <input class="form-control" mask="00000-000" type="text" formControlName="cep" autocomplete="off" placeholder="00000-000" [ngClass]="{ 'is-valid': f.cep.value && !f.cep.errors }">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <label class="text-sm-left mt-1">Endereço</label>
                <input class="form-control" type="text" maxlength="255" formControlName="address" autocomplete="off" [ngClass]="{ 'is-valid': f.address.value && !f.address.errors }">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <label class="text-sm-left mt-1">Número</label>
                <input class="form-control" type="text" formControlName="addressNumber" autocomplete="off" [ngClass]="{ 'is-valid': f.addressNumber.value && !f.addressNumber.errors }">
            </div>
            <div class="col-md-6">
                <label class="text-sm-left mt-1">Bairro</label>
                <input class="form-control" type="text" maxlength="255" formControlName="district" autocomplete="off" [ngClass]="{ 'is-valid': f.district.value && !f.district.errors }">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <label class="text-sm-left mt-1">Complemento</label>
                <input class="form-control" type="text" maxlength="255" formControlName="complement" autocomplete="off" [ngClass]="{ 'is-valid': f.complement.value && !f.complement.errors }">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <label class="text-sm-left mt-1">Cidade</label>
                <input class="form-control" type="text" maxlength="255" formControlName="city" autocomplete="off" [ngClass]="{ 'is-valid': f.city.value && !f.city.errors }">
            </div>
            <div class="col-md-6">
                <label class="text-sm-left mt-1">Estado</label>
                <select class="form-control" formControlName="state">
                    <option value="">Selecione</option>
                    <option value="Acre">Acre</option>
                    <option value="Alagoas">Alagoas</option>
                    <option value="Amapá">Amapá</option>
                    <option value="Amazonas">Amazonas</option>
                    <option value="Bahia">Bahia</option>
                    <option value="Ceará">Ceará</option>
                    <option value="Distrito Federal">Distrito Federal</option>
                    <option value="Espírito Santo">Espírito Santo</option>
                    <option value="Goiás">Goiás</option>
                    <option value="Maranhão">Maranhão</option>
                    <option value="Mato Grosso">Mato Grosso</option>
                    <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                    <option value="Minas Gerais">Minas Gerais</option>
                    <option value="Pará">Pará</option>
                    <option value="Paraíba">Paraíba</option>
                    <option value="Paraná">Paraná</option>
                    <option value="Pernambuco">Pernambuco</option>
                    <option value="Piauí">Piauí</option>
                    <option value="Rio de Janeiro">Rio de Janeiro</option>
                    <option value="Rio Grande do Norte">Rio Grande do Norte</option>
                    <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                    <option value="Rondônia">Rondônia</option>
                    <option value="Roraima">Roraima</option>
                    <option value="Santa Catarina">Santa Catarina</option>
                    <option value="São Paulo">São Paulo</option>
                    <option value="Sergipe">Sergipe</option>
                    <option value="Tocantins">Tocantins</option>
                </select>
            </div>
        </div>
    </form>
</main>
