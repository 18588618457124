import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DeviceService implements CanActivate {
    constructor(
        private router: Router,
        private deviceService: DeviceDetectorService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const prefix = this.getPrefixRoutes();
        const prefixes = ['/desktop', '/mobile'];
        let newUrl = prefix;

        if (!prefixes.includes(state.url)) {
            if (state.url !== '/') {
                newUrl = this.clearUrl(`${prefix}${state.url}`);
            }

            this.router.navigate([newUrl], {
                queryParams: route.queryParams
            });

            return false;
        }

        return true;
    }

    isMobile() {
        return this.deviceService.isMobile();
    }

    isWeb() {
        return this.deviceService.isDesktop();
    }

    clearUrl(url) {
        let queryCharacter = url.indexOf('?');

        if (queryCharacter) {
            queryCharacter--;
        }

        return url.substr(0, queryCharacter);
    }

    getPrefixRoutes() {
        let prefix = '/desktop';

        if (this.isMobile()) {
            prefix = '/mobile';
        }

        return prefix;
    }
}
