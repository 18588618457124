import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoadingModule } from 'src/app/shared/components';
import { UnsubscribePageComponent } from './unsubscribe-page.component';

@NgModule({
    declarations: [
        UnsubscribePageComponent
    ],
    exports: [
        UnsubscribePageComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSvgIconModule,
        LoadingModule,
        RouterModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UnsubscribePageModule { }
