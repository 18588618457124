<div class="close-page-div">
    <svg-icon src="assets/svg/close.svg" class="close-icon c-pointer" (click)="redirectToLastStep()"></svg-icon>
</div>

<main>
    <div class="main-body">
        <div class="d-flex flex-row select-address">
            <span>Selecione o endereço</span>
        </div>
        <div class="overflow-address" *ngIf="places.length">
            <div class="d-flex flex-row mb-3" *ngFor="let place of places">
                <div class="card">
                    <div class="card-body" (click)="setPlace(place)" [ngClass]="{ 'active': selectedPlace === place.id }">
                        <div class="d-flex mt-3">
                            <svg-icon class="mr-2" src="assets/svg/telemedicine-gray.svg" *ngIf="place.is_tele"></svg-icon>
                            <svg-icon class="mr-2" src="assets/svg/pin.svg" style="fill: #676a6c;" *ngIf="!place.is_tele"></svg-icon>

                            <div class="place-address" *ngIf="place.is_tele">
                                Teleatendimento
                            </div>

                            <div class="place-address" *ngIf="!place.is_tele">
                                {{ place.name }}
                            </div>
                        </div>

                        <span class="d-block place-name" *ngIf="place.address && !place.is_tele">
                            {{ place.address | address }}
                        </span>
                        <div class="d-block place-name" *ngIf="place.is_tele">
                            {{ place.name }}
                        </div>
                    </div>
                </div>
            </div>
         </div>
         <div class="d-flex" *ngIf="!places.length">
            <div class="alert alert-warning" style="width: 100%;">
                Não há unidades cadastradas nesta clínica!
            </div>
         </div>
    </div>
</main>
