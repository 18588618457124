import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable()
export class MapsService {
    getLocationByLatLng = (data, callback) => this.globalService.mountResource('GET', { url: '/maps/get-location' }, data, callback);

    constructor(
        protected globalService: GlobalService
    ) { }

    getUserCity(callback) {
        if (!navigator.geolocation) {
            callback('NOT_SUPPORTED');
            return;
        }

        navigator.geolocation.getCurrentPosition((position) => {
            this.getLocationByLatLng({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }, callback);
        }, function() {
            callback('DECLINED');
        });
    }
}
