import { MainSearchService } from './../../services/main-search.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CheckoutService, SearchService } from '../../../shared/services';
import { MobileSearchService } from './../../services';
import { templateJitUrl } from '@angular/compiler';

@Component({
    selector: 'app-mobile-select-doctor-page',
    templateUrl: './select-doctor-page.component.html',
    styleUrls: ['./select-doctor-page.component.scss']
})
export class SelectDoctorPageComponent implements OnInit {
    selectedDoctor;
    doctors = [];
    backUrl;
    companyId;
    loading: boolean = false;

    constructor(
        private checkoutService: CheckoutService,
        private router: Router,
        public mainSearchService: MainSearchService
    ) { }

    ngOnInit(): void {
        if (!this.checkoutService.getPlace() || (this.checkoutService.getPlace() && !this.checkoutService.getPlace().is_tele && !this.checkoutService.getEvent()) || !this.checkoutService.getCompany()) {
            this.redirectToLastStep();
            return;
        }

        if (this.checkoutService.getDoctor()) {
            this.selectedDoctor = this.checkoutService.getDoctor().id;
        }

        this.getDoctors();
    }

    getDoctors() {
        let info;

        if (!this.checkoutService.getPlace() || this.checkoutService.getPlace() && !this.checkoutService.getPlace().is_tele && !this.checkoutService.getEvent()) {
            return;
        }

        info = {
            place_id: this.checkoutService.getPlace().id,
            company_id: this.checkoutService.getCompany().id,
            event_id: this.checkoutService.getEvent().id
        };

        if (this.checkoutService.getPlace() && this.checkoutService.getPlace().is_tele) {
            info.place_id = this.checkoutService.getPlace().place.id;
            info.event_id = this.checkoutService.getPlace().id;
        }

        this.mainSearchService.loading = true;
        this.loading = true;

        if (this.checkoutService.getInsurancePlan() && this.checkoutService.getPayment() === 'Convênio') {
            info.insurance_id = this.checkoutService.getInsurancePlan().id;
        }

        this.mainSearchService.listDoctors(info, (error, resp) => {
            this.mainSearchService.loading = false;
            this.loading = false;

            if (error) {
                return;
            }

            this.doctors = resp;
            this.mainSearchService.items = resp;
        });
    }

    setDoctor(doctor) {
        this.checkoutService.setSlot(null);

        if (this.selectedDoctor) {
            this.selectedDoctor = this.selectedDoctor === doctor.id ? null : doctor;
            this.checkoutService.setDoctor(this.selectedDoctor);
            this.redirectToLastStep();
            return;
        }

        this.selectedDoctor = doctor.id;
        this.checkoutService.setDoctor(doctor);
        this.redirectToLastStep();
    }

    redirectToLastStep() {
        this.router.navigate(['/mobile/card-info/'], { queryParamsHandling: 'preserve' });
    }
}
