import { FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';

// custom validator to check that two fields match
export function isBeforeDate(controlName: string, options?) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const now = moment();

        if (!options) {
            options = {};
        }

        if (!options.pattern) {
            options.pattern = 'DDMMYYYY';
        }

        if (!options.date) {
            options.date = now;
        }

        if (control.errors && !control.errors.isInvalidDate) {
            return;
        }

        if (control.value && moment(control.value, options.pattern).isAfter(options.date)) {
            control.setErrors({
                isInvalidBeforeDate: true
            });
        } else {
            control.setErrors(null);
        }
    }
}
