import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MobileCheckoutService {
    doctor;
    company;
    selectedPayment: boolean = false;
    payment = null;
    place;
    creditcard = {
        card: null,
        owner: null,
        address: null
    };

    constructor() {}

    getPlace() {
        let place = this.place;

        if (!place) {
            try {
                place = JSON.parse(localStorage.getItem('place'));
            } catch (err) {}
        }

        return place;
    }

    setDoctor(doctor) {
        this.doctor = doctor;
    }

    setCompany(company) {
        this.company = company;
    }

    setCreditcardData(creditcardData) {
        this.creditcard.card = creditcardData;
    }

    setCreditcardOwner(owner) {
        this.creditcard.owner = owner;
    }

    setCreditcardAddress(address) {
        this.creditcard.address = address;
    }

    getCreditCard() {
        return this.creditcard;
    }
}
