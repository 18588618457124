<svg-icon src="assets/svg/close.svg" class="close" (click)="redirectToCardInfo()"></svg-icon>
<main class="main">
    <app-mobile-calendar
        (selectedSlotEvent)="selectedSlot($event)"
        [dateCustomClasses]="dateCustomClasses"
        [selectedEvent]="event"
        [selectedPlace]="place"
        [doctor]="doctorData"
        [company]="company"
    ></app-mobile-calendar>
</main>
<footer>
    <div class="row">
        <div class="col-12">
            <button class="btn btn-primary btn-block btn-save" [disabled]="!slotSelected" (click)="saveSlot()">Confirmar</button>
        </div>
    </div>
</footer>
