import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

//Utils services
import { ModalShareAttendanceResume } from './modal-share-attendance-resume.component';

@NgModule({
    declarations: [
        ModalShareAttendanceResume
    ],
    exports: [
        ModalShareAttendanceResume,
    ],
    imports: [
        BrowserModule,
        AngularSvgIconModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalShareAttendanceResumeModule { }
