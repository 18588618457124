import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalOnboardComponent } from './modal-onboard.component';
import { LoadingModule } from 'src/app/shared/components';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    declarations: [
        ModalOnboardComponent
    ],
    exports: [
        ModalOnboardComponent,
    ],
    imports: [
        RouterModule,
        AngularSvgIconModule,
        BrowserModule,
        LoadingModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalOnboardModule { }
