import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService, BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';

import * as moment from 'moment-timezone';
import * as lodash from 'lodash';

import { SearchPageService } from '../../services';
import { AlertMessageService, AuthService, CheckoutService, SearchService, SlotService, TimeGridService } from './../../../shared/services';

import { mountSlotShift, getArrivalShift } from '../../../shared/utils/index';

@Component({
    selector: 'app-web-company-card',
    templateUrl: './web-company-card.component.html',
    styleUrls: ['./web-company-card.component.scss']
})
export class WebCompanyCardComponent {
    @Input() company;

    datepickerInitialized: boolean = true;
    slotsGroupedByShift = [];
    loading: boolean = false;
    enabledDatesLoading: boolean = false;
    openSlots: boolean = false;
    events = [];
    user;
    doctors = [];
    listedSlots = {};
    slots = [];
    enabledDates = [moment('2000-09-12')];
    dateCustomClasses: DatepickerDateCustomClasses[] = [];
    selectedEvent = null;
    selectedPlace = null;
	selectedSlot = null;
    selectedInsurancePlan = null;
    insurancePlans = [];
    selectedInsuranceGroup = null;
    selectedPaymentMethod = null;
    selectedDoctor = null;
    procedurePayments = null;
    listedPaymentsProcedures = {};
    now = moment();
    bsConfig: Partial<BsDatepickerConfig>;
    config = {
        isAnimated: true,
        containerClass: 'card-theme',
        customTodayClass: 'current-day',
        showWeekNumbers: false,
        selectFromOtherMonth: false
    };
    options = {
        locale: 'pt-br',
        minDate: this.now.clone().add(1, 'day').toDate(),
        maxDate: this.now.clone().add(90, 'days').toDate()
    };
    dateValue: Date = this.now.clone().add(1, 'day').toDate();

    constructor(
        private localeService: BsLocaleService,
        private route: ActivatedRoute,
        public searchPageService: SearchPageService,
        private alertMessageService: AlertMessageService,
        private checkoutService: CheckoutService,
        private router: Router,
        private searchService: SearchService,
        private slotService: SlotService,
        private TimeGridService: TimeGridService
    ) {
        this.localeService.use(this.options.locale);
        ptBrLocale.weekdaysShort = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
        defineLocale('pt-br', ptBrLocale);
        this.bsConfig = Object.assign({}, this.config);
    }

    ngOnInit(): void {
        this.getCalendarConfig();

        console.log('here - on init new');
    }

    getCalendarConfig() {
        this.options.minDate = this.company.allow_schedule_for_current_day ? this.now.clone().toDate() : this.now.clone().add(1, 'day').toDate();
        this.options.maxDate = this.now.clone().add(this.company.scheduler_max_available_days, 'days').toDate();

        this.dateValue = this.options.minDate;
    }

    confirmSchedule(slot) {
        if (this.selectedSlot && this.selectedSlot.id === slot.id) {
            this.selectedSlot = null;

            return;
        }

        this.selectedSlot = slot;
    }

    onValueChange(value: Date) {
        if (this.dateValue === value) {
            return;
        }

        this.dateValue = value;

        this.getUserSlots(this.dateValue);
    }

    setCustomDates(dates) {
        this.dateCustomClasses = [];
        this.enabledDates = [];

        dates.forEach((date) => {
            const now = moment();

            const dateObject = {
                date: moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).endOf('day').toDate(),
                classes: ['enabled-date']
            };

            if (moment(date, 'DD/MM/YYYY').isBefore(moment(now).clone().format('YYYY-MM-DD'))) {
                return;
            }

            this.enabledDates.push(moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).endOf('day'));
            this.dateCustomClasses.push(dateObject);
        });

        if (!this.enabledDates.length) {
            this.enabledDates.push(moment('2001-09-22'));
        }
    }

    selectEvent(event) {
        if (!this.selectedPaymentMethod || (this.selectedPaymentMethod === 'Convênio' && (!this.selectedInsuranceGroup || !this.selectedInsurancePlan))) {
            return this.alertMessageService.open('Selecione a forma de pagamento para escolher o tipo de atendimento', null, 'error');;
        }

        if (this.selectedEvent) {
            this.selectedEvent = '';
            this.doctors = [];
            this.slots = [];
            this.dateCustomClasses = [];
            this.enabledDates = [moment('2001-09-22')];
            this.procedurePayments = null;
            this.selectedSlot = null;
            this.selectedDoctor = null;
            this.datepickerInitialized = false;
            this.openSlots = false;

            setTimeout(() => this.datepickerInitialized = true);

            if (this.selectedEvent && this.selectedEvent === event.id) {
                return;
            }
        }

        this.selectedEvent = event;

        if (this.selectedEvent && this.selectedPlace) {
            if (this.selectedPlace.is_tele) {
                this.getDoctors(this.selectedPlace.company_id, this.selectedPlace.place.id, this.selectedPlace.id);
            } else {
                this.getDoctors(this.selectedPlace.company_id, this.selectedPlace.id, this.selectedEvent.id);
            }
        }

        if (this.selectedPlace && this.selectedDoctor) {
            this.getEnabledDates();
        }

        this.getProceduresPrice();
    }

    getProceduresPrice() {
        const data = {
            event_id: this.selectedEvent.id
        };

        if (this.listedPaymentsProcedures[data.event_id]) {
            this.procedurePayments = this.listedPaymentsProcedures[data.event_id];

            return;
        }

        this.loading = true;

        this.searchService.getEventsProcedures(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.listedPaymentsProcedures[data.event_id] = resp;

            this.procedurePayments = resp;
        });
    }

    getFilter(date) {
        const filter = {
            company_id: this.company.id,
            user_id: this.selectedDoctor.id,
            place_id: this.selectedPlace.id,
            event_id: this.selectedEvent.id,
            insurance_id: this.selectedInsurancePlan && this.selectedInsurancePlan.id && this.selectedPaymentMethod === 'Convênio' ? this.selectedInsurancePlan.id : null,
            start_date: moment(date).format('YYYY-MM-DD'),
            token: this.route.snapshot.queryParams.token
        };

        if (this.selectedPlace.is_tele) {
            filter.place_id = this.selectedPlace.place.id;
            filter.event_id = this.selectedPlace.id;
        }

        return filter;
    }

    getTelemedicineCalendar(data) {
        const filter = {
            ...data,
            is_scheduler: true,
            is_microservice: true
        };

        this.slotService.showAllTelemedicineSlots(filter, (error, resp) => {
            this.loading = false;
            this.datepickerInitialized = true;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.enabledDates = [];

                return;
            }

            const response = Object.keys(resp) || [];

            const dates = response.filter(date => resp[date] === 'AVAILABLE').map(date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'));

            this.setCustomDates(dates || []);
        });
    }

    getTelemedicineSlots(date, filterData) {
        filterData.is_scheduler = true;

        this.loading = true;

        this.slotService.getTelemedicineSlots(filterData, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            if (!resp.length) {
                this.slots = [];

                return;
            }

            if (!this.listedSlots[this.selectedPlace.id]) {
                this.listedSlots[this.selectedPlace.id] = {};
            }

            if (!this.listedSlots[this.selectedPlace.id][this.selectedEvent.id]) {
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id] = {};
            }

            this.openSlots = true;
            this.slots = lodash.uniqBy(resp, (item) => item.id);

            if (!this.slots.length) {
                this.alertMessageService.open('Não há horários disponíveis no dia selecionado!', null, 'warning');
                this.openSlots = false;
            }

            this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')] = this.slots;
        });
    }

    getUserSlots(date) {
        if (this.listedSlots[this.selectedPlace.id] && this.listedSlots[this.selectedPlace.id][this.selectedEvent.id] && this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')]) {
            this.slots = this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')]

            return;
        }

        this.loading = true;

        this.slotService.showAll(this.getFilter(date), (error, doctorSlots) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');

                return;
            }

            if (!doctorSlots.length) {
                this.slots = [];

                this.alertMessageService.open('Não há horários disponíveis no dia selecionado!', null, 'warning');

                return;
            }

            if (!this.listedSlots[this.selectedPlace.id]) {
                this.listedSlots[this.selectedPlace.id] = {};
            }

            if (!this.listedSlots[this.selectedPlace.id][this.selectedEvent.id]) {
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id] = {};
            }

            this.openSlots = true;

            const slots = lodash.uniqBy(doctorSlots, item => item.id);

            if (this.selectedDoctor.config_arrival_order) {
                this.slots = [];

                const slotsGrouped = mountSlotShift(slots);

                this.slotsGroupedByShift = Object.values(slotsGrouped);
            } else {
                this.slots = slots;
                this.listedSlots[this.selectedPlace.id][this.selectedEvent.id][moment(date).format('YYYY-MM-DD')] = slots;
            }

            if (!slots.length) {
                this.alertMessageService.open('Não há horários disponíveis no dia selecionado!', null, 'warning');
            }

        });
    }

    getEnabledDates() {
        const data = {
            month_key: moment().format('YYYY-MM'),
            event_id: this.selectedEvent.id,
            company_id: this.selectedPlace.company_id,
            user_id: this.selectedDoctor.id,
            place_id: this.selectedPlace.id,
            insurance_id: this.selectedInsurancePlan && this.selectedInsurancePlan.id && this.selectedPaymentMethod === 'Convênio' ? this.selectedInsurancePlan.id : null,
            token: this.route.snapshot.queryParams.token
        };

        if (this.selectedPlace.is_tele) {
            data.event_id = this.selectedPlace.id;
            data.place_id = this.selectedPlace.place.id;
        }

        this.loading = true;
        this.datepickerInitialized = false;

        this.slotService.calendar(data, (error, resp) => {
            this.loading = false;
            this.datepickerInitialized = true;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.enabledDates = [];

                return;
            }

            this.setCustomDates(resp);
        });
    }

    getDoctors(companyId, placeId, eventId) {
        const data = {
            company_id: companyId,
            place_id: placeId,
            event_id: eventId,
            insurance_id: (this.selectedInsurancePlan && this.selectedInsurancePlan.id) || null
        };

        if (!companyId || !placeId || !eventId) {
            this.alertMessageService.open('É necessário selecionar a unidade e o evento!', null, 'warning');

            return;
        }

        this.searchService.listDoctors(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'warning');

                return;
            }

            this.doctors = resp;
        });
    }

    changePaymentMethod(method) {
        this.cleanSlotsInfo(null);
        this.selectedEvent = null;

        if (this.selectedPaymentMethod === method) {
            this.selectedPaymentMethod = null;
            this.selectedInsurancePlan = null;
            this.selectedInsuranceGroup = null;
            this.doctors = [];

            return;
        }

        this.selectedPaymentMethod = method;
    }

    showInexistentEventMessage() {
        if (this.selectedPlace && this.selectedPlace.is_tele) {
            this.alertMessageService.open('O tipo de atendimento "Teleatendimento" já está selecionado!', null, 'warning');
            return;
        }

        this.alertMessageService.open('Não há tipos de atendimento disponíveis na unidade selecionada!', null, 'warning');
    }

    showInexistentDoctorMessage() {
        this.alertMessageService.open('Não há médicos disponíveis para o filtro selecionado.', null, 'warning');
    }

    selectInsuranceGroup(group) {
        this.cleanSlotsInfo(null);

        if (this.selectedInsuranceGroup && this.selectedInsuranceGroup.id === group.id) {
            this.selectedInsuranceGroup = null;
            this.selectedInsurancePlan = null;
            return;
        }

        this.selectedInsuranceGroup = group;
        this.selectedInsurancePlan = null;
        this.selectedEvent = null;

        this.insurancePlans = this.company.insurance_plans.filter(item => item.health_insurance_group_id === group.id);
    }

    getListEvents() {
        const data = {
            place_id: this.selectedPlace.id,
            company_id: this.company.id,
            ...this.selectedInsurancePlan && this.selectedInsurancePlan.id ? { insurance_id: this.selectedInsurancePlan.id } : {}
        };

        this.searchService.listEvents(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'warning');
                return;
            }

            this.selectedEvent = null;
            this.events = resp;
        });
    }

    selectInsurancePlan(insurance) {
        this.cleanSlotsInfo(null);

        if (this.selectedInsurancePlan && this.selectedInsurancePlan.id === insurance.id) {
            this.selectedInsurancePlan = null;
            return;
        }

        this.selectedInsurancePlan = insurance;

        if (this.selectedPlace) {
            this.getListEvents();
        }
    }

    getSelectedSlotId() {
        const selectedSlot = this.checkoutService.getSlot();

        if (!selectedSlot) {
            return '';
        } else {
            return selectedSlot;
        }
    }

    selectPlace(place) {
        this.selectedSlot = null;
        this.procedurePayments = null;
        this.selectedEvent = null;
        this.selectedDoctor = null;

        if (this.dateCustomClasses.length || this.openSlots) {
            this.enabledDates = [moment('2020-09-12')];
            this.openSlots = false;
            this.dateCustomClasses = [];
            this.datepickerInitialized = false;

            setTimeout(() => this.datepickerInitialized = true, 100);
        }

        if ((this.selectedPlace && this.selectedPlace.id === place.id)) {
            this.selectedPlace = null;
            this.events = [];
            this.doctors = [];
            this.slots = [];

            return;
        }

        if (place.is_tele) {
            this.selectedPlace = place;
            this.selectEvent(place);

            return;
        }

        this.selectedPlace = place;
        this.doctors = [];

        if (this.selectedPaymentMethod !== 'Particular' && (this.selectedPlace && !this.selectedPlace.id)) {
            return;
        }

        this.getListEvents();
    }

    cleanSlotsInfo(options) {
        this.slots = [];
        this.slotsGroupedByShift = [];

        if (!options?.skipClearDoctors) {
            this.doctors = [];
        }

        this.dateCustomClasses = [];
        this.enabledDates = [moment('2001-09-22')];
        this.selectedDoctor = null;
        this.selectedSlot = null;
        this.dateValue = null;
        this.openSlots = false;
        this.datepickerInitialized = false;

        setTimeout(() => this.datepickerInitialized = true);
    }

	selectDoctor(doctor) {
        if (this.selectedDoctor && this.selectedDoctor.id === doctor.id) {
            return this.cleanSlotsInfo({ skipClearDoctors: true });
        }

        this.slotsGroupedByShift = []
        this.slots = []
        this.selectedDoctor = doctor;
        this.selectedSlot = null;
        this.dateValue = null;
        this.openSlots = false;
        this.datepickerInitialized = false;

        this.getEnabledDates();
    }

    setDataByUserId() {
        const userId = this.selectedDoctor.id;
        const items = [this.selectedEvent, this.selectedPlace, this.company, this.selectedSlot];

        items.forEach(item => item.user_id = userId);

        this.checkoutService.setDoctor(this.selectedDoctor);
        this.checkoutService.setEvent(this.selectedEvent);
        this.checkoutService.setCompany(this.company);
        this.checkoutService.setPlace(this.selectedPlace);
        this.checkoutService.setSlot(this.selectedSlot);
        this.checkoutService.setPayment(this.selectedPaymentMethod);
        this.checkoutService.setInsurancePlan(this.selectedPaymentMethod === 'Convênio' ? this.selectedInsurancePlan : null);
    }

    schedule(slot) {
        if (!slot || !this.selectedPlace || !this.selectedDoctor) {
            return;
        }

        if (!this.selectedEvent) {
            if (!this.selectedPlace.is_tele) {
                return;
            } else {
                this.selectedEvent = this.selectedPlace;
            }
        }

        this.selectedSlot = slot;
        this.selectedSlot.price = this.procedurePayments.price;
        this.selectedSlot.payment_methods = this.procedurePayments.payment_methods;
        this.selectedSlot.event_id = this.selectedEvent.id;
        this.selectedSlot.place_id = this.selectedPlace.id;
        this.selectedSlot.user_id = this.selectedDoctor.id;

        this.selectedSlot.insurance_id = (this.selectedInsurancePlan && this.selectedInsurancePlan.id) || null;

        if (this.selectedPlace.is_tele) {
            this.selectedSlot.place_id = this.selectedPlace.place.id;
            this.selectedSlot.event_id = this.selectedPlace.id;
            this.selectedEvent = this.selectedPlace;
            this.selectedPlace = this.selectedPlace.place;
        }

        this.setDataByUserId();

        this.router.navigate(['/desktop/resume'], { queryParamsHandling: 'preserve' });
	}

    async getTimegridInfo(data) {
        const response = await new Promise((resolve, reject) => {
            this.TimeGridService.timeGridInfo(data, (error, timeGrid) => {
                resolve(timeGrid)
            })
        });

        return response;
    }

    async scheduleByShift(shift) {
        if (!shift || !this.selectedPlace || !this.selectedDoctor) {
            return;
        }

        if (!this.selectedEvent) {
            if (!this.selectedPlace.is_tele) {
                return;
            } else {
                this.selectedEvent = this.selectedPlace;
            }
        }

        const slot = shift.slots[0];

        this.selectedSlot = slot;

        this.loading = true;

        const timegridInfo = await this.getTimegridInfo({id: slot.timegrid_id});

        const shiftAttandenceInfo = getArrivalShift(timegridInfo, shift.label);

        this.selectedSlot.shift = timegridInfo;
        this.selectedSlot.shift = {
            ...this.selectedSlot.shift,
            ...shiftAttandenceInfo
        };
        this.selectedSlot.shift.label = shift.label;

        this.selectedSlot.price = this.procedurePayments.price;
        this.selectedSlot.payment_methods = this.procedurePayments.payment_methods;
        this.selectedSlot.event_id = this.selectedEvent.id;
        this.selectedSlot.place_id = this.selectedPlace.id;
        this.selectedSlot.user_id = this.selectedDoctor.id;

        this.selectedSlot.insurance_id = (this.selectedInsurancePlan && this.selectedInsurancePlan.id) || null;

        if (this.selectedPlace.is_tele) {
            this.selectedSlot.place_id = this.selectedPlace.place.id;
            this.selectedSlot.event_id = this.selectedPlace.id;
            this.selectedEvent = this.selectedPlace;
            this.selectedPlace = this.selectedPlace.place;
        }

        this.loading = false;

        this.setDataByUserId();

        this.router.navigate(['/desktop/resume'], { queryParamsHandling: 'preserve' });
	}
}
