<app-loading [hidden]="!loading"></app-loading>

<main class="container" *ngIf="!loading && (!item || !onboardType)">
    <div class="row" style="margin-top: 40%;">
        <div class="col-12">
            <div class="text-center">
                <img src="assets/img/not-found.png" style="width: 100%">
                <p class="not-found--text">Nenhum resultado encontrado</p>
                <span class="not-found-label">Token inválido ou já utilizado</span>
            </div>
        </div>
    </div>
</main>

<main class="container" [ngClass]="{ 'company': item && !item.isDoctor, 'doctor': item && item.isDoctor }" [hidden]="loading || !item || !onboardType">
    <div class="d-flex flex-column" style="margin-top: 24px;">
        <div style="font-style: normal;font-weight: bold;font-size: 20px;">
            Olá, {{ patientName }}!
        </div>

        <div style="font-style: normal;font-weight: normal;font-size: 15px;line-height: 150%;">
            Agende seu atendimento.
        </div>
    </div>

    <hr>

    <div class="row card-info" style="margin-left: 0;margin-right: 0">
        <div class="col-12">
            <app-doctor-card [doctorData]="doctor" *ngIf="item && item.isDoctor"></app-doctor-card>
            <app-company-card [companyData]="company" *ngIf="item && !item.isDoctor"></app-company-card>
        </div>
    </div>

    <section style="margin: 0 13px;">
        <div class="row select-entity align-items-center" (click)="showPaymentMethods()">
            <div class="col-10">
                <span class="select-entity-title d-block mb-2" style="font-style: normal;font-weight: 400;font-size: 18px;">
                    <svg-icon style="fill: #008cff !important;width: 26px;height: 24px;position: relative;bottom: 1px;margin-right: 3px;" src="assets/svg/money-blue.svg"></svg-icon>
                    Convênio ou Particular?

                    <span style="margin-left: 3px;" [hidden]="!paymentMethod"><svg-icon src="assets/svg/verified-green.svg" class="icon-verified-green"></svg-icon></span>
                </span>
                <span class="select-entity-subtitle d-block">{{ paymentMethod || 'Selecione a opção desejada' }}</span>
            </div>

            <div class="col-2">
                <span class="c-pointer">
                    <svg-icon src="assets/svg/arrow-right-gray.svg" style="fill: #676A6C !important;" class="address-arrow-right"></svg-icon>
                </span>
            </div>
        </div>

        <div class="row select-entity align-items-center" (click)="redirectToSelectAddress()">
            <div class="col-10">
                <span class="select-entity-title d-block mb-2" style="font-style: normal;font-weight: 400;font-size: 18px;">
                    <svg-icon style="fill: #008cff;width: 26px;height: 24px;position: relative;bottom: 1px;margin-right: 3px;" src="assets/svg/pin-blue.svg"></svg-icon>
                    Unidade
                    <span style="margin-left: 3px;" [hidden]="!place"><svg-icon src="assets/svg/verified-green.svg" class="icon-verified-green"></svg-icon></span>
                </span>
                <span class="select-entity-subtitle d-block" *ngIf="!place">Onde quer ser atendido?</span>
                <span class="select-entity-subtitle d-block" *ngIf="place && place.address" style="font-style: normal;font-weight: 100;font-size: 14px;">{{ place.address | address }}</span>
                <span *ngIf="place" class="select-entity-description" style="font-style: normal;font-weight: 100;font-size: 14px;">{{ place.name }}</span>
            </div>

            <div class="col-2">
                <span class="c-pointer">
                    <svg-icon src="assets/svg/arrow-right-gray.svg" style="fill: #676A6C !important;" class="address-arrow-right"></svg-icon>
                </span>
            </div>
        </div>

        <div class="row select-entity align-items-center" (click)="showEvents()">
            <div class="col-10 text-truncate">
                <span class="select-entity-title d-block mb-2" style="font-style: normal;font-weight: 400;font-size: 18px;">
                    <svg-icon style="margin-right: 5px;" src="assets/svg/telemedicine-blue.svg"></svg-icon>
                    Tipo de atendimento
                    <span style="margin-left: 3px;" [hidden]="!event"><svg-icon src="assets/svg/verified-green.svg" class="icon-verified-green"></svg-icon></span></span>
                <span class="select-entity-subtitle d-block" style="font-style: normal;font-weight: 100;font-size: 14px;" *ngIf="(!event && !place) || (!event && (place && !place.is_tele))">Selecione</span>
                <span class="select-entity-subtitle d-block" style="font-style: normal;font-weight: 100;font-size: 14px;" *ngIf="event && (place && !place.is_tele)">{{ event.name }}</span>
                <span class="select-entity-subtitle d-block" style="font-style: normal;font-weight: 100;font-size: 14px;" *ngIf="place && place.is_tele">Teleatendimento</span>
                <!-- <span *ngIf="event" class="select-entity-description">
                    <span class="text-truncate payment-method" style="font-style: normal;font-weight: 100;font-size: 14px;" *ngIf="procedurePayments" title="{{ procedurePayments.payment_methods | payment }}">
                        <!-- <span *ngIf="procedurePayments.price">R$ {{ procedurePayments.price.toFixed(2) }} - </span> -->
                        <!-- <span *ngIf="procedurePayments.payment_methods && procedurePayments.payment_methods.length" title="{{ procedurePayments.payment_methods | payment }}">{{ procedurePayments.payment_methods | payment }}</span> -->
                        <!-- <span *ngIf="!procedurePayments.payment_methods || (procedurePayments.payment_methods && !procedurePayments.payment_methods.length)">INDISPONÍVEL</span> -->
                    <!-- </span> -->
                <!-- </span> -->
            </div>

            <div class="col-2">
                <span class="c-pointer">
                    <svg-icon src="assets/svg/arrow-right-gray.svg" style="fill: #676A6C !important;" class="address-arrow-right"></svg-icon>
                </span>
            </div>
        </div>

        <div class="row select-entity align-items-center" *ngIf="item && !item.isDoctor" (click)="showDoctors()">
            <div class="col-10" *ngIf="!doctor">
                <span class="select-entity-title d-block mb-2" style="font-style: normal;font-weight: 400;font-size: 18px;">
                    <svg-icon style="fill: #008cff;width: 20px;height: 24px;position: relative;bottom: 2px;margin-right: 5px;" src="assets/svg/user-doctor.svg"></svg-icon>
                    Profissional</span>
                <span class="select-entity-subtitle d-block">Selecione</span>
            </div>

            <div class="col-10" *ngIf="doctor">
                <app-doctor-card [doctorData]="doctor"></app-doctor-card>
            </div>

            <div class="col-2">
                <span class="c-pointer">
                    <svg-icon src="assets/svg/arrow-right-gray.svg" style="fill: #676A6C !important;" class="address-arrow-right"></svg-icon>
                </span>
            </div>
        </div>

        <div class="row select-entity align-items-center" (click)="showSlots()">
            <div class="col-10">
                <span class="select-entity-title d-block mb-2" style="font-style: normal;font-weight: 400;font-size: 18px;">Data e Horário <span style="margin-left: 3px;" [hidden]="!slot"><svg-icon src="assets/svg/verified-green.svg" class="icon-verified-green"></svg-icon></span></span>
                <span class="select-entity-subtitle d-block" *ngIf="!slot">
                    <svg-icon src="assets/svg/calendar-blue.svg" style="fill: #676a6c !important;" [svgClass]="'select-entity-calendar-icon'" class="mr-2"></svg-icon>
                    Selecione o horário
                </span>
                <span class="select-entity-description" *ngIf="slot">
                    <svg-icon src="assets/svg/calendar-blue.svg" [svgClass]="'select-entity-calendar-icon'" class="mr-2"></svg-icon>
                    <span *ngIf="!slot.shift" style="font-weight: 300;font-size: 14px;line-height: 115%;color: #676A6C;">{{ (slot.start_date | date: 'dd MMMM yyyy') | titlecase }} às <strong>{{ slot.start_date | date: 'HH:mm' : '-03:00' }}</strong></span>

                    <span *ngIf="slot.shift" style="font-weight: 300;font-size: 14px;line-height: 115%;color: #676A6C;">
                        {{ (slot.start_date | date: 'dd MMMM yyyy') | titlecase }} <br>
                        A partir das <strong>{{ slot.shift.attendance_start }}</strong> às <strong>{{ slot.shift.attendance_end }}</strong>
                    </span>
                </span>
            </div>

            <div class="col-2">
                <span class="c-pointer">
                    <svg-icon src="assets/svg/arrow-right-gray.svg" style="fill: #676A6C !important;" class="address-arrow-right"></svg-icon>
                </span>
            </div>
        </div>
    </section>

    <div class="row" style="margin-top: 24px;">
        <div class="col-12">
            <button class="btn btn-primary btn-block" style="height: 48px;" (click)="schedule()" [disabled]="!isValid()">Concluir agendamento</button>
        </div>
    </div>
</main>
