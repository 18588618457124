<div class="close-page-div">
    <svg-icon src="assets/svg/close.svg" class="close-icon c-pointer" (click)="redirectToLastStep()"></svg-icon>
</div>

<main>
    <app-loading [hidden]="!loading"></app-loading>

    <div class="main-body" [hidden]="loading">
        <div class="d-flex flex-row select-doctor">
            <span>Selecione o profissional</span>
        </div>

        <div class="overflow-doctors" *ngIf="doctors.length && !mainSearchService.loading">
            <div class="d-flex flex-row mt-3" *ngFor="let doctor of doctors" (click)="setDoctor(doctor)">
                <div class="card" [ngClass]="{ 'border-primary': selectedDoctor == doctor.id }">
                    <div class="card-body">
                        <app-doctor-card [doctorData]="doctor"></app-doctor-card>
                    </div>
                </div>
            </div>
         </div>

         <div class="d-flex flex-row mt-3" *ngIf="!doctors.length && !mainSearchService.loading">
            <div class="alert alert-warning">Não há profissionais que atendem este tipo de atendimento nesta unidade!</div>
        </div>
    </div>
</main>
