<div class="container">
    <app-loading [hidden]="!loading"></app-loading>
    <div class="row" [hidden]="loading">
        <div class="col-12">
            <div class="row">
                <div class="col-12" style="padding-bottom: 24px; padding-top: 18px;">
                    <svg-icon src="assets/svg/close.svg" [routerLink]="['/mobile']" style="padding: 6px; padding-left: 0 !important;"></svg-icon>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <span class="attendance-history-label">Histórico de agendamentos</span>
                </div>
            </div>

            <div class="row mt-3" *ngIf="schedules.length">
                <div class="col-12 attendance-card" *ngFor="let schedule of schedules" (click)="openAttendance(schedule)">
                    <div class="attendance-to" style="padding-bottom: 7px;">
                        <svg-icon src="assets/svg/attendance-done.svg" [svgClass]="'icon'" *ngIf="schedule.status === 'DONE'" style="position: absolute;"></svg-icon>
                        <svg-icon src="assets/svg/attendance-canceled.svg" [svgClass]="'icon'" *ngIf="schedule.status === 'CANCELED'" style="position: absolute;"></svg-icon>
                        <svg-icon src="assets/svg/scheduled.svg" [svgClass]="'icon'" *ngIf="schedule.status === 'SCHEDULED'" style="position: absolute;"></svg-icon>
                        <span class="text-truncate" style="font-weight: bold; text-decoration: underline; margin-left: 33px;">
                            Consulta para {{ schedule.name }}
                        </span>
                    </div>
                    <div style="border-left: 3px solid #f0f0f0; margin-left: 7px; height: 62px;">
                        <div class="attendance-date">
                            {{ schedule.start_date | date: 'EEEE' | titlecase }},
                            {{ schedule.start_date | date: 'd' }} de {{ schedule.start_date | date: 'MMMM' }}
                            ás {{ schedule.start_date | date: 'HH:mm' : '-03:00' }}
                        </div>
                        <div class="d-block doctor text-truncate" style="margin-left: 22px; padding-top: 4px;">
                            {{ schedule.user.name }} ● {{ schedule.user.specialty }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!schedules.length">
                <div class="col-12 mt-3">
                    <div class="alert alert-warning">Não há atendimentos registrados!</div>
                </div>
            </div>
        </div>
    </div>
</div>
