import { Injectable } from '@angular/core';
import { GlobalService } from 'src/app/shared/services';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MainSearchService {
    doctorList = (data, callback) => this.globalService.mountResource('GET', { url: '/search/doctor-list' }, data, callback);
    companyList = (data, callback) => this.globalService.mountResource('GET', { url: '/search/company-list' }, data, callback);
    listDoctors = (data, callback) => this.globalService.mountResource('GET', { url: '/search/company-list/doctors' }, data, callback);

    loading: Boolean = false;
    items: any = [];

    constructor(
        private route: ActivatedRoute,
        protected globalService: GlobalService
    ) { }

    list(action) {
        action = `${action}List`;

        this.loading = true;

        this[action](this.getFilter(), (error, resp) => {
            this.loading = false;

            if (error) {
                return;
            }

            this.items = resp;
        });
    }

    getFilter() {
        return {
            type: this.route.snapshot.queryParams.type,
            search_text: this.route.snapshot.queryParams.search_text
        };
    }
}
