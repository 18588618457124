import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { MobileResumeCheckoutPageComponent } from './resume-checkout-page.component';
import { MobileDefaultHeaderModule } from '../../components';
import { RouterModule } from '@angular/router';
import { AddressPipeModule } from 'src/app/shared/pipes';
import { CardBrandModule } from 'src/app/desktop/components';
import { LoadingModule } from 'src/app/shared/components';

@NgModule({
    declarations: [
        MobileResumeCheckoutPageComponent
    ],
    exports: [
        MobileResumeCheckoutPageComponent
    ],
    imports: [
        BrowserModule,
        AngularSvgIconModule,
        MobileDefaultHeaderModule,
        RouterModule,
        AddressPipeModule,
        CardBrandModule,
        LoadingModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ResumeCheckoutPageModule { }
