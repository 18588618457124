import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-card-brand',
    templateUrl: './card-brand.component.html',
    styleUrls: ['./card-brand.component.scss']
})
export class CardBrandComponent implements OnInit {
    @Input() cardBrand;

    constructor() { }

    ngOnInit(): void {
    }
}
