import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MobileSearchService {
    lastSearch = {
        type: null,
        search_text: null,
        insurances: [],
        locationText: null,
        requestTabType: null
    };
    private companyData = {
        list: '',
        card: ''
    };
    private doctorData = {
        list: '',
        card: ''
    };

    setLastSearch(lastSearch) {
        this.lastSearch = lastSearch;

        localStorage.setItem('lastSearch', JSON.stringify(this.lastSearch));
    }

    setCompanyListData(data) {
        this.companyData.list = data;
    }

    setCompanyCardData(data) {
        this.companyData.card = data;
    }

    setDoctorListData(data) {
        this.doctorData.list = data;
    }

    setDoctorCardData(data) {
        this.doctorData.card = data;
    }

    getLastSearch() {
        this.lastSearch = JSON.parse(localStorage.getItem('lastSearch'));

        return this.lastSearch;
    }

    getDoctorData() {
        return this.doctorData;
    }

    getCompanyData() {
        return this.companyData;
    }
}
