import { AlertMessageService } from '../../../shared/services';
import { Injectable } from '@angular/core';

@Injectable()
export class UtilsFormValidator {
    constructor(private alertMessageService: AlertMessageService) {
    }

    isValid(formGroup): boolean {
        if (!formGroup.valid) {
            this.alertMessageService.open('Preencha todos os campos!', null, 'warning');

            if (formGroup.controls.recaptcha) {
                formGroup.controls.recaptcha.reset();
            }

            return false;
        }

        return true;
    }
}
