<div class="d-flex" style="width: 100%;">
    <div class="card" [ngClass]="{ 'increase-width': openSlots }" style="margin: 0 auto; height: 400px !important; border: none !important;box-shadow: 2px 4px 14px #d7d2d2;">
        <div class="card-body d-flex" style="padding: 0;">
            <div *ngIf="loading" style="width: 100%; margin-bottom: 50px;" class="d-flex justify-content-center align-items-center">
                <app-loading></app-loading>
            </div>

            <!-- <div class="d-flex"> -->
                <div class="d-flex flex-column" [ngClass]="{ 'w-100': !loading }" [hidden]="loading || datepickerInitialized">
                    <div class="d-flex flex-column">
                        <div class="d-flex">
                            <div class="pt-4 pl-3" [hidden]="loading">
                                <div class="d-flex">
                                    <div>
                                        <img style="object-fit: contain;" *ngIf="doctor.thumb_url" src="{{ doctor.thumb_url }}" class="img-profile">
                                        <img style="object-fit: contain;" *ngIf="!doctor.thumb_url" src="assets/img/user-profile.png" class="img-profile">
                                    </div>

                                    <div class="doctor-info text-truncate">
                                        <span class="doctor-name text-left d-block">{{ doctor.name }}</span>
                                        <span class="doctor-crm mb-2 align-middle" *ngIf="doctor.council_name">{{ doctor.council_name }}</span>
                                        <span class="doctor-crm mb-2 align-middle" *ngIf="doctor.council_number && doctor.council_name">&nbsp;{{ doctor.council_number }}</span>
                                        <span class="doctor-crm mb-2 align-middle" *ngIf="doctor.council_number && doctor.council_name && doctor.specialty">&nbsp;- {{ doctor.specialty }}</span>
                                        <span class="d-block mt-2" *ngIf="doctor.has_tele && (doctor.council_number || doctor.council_name || doctor.specialty)">
                                            <svg-icon src="assets/svg/telemedicine.svg" class="button-icon"></svg-icon>
                                            Telemedicina disponível
                                        </span>
                                        <span class="d-block mt-2" *ngIf="doctor.has_tele && !doctor.council_number && !doctor.council_name && !doctor.specialty" style="margin-top: 29px !important;">
                                            <svg-icon src="assets/svg/telemedicine.svg" class="button-icon"></svg-icon>
                                            Telemedicina disponível
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <div class="mt-2 d-block place">Convênio ou Particular?</div>

                                    <div class="dropdown" style="width: 400px;">
                                        <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #f2f5f7; color: #676a6c; border: none;">
                                            <span class="dropdown-text">{{ selectedPaymentMethod || 'Selecione' }}</span>
                                        </button>

                                        <div class="dropdown-menu" style="padding: 10px;" aria-labelledby="dropdown">
                                            <button class="dropdown-button dropdown-item" style="margin-bottom: 5px; border-radius: 5px;" (click)="changePaymentMethod('Particular')" [ngClass]="{ 'selected': selectedPaymentMethod && selectedPaymentMethod === 'Particular' }">
                                                <span class="d-block text-truncate">
                                                    <span class="place place-name">Particular</span>
                                                </span>
                                            </button>

                                            <button class="dropdown-button dropdown-item" style="margin-bottom: 5px; border-radius: 5px;" (click)="changePaymentMethod('Convênio')" [ngClass]="{ 'selected': selectedPaymentMethod && selectedPaymentMethod === 'Convênio' }" *ngIf="doctor.insurance_groups && doctor.insurance_groups.length">
                                                <span class="d-block text-truncate">
                                                    <span class="place place-name">Convênio</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="selectedPaymentMethod === 'Convênio'" class="d-flex" style="width: 97%;">
                                    <div style="flex: 1;">
                                        <div class="mt-2 d-block place">Selecione o convênio</div>

                                        <div class="dropdown">
                                            <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #f2f5f7; color: #676a6c; border: none;">
                                                <span class="dropdown-text">{{ (selectedInsuranceGroup && selectedInsuranceGroup.name) || 'Selecione' }}</span>
                                            </button>

                                            <div class="dropdown-menu" style="width: 210px; padding: 10px;" aria-labelledby="dropdown">
                                                <button class="dropdown-button dropdown-item" title="{{ group.name }}" style="margin-bottom: 5px; border-radius: 5px;" *ngFor="let group of doctor.insurance_groups" (click)="selectInsuranceGroup(group)" [ngClass]="{ 'selected': selectedInsuranceGroup && selectedInsuranceGroup.id === group.id }">
                                                    <span class="d-block text-truncate">
                                                        <span class="place place-name">{{ group.name }}</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="flex: 1;">
                                        <div class="mt-2 d-block place">Selecione o Plano</div>

                                        <div class="dropdown">
                                            <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #f2f5f7; color: #676a6c; border: none;">
                                                <span class="dropdown-text">{{ (selectedInsurancePlan && selectedInsurancePlan.name) || 'Selecione' }}</span>
                                            </button>

                                            <div class="dropdown-menu" style="width: 210px; padding: 10px;" aria-labelledby="dropdown">
                                                <button class="dropdown-button dropdown-item" title="{{ plan.preview_name || plan.name }}" style="margin-bottom: 5px; border-radius: 5px;" *ngFor="let plan of insurancePlans" (click)="selectInsurancePlan(plan)" [ngClass]="{ 'selected': selectedInsurancePlan && selectedInsurancePlan.id === plan.id }">
                                                    <span class="d-block text-truncate">
                                                        <span class="place place-name">{{ plan.preview_name || plan.name }}</span>
                                                    </span>
                                                </button>

                                                <button class="dropdown-button dropdown-item" style="margin-bottom: 5px; border-radius: 5px;" *ngIf="!insurancePlans.length">
                                                    <span class="d-block text-truncate">
                                                        <span class="place place-name">Não há planos disponíveis para o filtro selecionado</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="mt-2 d-block place">Unidade</div>

                                    <div class="dropdown" style="width: 400px;">
                                        <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #f2f5f7; color: #676a6c; border: none;">
                                            <span class="dropdown-text" *ngIf="!selectedPlace">Selecione a clínica de sua preferência</span>
                                            <span class="dropdown-text" *ngIf="selectedPlace">
                                                {{ selectedPlace.name }}
                                                <span *ngIf="selectedPlace.address" class="text-truncate"> - {{ selectedPlace.address | address }}</span>
                                                <span *ngIf="selectedPlace.is_tele"> - Atendimento online</span>
                                            </span>
                                        </button>

                                        <div class="dropdown-menu" style="padding: 10px;" aria-labelledby="dropdown">
                                            <button class="dropdown-button dropdown-item" style="margin-bottom: 5px; border-radius: 5px;" *ngFor="let place of doctor.places" (click)="selectPlace(place)" [ngClass]="{ 'selected': selectedPlace && selectedPlace.id === place.id }">
                                                <span class="d-block text-truncate">
                                                    <svg-icon src="assets/svg/location-gray.svg" class="mr-1" *ngIf="!place.is_tele"></svg-icon>
                                                    <svg-icon src="assets/svg/telemedicine.svg" class="mr-1" *ngIf="place.is_tele"></svg-icon>
                                                    <span class="place place-name" *ngIf="place && place.company">{{ place.name }} - {{ place.company.name }}</span>
                                                </span>
                                                <span class="place-address text-truncate" *ngIf="place.address && place.address.address_address && !place.is_tele">{{ place.address | address }}</span>
                                                <span class="place-address text-truncate" *ngIf="place.address && place.address.address_address && place.is_tele">Online</span>
                                                <span class="place-address text-truncate" *ngIf="(!place.address || place.address && !place.address.address_address) && !place.is_tele">Endereço não informado</span>
                                                <span class="place-address text-truncate" *ngIf="(!place.address || place.address && !place.address.address_address) && place.is_tele">Atendimento online</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="mt-2 d-block place">Tipo de atendimento</div>

                                    <div class="dropdown" style="width: 400px;">
                                        <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="(selectedPlace && selectedPlace.events && selectedPlace.events.length)" style=" background: #f2f5f3; color: #676a6c; border: none !important;">
                                            <span class="dropdown-text" *ngIf="!selectedEvent">Selecione o tipo de atendimento</span>
                                            <span class="dropdown-text" *ngIf="selectedEvent">{{ selectedEvent.name }}</span>
                                        </button>

                                        <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left" *ngIf="(!selectedPlace || !selectedPlace.events || !selectedPlace.events.length)" type="button" (click)="showInexistentEventMessage()" style="background: #f2f5f3; color: #676a6c; border: none;">
                                            <span class="dropdown-text" *ngIf="selectedPlace && selectedPlace.is_tele">Teleatendimento</span>
                                            <span class="dropdown-text" *ngIf="!selectedEvent && ((selectedPlace && !selectedPlace.is_tele) || !selectedPlace) ">Selecione o tipo de atendimento</span>
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-event" style="padding: 10px;" aria-labelledby="dropdown" *ngIf="selectedPlace && !selectedPlace.is_tele && selectedPlace.events && selectedPlace.events.length" style="max-height: 133px !important;">
                                            <button class="dropdown-button dropdown-item text-truncate" style="margin-bottom: 5px; border-radius: 5px;" *ngFor="let event of selectedPlace.events" (click)="selectEvent(event)" [ngClass]="{ 'selected': selectedEvent && selectedEvent.id === event.id }">
                                                <span class="d-block text-truncate">
                                                    <span class="place place-name">{{ event.name }}</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="pt-4 pr-3" [hidden]="loading">
                                <bs-datepicker-inline class="datepicker" (bsValueChange)="onValueChange($event)"
                                bsDatepicker [minDate]="options.minDate" [(ngModel)]="dateValue"
                                name="dateValue" ngDefaultControl [dateCustomClasses]="dateCustomClasses"
                                [maxDate]="options.maxDate" [bsConfig]="bsConfig" [datesEnabled]="enabledDates" *ngIf="datepickerInitialized">
                                </bs-datepicker-inline>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex" [hidden]="loading">
                    <div class="d-flex flex-row">

                        <div [hidden]="loading" *ngIf="openSlots && !loading" style="padding: 20px 10px; background: #f0f0f0; border-top-right-radius: 8px; border-bottom-right-radius: 8px; width: 100%">
                            <div class="mb-3" style="margin-left: 3px;" *ngIf="openSlots && !loading">
                                <span style="font-weight: bold;font-size: 16px;line-height: 112%;color: #676A6C;">{{ dateValue | date: 'EEE' | titlecase }} {{ dateValue | date: 'dd'}} de {{ dateValue | date: 'LLLL' | titlecase }}</span>
                            </div>

                            <div class="slot-group" *ngIf="!doctor.config_arrival_order">
                                <div *ngFor="let slot of slots">
                                    <button type="button" class="btn button-slot btn-secondary" *ngIf="slot.id !== selectedSlot" (click)="confirmSchedule(slot.id)">{{ slot.start_date | date: 'HH:mm' : '-03:00' }}</button>

                                    <div class="d-flex justify-content-between" *ngIf="selectedSlot && selectedSlot === slot.id">
                                        <button type="button" style="width: 50%; margin-right: 5px; font-size: 10px;" class="btn button-confirm-slot" (click)="confirmSchedule(slot.id)">{{ slot.start_date | date: 'HH:mm' : '-03:00' }}</button>
                                        <button type="button" style="width: 50%; font-size: 10px;" class="btn btn-primary button-confirm" (click)="schedule(slot)">Confirmar</button>
                                    </div>
                                </div>
                            </div>

                            <div class="slot-group" *ngIf="doctor.config_arrival_order">
                                <div *ngFor="let shift of slotsGroupedByShift">
                                    <button type="button" class="btn button-slot btn-secondary" *ngIf="shift.id !== selectedSlot" (click)="confirmSchedule(shift.id)">{{ shift.label }}</button>

                                    <div class="d-flex justify-content-between" *ngIf="selectedSlot && selectedSlot === shift.id">
                                        <button type="button" style="width: 50%; margin-right: 5px; font-size: 10px;" class="btn button-confirm-slot" (click)="confirmSchedule(shift)">{{ shift.label }}</button>
                                        <button type="button" style="width: 50%; font-size: 10px;" class="btn btn-primary button-confirm" (click)="scheduleByShift(shift)">Confirmar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
</div>
