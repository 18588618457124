import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

//Utils services
import { DefaultHeaderModule } from '../../components';
import { AttendanceInfoPageComponent } from './attendance-info-page.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AddressPipeModule } from 'src/app/shared/pipes';
import { LoadingModule } from 'src/app/shared/components';

@NgModule({
    declarations: [
        AttendanceInfoPageComponent
    ],
    exports: [
        AttendanceInfoPageComponent,
    ],
    imports: [
        BrowserModule,
        DefaultHeaderModule,
        AngularSvgIconModule,
        AddressPipeModule,
        LoadingModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AttendanceInfoModule { }
