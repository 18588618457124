import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { DoctorListPageComponent } from './doctor-list-page.component';
import { DoctorCardModule } from '../../components';
import { LoadingModule } from 'src/app/shared/components';

@NgModule({
    declarations: [
        DoctorListPageComponent
    ],
    exports: [
        DoctorListPageComponent
    ],
    imports: [
        BrowserModule,
        LoadingModule,
        AngularSvgIconModule,
        DoctorCardModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DoctorListPageModule { }
