<div class="d-flex justify-content-center flex-column">
    <div class="d-flex justify-content-center">
        <svg-icon src="assets/svg/amigo-logo.svg" [svgClass]="'amigo-logo'" style="fill: #008cff; margin-top: 32px;"></svg-icon>
    </div>
    <div class="d-flex flex-column" style="margin-top: 24px;">
        <app-loading [hidden]="!loading"></app-loading>

        <div class="text-center" *ngIf="!loading" style="font-weight: 500; font-size: 20px; line-height: 112%;">Agendamento concluído com sucesso</div>

        <div class="d-flex flex-column" *ngIf="!loading" style="width: 100%; padding: 24px; background-color: #f2f5f7; border-radius: 8px; max-width: 800px; margin: 0 auto; margin-top: 24px;">
            <div class="d-flex flex-column" style="border-bottom: 1px solid #DFE6EC; margin-bottom: 16px; padding-bottom: 16px;">
                <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">
                    Localização
                </div>

                <div class="d-flex" style="margin-top: 16px;" *ngIf="place">
                    <div style="margin-right: 16px;">
                        <img src="{{ company_logo.url }}" alt="" *ngIf="company_logo" style="width: 54px; height: 54px;">
                        <img src="assets/svg/company-blank.svg" alt="" *ngIf="!company_logo" style="width: 54px; height: 54px; object-fit: scale-down;">
                    </div>
                    <div class="d-flex flex-column">
                        <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">{{ place.company.name }}</div>

                        <div style="margin-top: 8px;" style="font-weight: 500;font-size: 14px;line-height: 115%;">
                            <span class="d-block" *ngIf="place.address && event && !event.is_tele">{{ place.address | address }}</span>
                            <span class="d-block" *ngIf="event && event.is_tele">(Atendimento online)</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column" style="border-bottom: 1px solid #DFE6EC; margin-bottom: 16px; padding-bottom: 16px;">
                <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">
                    Profissional
                </div>

                <div class="d-flex text-truncate" style="margin-top: 16px; max-width: 500px;">
                    <app-doctor-card [doctorData]="doctor"></app-doctor-card>
                </div>
            </div>

            <div class="d-flex flex-column" style="border-bottom: 1px solid #DFE6EC; margin-bottom: 16px; padding-bottom: 16px;">
                <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">
                    <svg-icon src="{{ status ? 'assets/svg/double-check.svg' : 'assets/svg/scheduled-icon.svg' }}" style="bottom: 1px;position: relative;margin-right: 8px; width: 16px; height: 16px;"></svg-icon> Data
                </div>

                <div class="d-flex" style="margin-top: 16px;">
                    <div class="d-flex align-items-center justify-content-between" *ngIf="slot" style="width: 100%;">
                        <div>{{ slot.start_date | date: 'dd' }} de {{ slot.start_date | date: 'MMMM' | titlecase }}<span *ngIf="!slot.shift"> às {{ slot.start_date | date: 'HH:mm' : '-03:00' }}</span></div>
                    </div>
                </div>

                <div style="font-size: 15px;" *ngIf="slot.shift"> A partir das {{ slot.shift.attendance_start }} às {{ slot.shift.attendance_end }}</div>
            </div>

            <div class="d-flex justify-content-between align-items-center">
                <div style="font-weight: bold;font-size: 17px;line-height: 120%;color: #676A6C;">
                    Tipo de atendimento
                </div>

                <div style="font-size: 14px; font-weight: 300; line-height: 120%;color: #676A6C; margin-top: 8px;" *ngIf="event">
                    {{ event.name }}
                </div>
            </div>
        </div>
    </div>
</div>
