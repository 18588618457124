import * as lodash from 'lodash';

const getSlotShift = (shift) => {
    const hour = lodash.toNumber(shift.hour.split(':')[0]);

    if (hour < 12) {
        return 'MORNING';
    }

    if (hour > 17) {
        return 'NIGHT'
    }

    return 'AFTERNOON'
}

const getShiftLabel = (shift) => {
    const labels = {
        MORNING: 'Manhã',
        AFTERNOON: 'Tarde',
        NIGHT: 'Noite'
    };

    return labels[shift];
}

const getArrivalShift = (data, shift) => {
    const response = {
        attendance_start: data.start,
        attendance_end: data.end,
    };

    const startHour = lodash.toNumber(data.start.split(':')[0]);

    const endHour = lodash.toNumber(data.end.split(':')[0]);

    if (shift === 'Manhã') {
        if (endHour > 12) {
            response.attendance_end = '12:00';
        }
    }

    if (shift === 'Tarde') {
        if (startHour < 12) {
            response.attendance_start = "12:00";
        }

        if (endHour > 18) {
            response.attendance_end = "18:00";
        }
    }

    if (shift === 'Noite') {
        response.attendance_start = "18:00";
    }

    return response;
}

const mountSlotShift = (slots) => {
    return slots.reduce((acc, slot) => {
        const shift = getSlotShift(slot);

        if (!acc[shift]) {
            acc[shift] = {
                id: slot.id,
                label: getShiftLabel(shift),
                slots: []
            };
        }

        acc[shift].slots.push(slot);

        return acc;
    }, {});

}

export {
    getArrivalShift,
    mountSlotShift
}
