import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';

@Injectable()
export class CloseModalService {
    public onClose: Subject<any> = new Subject();

    constructor(
        public bsModalRef: BsModalRef,
    ) { }

    hideModal(data): void {
        this.onClose.next(data);
        this.bsModalRef.hide();
    }
}
