<main>
    <router-outlet></router-outlet>
</main>

<footer>
    <div class="row">
        <div class="col-12 pt-3 pl-0">
            <button class="btn btn-primary" *ngIf="url.includes('/mobile/select-payment/creditcard/card')" [disabled]="!creditcardService.isValid" (click)="nextStep()">Próximo</button>
            <button class="btn btn-primary" *ngIf="url.includes('/mobile/select-payment/creditcard/owner')" [disabled]="!creditcardService.isValid" (click)="backToResumePage()">Próximo</button>
            <svg-icon src="assets/svg/arrow-right-white.svg" class="next-step-arrow-left"></svg-icon>
        </div>
    </div>
</footer>
