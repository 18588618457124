import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable()
export class PatientService {
    patientInfo = (data, callback) => this.globalService.mountResource('GET', { url: '/patient' }, data, callback);
    add = (data, callback) => this.globalService.mountResource('POST', { url: '/patient' }, data, callback);
    companyInfo = (data, callback) => this.globalService.mountResource('GET', { url: '/patient/info' }, data, callback);

    constructor (
        protected globalService: GlobalService
    ) { }
}
