import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CompanyPageComponent } from './company-page.component';
import { AddressPipeModule, PaymentMethodPipeModule, PhonePipeModule } from 'src/app/shared/pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        CompanyPageComponent
    ],
    exports: [
        CompanyPageComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,

        PhonePipeModule,
        AddressPipeModule,
        PaymentMethodPipeModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CompanyPageModule { }
