import { NgModule } from '@angular/core';
import { PaymentMethodPipe } from './payment-method.pipe';

@NgModule({
    declarations: [
        PaymentMethodPipe,
    ],
    exports: [
        PaymentMethodPipe,
    ]
})
export class PaymentMethodPipeModule { }
