import { Component } from '@angular/core';

@Component({
    selector: 'app-layout-default',
    templateUrl: './layout-default.component.html',
    //   styleUrls: ['./layout-default.component.scss']
})
export class LayoutDefaultComponent {
    constructor() { }

}
