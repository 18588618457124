import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';

import { AlertMessageService, AttendanceService, AuthService, CheckoutService, CompanyService, ResumeCheckoutService } from '../../../shared/services';
import { Location } from '@angular/common';
import { ModalShareAttendanceResume } from '../../modals';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-scheduled-successfully-page',
    templateUrl: './scheduled-successfully-page.component.html',
    styleUrls: ['./scheduled-successfully-page.component.scss']
})
export class ScheduledSuccessfullyPage implements OnInit {
    patient = null;
    slot = null;
    doctor = null;
    place = null;
    event = null;
    user = null;
    company_logo = null;
    loading = false;
    status = null;

    constructor(
        private checkoutService: CheckoutService,
        private location: Location,
        private bsModalService: BsModalService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private companyService: CompanyService,
        private resumeCheckoutService: ResumeCheckoutService,
        private attendanceService: AttendanceService,
        private alertMessageService: AlertMessageService
    ) { }

    ngOnInit(): void {
        this.setDefaultStyle();
        this.user = this.authService.getUser();
        this.patient = this.checkoutService.getPatient();
        this.doctor = this.checkoutService.getDoctor();
        this.place = this.checkoutService.getPlace();
        this.event = this.checkoutService.getEvent();
        this.slot = this.checkoutService.getSlot();
        this.company_logo = this.companyService.getCompanyLogo();

        if (!this.patient || !this.doctor || !this.place || !this.event || !this.slot) {
            this.router.navigate(['/desktop/search'], { queryParamsHandling: 'preserve' });

            return;
        }

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                skdld: true
            },
            queryParamsHandling: 'merge'
        });
    }

    setDefaultStyle() {
        document.body.style.backgroundColor = 'white';
    }

    confirmAttendance() {
        const data = {
            id: this.resumeCheckoutService.getNewAttendanceId()
        };

        if (!data.id) {
            return;
        }

        this.loading = true;

        this.attendanceService.confirmAttendance(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.status = 'CONFIRMED';
            this.alertMessageService.open('Atendimento confirmado com sucesso!', null, 'success');
        });
    }

    openShareModal() {
        this.bsModalService.show(ModalShareAttendanceResume).setClass('modal-sm');
    }
}
