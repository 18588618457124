import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { MobileConfigurationPageComponent } from './configuration-page.component';

@NgModule({
    declarations: [
       MobileConfigurationPageComponent
    ],
    exports: [
       MobileConfigurationPageComponent
    ],
    imports: [
        RouterModule,
        BrowserModule,
        AngularSvgIconModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConfigurationPageModule { }
