import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SelectAddressPageComponent } from './select-address-page.component';
import { AddressPipeModule } from 'src/app/shared/pipes';

@NgModule({
    declarations: [
        SelectAddressPageComponent,
    ],
    exports: [
        SelectAddressPageComponent,
    ],
    imports: [
        BrowserModule,

        AddressPipeModule,
        AngularSvgIconModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SelectAddressPageModule { }
