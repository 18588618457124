import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { IsValidCPF, IsValidDate, isBeforeDate } from '../../../shared/helpers';
import { Location } from '@angular/common';

import { CreditCardService, MobileCheckoutService } from '../../services';
import { CepService, CheckoutService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-creditcard-owner',
    templateUrl: './creditcard-owner.component.html',
    styleUrls: ['./creditcard-owner.component.scss']
})
export class CreditcardOwnerComponent implements OnInit {
    cardOwnerForm: FormGroup;
    expirationDate: Date;
    cvv_mask;
    cvv_placeholder = '000';

    constructor(
        private fb: FormBuilder,
        private checkoutService: MobileCheckoutService,
        private creditCardService: CreditCardService,
        private cepService: CepService,
        private router: Router,
        private route: ActivatedRoute,
        private checkoutServiceDesktop: CheckoutService
    ) { }

    ngOnInit() {
        this.creditCardService.isValid = false;

        if (!this.checkoutService.getCreditCard() || !this.checkoutService.getCreditCard().card) {
            this.navigateByUrl('/mobile/select-payment');
            return;
        }

        if (this.route.snapshot.queryParams.wasDesktop) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    wasDesktop: ''
                },
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });

            this.checkoutServiceDesktop.setSlot(null);
            this.navigateByUrl('/mobile/card-info');
            return;
        }

        if (!this.checkoutService.getCreditCard().card) {
            this.navigateByUrl('/mobile/select-payment');

            return;
        }

        this.renderForm();
    }

    navigateByUrl(url) {
        this.router.navigate([url], { queryParamsHandling: 'preserve' });
    }

    renderForm() {
        const creditcard = this.checkoutService.getCreditCard();

        this.cardOwnerForm = this.fb.group({
            name: ['', [Validators.required]],
            born: ['', [Validators.required]],
            address: ['', [Validators.required]],
            district: ['', [Validators.required]],
            addressNumber: ['', [Validators.required]],
            complement: [''],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            cpf: ['', [Validators.required]],
            cellphone: ['', [Validators.required]],
        }, {
            validators: [
                IsValidCPF('cpf'),
                IsValidDate('born'),
                isBeforeDate('born')
            ]
        });

        if (creditcard.owner && Object.keys(creditcard.owner).length && creditcard.address && Object.keys(creditcard.address).length) {
            this.cardOwnerForm.setValue(Object.assign({}, creditcard.owner, creditcard.address));
            this.creditCardService.isValid = true;
            return;
        }

        this.getAddressCepInfo();

        this.cardOwnerForm.valueChanges.subscribe((status) => {
            this.creditCardService.isValid = this.cardOwnerForm.valid;

            if (this.creditCardService.isValid) {
                const mountedData = this.mountData();

                this.checkoutService.setCreditcardOwner(mountedData.owner);
                this.checkoutService.setCreditcardAddress(mountedData.address);
            }
        });
    }

    get f() {
        return this.cardOwnerForm.controls;
    }

    getAddressCepInfo() {
        const addressValues = this.cepService.mobileCardFormCep;

        if (!addressValues) {
            return;
        }

        this.completeAddressValues(addressValues);
    }

    mountData() {
        return {
            owner: {
                name: this.cardOwnerForm.value.name,
                born: this.cardOwnerForm.value.born,
                cpf: this.cardOwnerForm.value.cpf,
                cellphone: this.cardOwnerForm.value.cellphone
            },
            address: {
                address: this.cardOwnerForm.value.address,
                district: this.cardOwnerForm.value.district,
                addressNumber: this.cardOwnerForm.value.addressNumber,
                complement: this.cardOwnerForm.value.complement,
                city: this.cardOwnerForm.value.city,
                state: this.cardOwnerForm.value.state,
            }
        }
    }

    cleanAddressValues() {
        this.cardOwnerForm.controls.address.setValue('');
        this.cardOwnerForm.controls.district.setValue('');
        this.cardOwnerForm.controls.addressNumber.setValue('');
        this.cardOwnerForm.controls.complement.setValue('');
        this.cardOwnerForm.controls.state.setValue('');
        this.cardOwnerForm.controls.city.setValue('');
    }

    completeAddressValues(cep) {
        this.cardOwnerForm.controls.address.setValue(cep['logradouro']);
        this.cardOwnerForm.controls.district.setValue(cep['bairro']);
        this.cardOwnerForm.controls.complement.setValue(cep['complemento']);
        this.cardOwnerForm.controls.city.setValue(cep['localidade']);
        this.cardOwnerForm.controls.state.setValue(this.cepService.parseStateName(cep['uf']));
    }
}
