import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DeviceService, AttendanceService, ExternalService } from '../../../shared/services';

@Component({
    selector: 'app-attendance-status-page',
    templateUrl: './attendance-status-page.component.html',
    styleUrls: ['./attendance-status-page.component.scss']
})
export class AttendanceStatusPageComponent implements OnInit {
    token = null;
    action = null;
    patient = null;
    loading: boolean = true;
    attendance = null;
    isValidToken: boolean = true;
    madeRequest: boolean = false;
    actionMessage = null;

    constructor (
        private route: ActivatedRoute,
        private attendanceService: AttendanceService,
        private deviceService: DeviceService,
        private externalService: ExternalService
    ) {}

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;

        if (this.deviceService.isWeb()) {
            this.setBodyStyle();
        }

        if (!this.token) {
            this.loading = false;
            this.isValidToken = false;

            return;
        }

        this.getAttendanceInfo();
        this.externalService.setOpenedLinkStatus({ token: this.token }, () => {});
    }

    getAttendanceInfo() {
        const data = {
            token: this.token
        };

        this.attendanceService.getAttendanceByToken(data, (error, resp) => {
            this.loading = false;

            if (error || !resp) {
                this.isValidToken = false;
                return;
            }

            this.attendance = resp || null
            this.isValidToken = true;
        });
    }

    submit(action) {
        const data = {
            token: this.token,
            action: action
        };

        this.loading = true;

        this.attendanceService.attendanceStatus(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.isValidToken = false;
                return;
            }

            this.isValidToken = true;
            this.madeRequest = true;
            this.actionMessage = data.action === 'CONFIRMED' ? 'confirmado' : 'cancelado';
        });
    }

    setBodyStyle() {
        document.querySelector('body').style.backgroundColor = '#E5E5E5';
    }
}
