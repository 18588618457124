<div class="row card-row" style="background-color: #E5E5E5; width: 100%; height: 100vh;">
    <div class="offset-md-2 col-12 col-md-8 card-column" style="padding: 0 !important; border-radius: 8px;display: flex;justify-content: center;align-items: center;">
        <main style="background-color: white; border-radius: 10px; width: 100%; max-width: 100%;">
            <div class="card">
                <div class="card-body">
                    <app-loading [hidden]="!loading"></app-loading>

                    <main *ngIf="!isValidToken && !loading">
                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                                <img src="assets/img/error-icon.png" alt="" style="width: 120px;">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                                Erro!
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                                Token inválido ou já utilizado!
                            </div>
                        </div>
                    </main>

                    <main *ngIf="!formStep && !loading && isValidToken">
                        <div *ngIf="patient && patient.is_novartis">
                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                                    <img src="assets/img/success-icon.png" alt="" style="width: 120px;">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                                    Sucesso!
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                                    Prezado(a) <strong>{{ patient.name }},</strong>
                                    <br>
                                    Continue cuidando da sua saúde e conte conosco na sua jornada de tratamento!
                                    <br>
                                    <br>
                                    Muito obrigado!
                                </div>
                            </div>
                        </div>

                        <div *ngIf="patient && !patient.is_novartis">
                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                                    <img src="assets/img/success-icon.png" alt="" style="width: 120px;">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                                    Obrigado!
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                                    Conte conosco sempre!
                                    <br>
                                    Até breve!
                                    <br>
                                </div>
                            </div>
                        </div>
                    </main>

                    <main *ngIf="formStep && !loading && isValidToken">
                        <div *ngIf="patient && !patient.is_novartis">
                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                                    <img src="assets/img/user-profile.png" style="border-radius: 43px;">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-top: 8px;">
                                    <span style="color: #008cff; font-weight: bold;">Dr(a). {{ doctor.name }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-top: 8px;">
                                    {{ doctor.specialty }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-top: 16px; font-size: 16px;line-height: 150%;color: #676A6C;">
                                    Olá, {{ patient.name }}!
                                    <br>
                                    Classifique sua experiência de 01 a 05.
                                    <br>
                                    <span style="font-size: 16px;">
                                        Clique na quantidade de estrelas abaixo
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-top: 24px;">
                                    <svg-icon src="assets/svg/nps-star.svg" [ngClass]="{ 'fill-yellow': rate >= 1 }" style="fill: white; margin-right: 15px; cursor: pointer;" (click)="rate = 1"></svg-icon>
                                    <svg-icon src="assets/svg/nps-star.svg" [ngClass]="{ 'fill-yellow': rate >= 2 }" style="fill: white; margin-right: 15px; cursor: pointer;" (click)="rate = 2"></svg-icon>
                                    <svg-icon src="assets/svg/nps-star.svg" [ngClass]="{ 'fill-yellow': rate >= 3 }" style="fill: white; margin-right: 15px; cursor: pointer;" (click)="rate = 3"></svg-icon>
                                    <svg-icon src="assets/svg/nps-star.svg" [ngClass]="{ 'fill-yellow': rate >= 4 }" style="fill: white; margin-right: 15px; cursor: pointer;" (click)="rate = 4"></svg-icon>
                                    <svg-icon src="assets/svg/nps-star.svg" [ngClass]="{ 'fill-yellow': rate == 5 }" style="fill: white; margin-right: 15px; cursor: pointer;" (click)="rate = 5"></svg-icon>
                                </div>
                            </div>

                            <div class="row">
                                <div class="offset-md-3 col-md-6 col-12 text-center" style="margin-top: 32px;">
                                    <textarea maxlength="255" id="text-area" [(ngModel)]="textAreaValue"></textarea>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12 text-center mt-3">
                                    <button class="btn btn-primary btn-mobile" (click)="submit()">Enviar</button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="patient && patient.is_novartis">
                            <div class="row">
                                <div class="col-md-12 col-12 text-center" style="margin-top: 16px; font-size: 16px;line-height: 150%;color: #676A6C;">
                                    <div>
                                        <img style="max-width: 150px;  max-height: 150px;" src="assets/img/novartis-nps.jpg" alt="">
                                    </div>

                                    <span style="margin-top: 30px;">
                                        Olá, {{ patient.name }}!
                                    </span>

                                    <br>
                                    Responda gratuitamente de 0 a 10, onde 0 significa NUNCA e 10 SEMPRE
                                    <br>
                                    <br>
                                    <span style="font-size: 16px;">
                                        Você recomendaria o Programa Bem Estar a um familiar ou conhecido?
                                    </span>
                                </div>
                            </div>

                            <div class="d-flex justify-content-center" style="margin-top: 20px;">
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 0, 'btn-secondary': rate != 0 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 0">0</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 1, 'btn-secondary': rate != 1 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 1">1</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 2, 'btn-secondary': rate != 2 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 2">2</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 3, 'btn-secondary': rate != 3 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 3">3</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 4, 'btn-secondary': rate != 4 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 4">4</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 5, 'btn-secondary': rate != 5 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 5">5</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 6, 'btn-secondary': rate != 6 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 6">6</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 7, 'btn-secondary': rate != 7 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 7">7</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 8, 'btn-secondary': rate != 8 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 8">8</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 9, 'btn-secondary': rate != 9 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 9">9</button>
                                <button type="button" class="btn" [ngClass]="{ 'btn-primary': rate == 10, 'btn-secondary': rate != 10 }" style="margin-top: 5px; margin-right: 5px;" (click)="rate = 10">10</button>
                            </div>

                            <div class="row" style="margin-top: 20px;">
                                <div class="col-md-12 col-12 text-center mt-3">
                                    <button class="btn btn-primary btn-mobile" (click)="submit()">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </main>
    </div>
</div>
