import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Component({
    selector: 'app-suggest-multi-select',
    templateUrl: './suggest-multi-select.component.html',
    styleUrls: ['./suggest-multi-select.component.scss']
})
export class SuggestMultiSelectComponent implements OnInit {
    @Input() items;
    @Input() options = {
        placeholder: '',
        customClass: '',
        selectedIds: []
    };
    @Output() toggleItems = new EventEmitter<any>();
    @HostListener('document:click', ['$event.target'])
    onClick(target) {
        const componentBox = this.el.nativeElement.querySelectorAll('.suggest-multi')[0];

        if (!componentBox.contains(target)) {
            this.isOpened = false;

            if (!this.amountSelected) {
                return;
            }

            this.setPlaceholder();
        }
    }

    originalItems = [];
    searchText = '';
    isOpened = false;
    amountSelected = 0;
    firstEnter = true;

    constructor(
        private el: ElementRef
    ) { }

    ngOnInit() {
    }

    clear() {
        this.searchText = '';

        this.items.forEach((item) => {
            item.selected = false;
        });

        this.amountSelected = 0;
        this.toggleItems.emit([]);
        this.isOpened = false;
        this.setPlaceholder();
    }

    apply() {
        const selectedIds = [];

        this.items.forEach((item) => {
            if (!item.selected) {
                return;
            }

            selectedIds.push(item.id);
        });

        this.amountSelected = selectedIds.length;
        this.toggleItems.emit(selectedIds);
        this.isOpened = false;
        this.setPlaceholder();
    }

    focusInput() {
        this.searchText = '';
        this.isOpened = true;
        this.firstEnter = false;
    }

    setPlaceholder() {
        if (!this.options.placeholder) {
            return;
        }

        this.searchText = this.options.placeholder.replace('${qtd}', `${this.amountSelected}`);
    }
}
