<main style="padding-left: 7px; background-color: white;">
    <app-loading [hidden]="!datepickerInitializedLoading"></app-loading>

    <div class="mt-3">
        <svg-icon src="assets/svg/mobile-arrow-left.svg" style="padding: 15px;" [hidden]="datepickerInitializedLoading" (click)="bsModalRef.hide()"></svg-icon>
    </div>

    <main [hidden]="datepickerInitializedLoading" class="mt-2">
        <bs-datepicker-inline class="datepicker" (bsValueChange)="onValueChange($event)" [bsValue]="selectedDate"
        bsDatepicker [minDate]="options.minDate" [(ngModel)]="dateValue" name="dateValue"
        ngDefaultControl [dateCustomClasses]="dateCustomClasses" [maxDate]="options.maxDate"
        [bsConfig]="bsConfig" [datesEnabled]="enabledDates" *ngIf="datepickerInitialized">
        </bs-datepicker-inline>

        <div class="col-12 slot" style="padding-top: 18px;">
            <app-loading [hidden]="!loading || !openSlots" class="slot-loading"></app-loading>
            <div class="d-block" *ngIf="openSlots && !loading">
                <div class="d-flex row slot-group slot-scroll" *ngIf="slots.length" style="background-color: white; padding-bottom: 64px;">
                    <div class="col-4 mb-3" *ngFor="let slot of slots">
                        <button type="button" class="btn button-slot btn-outline-primary" [ngClass]="{ 'selected-slot': slot.id === selectedSlot.id }" (click)="setSlot(slot)">{{ slot.start_date | date: 'HH:mm' : '-03:00' }}</button>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-primary btn-block btn-save" [disabled]="!this.selectedSlot" (click)="saveSlot()">Salvar</button>
                </div>
            </div>
        </footer>
    </main>
</main>
