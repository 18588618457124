import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoadingModule } from 'src/app/shared/components';
import { NotFoundPageComponent } from './not-found-page.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    declarations: [
        NotFoundPageComponent
    ],
    exports: [
        NotFoundPageComponent,
    ],
    imports: [
        FormsModule,
        RouterModule,
        AngularSvgIconModule,
        LoadingModule,
        BrowserModule,
        NgxMaskModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NotFoundPageModule { }
