<div class="card" [ngClass]="{ 'increase-width': openSlots }" style="min-height: 405px;">
    <div class="card-body">
        <div class="row">
            <div class="col-12 pb-3" style="border-bottom: 1px solid #f0f0f0;">
                <span class="change-slot-label">Alterar data e horário</span>
                <svg-icon src="assets/svg/close.svg" class="float-right c-pointer p-2" (click)="bsModalRef.hide()"></svg-icon>
            </div>
        </div>

        <div class="d-flex" style="width: 100%;">
            <div class="card-body d-flex" style="padding: 0;">
                <div *ngIf="loading" style="width: 100%;" class="d-flex justify-content-center align-items-center">
                    <app-loading style="margin-top: 40px;"></app-loading>
                </div>

                <div class="d-flex flex-column">
                    <div class="d-flex flex-column">
                        <div class="d-flex">
                            <div class="pt-4 pl-3" [hidden]="loading">
                                <div class="d-flex">
                                    <div>
                                        <img *ngIf="doctor.thumb_url" src="{{ doctor.thumb_url }}" class="img-profile">
                                        <img *ngIf="!doctor.thumb_url" src="assets/img/user-profile.png" class="img-profile">
                                    </div>

                                    <div class="doctor-info text-truncate">
                                        <span class="doctor-name text-left d-block">{{ doctor.name }}</span>
                                        <span class="doctor-crm mb-2 align-middle" *ngIf="doctor.council_name">{{ doctor.council_name }}</span>
                                        <span class="doctor-crm mb-2 align-middle" *ngIf="doctor.council_number && doctor.council_name">&nbsp;{{ doctor.council_number }}</span>
                                        <span class="doctor-crm mb-2 align-middle" *ngIf="doctor.council_number && doctor.council_name && doctor.specialty">&nbsp;- {{ doctor.specialty }}</span>
                                        <span class="d-block mt-2" *ngIf="doctor.has_tele && (doctor.council_number || doctor.council_name || doctor.specialty)">
                                            <svg-icon src="assets/svg/telemedicine.svg" class="button-icon"></svg-icon>
                                            Telemedicina disponível
                                        </span>
                                        <span class="d-block mt-2" *ngIf="doctor.has_tele && !doctor.council_number && !doctor.council_name && !doctor.specialty" style="margin-top: 29px !important;">
                                            <svg-icon src="assets/svg/telemedicine.svg" class="button-icon"></svg-icon>
                                            Telemedicina disponível
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <div class="mt-2 d-block place">Clínica</div>

                                    <div class="dropdown" style="width: 400px;">
                                        <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left text-truncate" type="button" *ngIf="place">
                                            <span class="dropdown-text text-truncate">
                                                <span class="text-truncate" *ngIf="event.is_tele">{{ event.name }} - Atendimento online</span>
                                                <span class="text-truncate" *ngIf="!event.is_tele && place.address">{{ place.name }} - {{ place.address | address }}</span>
                                                <span class="text-truncate" *ngIf="!event.is_tele && !place.address"> - ENDEREÇO NÃO INFORMADO</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <div class="mt-2 d-block place">Tipo de atendimento</div>

                                    <div class="dropdown" style="width: 400px;">
                                        <button class="btn btn-lg dropdown-btn dropdown-toggle btn-outline-black text-left text-truncate" type="button" *ngIf="place && event">
                                            <span class="dropdown-text text-truncate" *ngIf="event && !event.is_tele">{{ event.name }}</span>
                                            <span class="dropdown-text text-truncate" *ngIf="event && event.is_tele">Atendimento online</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="pt-4 pr-3" [hidden]="loading">
                                <bs-datepicker-inline (bsValueChange)="onValueChange($event)" [bsValue]="selectedDate"
                                bsDatepicker [minDate]="options.minDate" [datesEnabled]="enabledDates"
                                [(ngModel)]="dateValue" name="dateValue" ngDefaultControl [dateCustomClasses]="dateCustomClasses"
                                [maxDate]="options.maxDate" [bsConfig]="config" *ngIf="datepickerInitialized">
                                </bs-datepicker-inline>
                            </div>
                        </div>

                        <div *ngIf="!loading && datepickerInitialized" class="d-flex pl-3" style="font-size: 12px;line-height: 16px;color: #A7B1C2;">
                            <svg-icon src="assets/svg/money.svg" style="position: relative; top: -1px;" class="money-icon mr-2"></svg-icon>

                            <span class="text-truncate payment-method" title="{{ doctor.payment_methods | payment }}" *ngIf="!procedurePayments">
                                <span *ngIf="(((place && event) || (place && (!event && place.is_tele))) && (doctor.eventPrices && doctor.eventPrices[event.id]))">R$ {{ doctor.eventPrices[event.id].toFixed(2) }} - </span>
                                Formas de pagamento: {{ doctor.payment_methods | payment }}
                            </span>

                            <span class="text-truncate payment-method" *ngIf="procedurePayments" title="{{ procedurePayments.payment_methods | payment }}">
                                <span *ngIf="procedurePayments.price">R$ {{ procedurePayments.price.toFixed(2) }} - </span>
                                Formas de pagamento:
                                <span *ngIf="procedurePayments.payment_methods && procedurePayments.payment_methods.length" title="{{ procedurePayments.payment_methods | payment }}">{{ procedurePayments.payment_methods | payment }}</span>
                                <span *ngIf="!procedurePayments.payment_methods || (procedurePayments.payment_methods && !procedurePayments.payment_methods.length)">INDISPONÍVEL</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="d-flex flex-row">

                        <div [hidden]="loading" *ngIf="openSlots && !loading && (slots && slots.length)" style="padding: 20px 10px; background: #f0f0f0;">
                            <div class="mb-3" style="margin-left: 3px;" *ngIf="openSlots && !loading && slots.length">
                                <span style="font-weight: bold;font-size: 16px;line-height: 112%;color: #676A6C;">{{ dateValue | date: 'EEE' | titlecase }} {{ dateValue | date: 'dd'}} de {{ dateValue | date: 'LLLL' | titlecase }}</span>
                            </div>

                            <div class="slot-group">
                                <div *ngFor="let slot of slots">
                                    <button type="button" class="btn button-slot btn-secondary" *ngIf="slot.id !== selectedSlot" (click)="confirmSchedule(slot.id)">{{ slot.start_date | date: 'HH:mm' : '-03:00' }}</button>

                                    <div class="d-flex justify-content-between" *ngIf="selectedSlot && selectedSlot === slot.id">
                                        <button type="button" style="width: 50%; margin-right: 5px; font-size: 10px;" class="btn button-confirm-slot" (click)="confirmSchedule(slot.id)">{{ slot.start_date | date: 'HH:mm' : '-03:00' }}</button>
                                        <button type="button" style="width: 50%; font-size: 10px;" class="btn btn-primary button-confirm" (click)="schedule(slot)">Confirmar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
