import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class GlobalService {
    protected headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    protected httpParams = new HttpParams();

    constructor(private http: HttpClient) {
    }

    mountResource(method, options, data, callback?) {
        const microservices = {
            agendemais: 'https://api.agenmais.com.br'
        };
        let url;

        if (window.location.host === 'agende.app.br') {
			url = 'https://api.agende.app.br/api';
        } else if (window.location.host === 'stage.agende.app.br') {
			url = 'https://stage-api.agende.app.br/api';
		} else if (window.location.host.indexOf('localhost') !== -1) {
			url = 'http://localhost:3000/api';
        }

        url += options.url;
        let resource;

        if (options.micro_service && microservices[options.micro_service]) {
            url = microservices[options.micro_service] + options.url;
        }

        if (url.includes(':id')) {
            url = url.replace(':id', data.id);

            delete data.id;
        }

        switch (method) {
            case 'POST':
                resource = this.http.post(url, data, {
                    params: options.params || {},
                    headers: this.headers
                });
                break;

            case 'PUT':
                resource = this.http.put(url, data, {
                    headers: this.headers
                });
                break;

            case 'DELETE':
                resource = this.http.delete(url, {
                    headers: this.headers
                });
                break;

            default:
                resource = this.http.get(url, {
                    params: data || {},
                    headers: this.headers
                });
                break;
        }

        if (callback) {
            resource.subscribe((resp) => {
                let error = null;
                let response = {
                    data: null
                };

                if (resp.status === 'error') {
                    error = resp;
                } else {
                    response = resp;
                }

                if (options.returnResp) {
                    callback(error, response);
                    return;
                }

                callback(error, response.data);
            }, (resp) => {
                callback({
                    status: 'error',
                    message: (resp.error && resp.error.message) ? resp.error.message : 'Algo de errado aconteceu, por favor, tente novamente'
                });
            });

            return;
        }

        return resource;
    }
}
