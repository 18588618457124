import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isValidEmail } from 'src/app/shared/helpers';

import { DeviceService, CompanyService, AlertMessageService} from '../../../shared/services';

@Component({
    selector: 'app-leads-page',
    templateUrl: './leads-page.component.html',
    styleUrls: ['./leads-page.component.scss']
})
export class LeadsPageComponent implements OnInit {
    token = null;
    action = null;
    patient = null;
    loading: boolean = false;
    isValidToken: boolean = true;
    hasSubmited: boolean = false;
    setting = {
        logo_url: '../../../../assets/img/default-profile.png',
        company_id: null,
        template: {
            background_url: "../../../../assets/svg/background.svg",
            title: 'IOR - Instituto de olhos do Recife',
            subtitle: 'Inscreva-se abaixo para receber nosso atendimento exclusivo',
            show_logo: true,
            consentment_terms: 'Ao clicar em prosseguir abaixo, eu aceito o Termo de Uso e Consentimento.',
            background: {
                url: ''
            }
        }
    };
    lead = {
        name: '',
        phone: '',
        email: null
    };
    isWeb: boolean = true;
    COMPANY_NOT_REQUIRE_EMAIL = [16731];
    REDIRECT_LINK_BY_COMPANY_ID = {
        16731: `https://wa.me/5585997988444?text=${window.encodeURIComponent('Olá! Quero agendar minha consulta.')}`,
        43477: 'https://wa.link/g3lget',
        1168: `https://wa.me/556183787898?text=${window.encodeURIComponent('Olá! Quero agendar minha consulta.')}`,
        17705: 'https://wa.me/5598981158115',
        24732: 'https://wa.me/71981154240'
    };
    isEmailRequired: boolean = true;
    isIframe: boolean = window.location !== window.parent.location;

    constructor (
        private route: ActivatedRoute,
        private companyService: CompanyService,
        private deviceService: DeviceService,
        private alertMessageService: AlertMessageService
    ) {}

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;

        this.isWeb = this.deviceService.isWeb();

        if (!this.token) {
            this.isValidToken = false;
            return;
        }

        if (this.isIframe) {
            this.setBodyTransparent();
        }

        this.getFormSettings();
    }

    setBodyTransparent() {
        document.getElementById("amigo-body").style.backgroundColor = "transparent";
    }

    getFormSettings() {
        const data = {
            token: this.token
        };

        this.loading = true;

        this.companyService.getLeadFormSettings(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.isValidToken = false;
                return;
            }

            this.setting = resp;
        });
    }

    isValid(data) {
        const nameSplited = data.name.split(' ');

        if (!data.name) {
            this.alertMessageService.open('O nome é um campo obrigatório', 'warning');
            return false;
        }

        if (!nameSplited || !nameSplited.length || nameSplited.length === 1) {
            this.alertMessageService.open('Preencha o nome e o seu sobrenome', null, 'warning');
            return false;
        }

        if (data.email && !isValidEmail(data.email)) {
            this.alertMessageService.open('Digite um email válido!', null, 'warning');
            return false;
        }

        if (!data.phone || data.phone.length !== 11) {
            this.alertMessageService.open('O telefone é um campo obrigatório e deve possuir 11 dígitos', null, 'warning');
            return false;
        }

        return true;
    }

    submit() {
        const data = Object.assign({
            token: this.token
        }, this.lead);

        if (!this.isValid(data)) {
            return;
        }

        this.loading = true;

        this.companyService.addLead(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.isValidToken = false;
                return;
            }

            if (this.REDIRECT_LINK_BY_COMPANY_ID[this.setting.company_id]) {
                window.open(this.REDIRECT_LINK_BY_COMPANY_ID[this.setting.company_id], '_blank');
            }

            this.hasSubmited = true;
        });
    }
}
