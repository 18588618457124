import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoadingModule } from 'src/app/shared/components';
import { NpsPageComponent } from './nps-page.component';

@NgModule({
    declarations: [
        NpsPageComponent
    ],
    exports: [
        NpsPageComponent,
    ],
    imports: [
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        AngularSvgIconModule,
        LoadingModule,
        BrowserModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NpsPageModule { }
