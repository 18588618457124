import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attendance'
})
export class AttendancePipe implements PipeTransform {
    transform(status: any) {
        const keyMap = {
            'DONE': 'Realizado',
            'MISSED': 'Faltou',
            'CANCELED': 'Cancelou',
            'ARRIVED': 'Chegou',
            'SCHEDULED': 'Agendado'
        };

        if (!status) {
            return;
        }

       return keyMap[status];
    }
}
