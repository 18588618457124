import { NgModule } from '@angular/core';
import { PaymentAvailablePipe } from './payments-available.pipe';

@NgModule({
    declarations: [
        PaymentAvailablePipe
    ],
    exports: [
        PaymentAvailablePipe
    ]
})
export class PaymentAvailablePipeModule { }
