import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SuggestCitiesService } from '../../services';

@Component({
    selector: 'app-suggest-cities',
    templateUrl: './suggest-cities.component.html',
    styleUrls: ['./suggest-cities.component.scss']
})
export class SuggestCitiesComponent implements OnInit {
    @Output() selectCity = new EventEmitter<string>();
    @Output() clearCityInput = new EventEmitter<string>();
    @Input() selectedCity;

    private url = 'assets/json/cities.json';
    private cities: any = [];

    results: any = [];
    searchKeyword = '';
    city = '';

    constructor(
        private http: HttpClient,
        private suggestCitiesService: SuggestCitiesService
    ) {}

    ngOnInit(): void {
        this.city = this.selectedCity || '';
        this.suggestCitiesService.hasFilteredItems = false;
    }

    getCitiesJson() {
        this.http.get(this.url).subscribe((resp) => this.cities = resp);
    }

    selectEvent(item) {
        this.selectCity.emit(item);
    }

    onChangeSearch(val: string) {
        const regex = new RegExp(val, 'gi');

        this.results = this.cities.filter((city) => regex.test(city.normalize("NFD").replace(/[\u0300-\u036f]/g, '')));

        this.suggestCitiesService.hasFilteredItems = this.results.length ? true : false;
    }

    onClear() {
        this.results = [];
        this.suggestCitiesService.hasFilteredItems = false;
        this.clearCityInput.emit();
    }

    onFocused() {
        if (!this.cities.length) {
            this.getCitiesJson();
        }
    }
}
