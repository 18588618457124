import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { CardInfoPageComponent } from './card-info-page.component';
import { DoctorCardModule, CompanyCardModule } from '../../components';
import { LoadingModule } from 'src/app/shared/components';
import { RouterModule } from '@angular/router';
import { AddressPipeModule, PaymentAvailablePipeModule } from 'src/app/shared/pipes';

@NgModule({
    declarations: [
        CardInfoPageComponent
    ],
    exports: [
        CardInfoPageComponent
    ],
    imports: [
        RouterModule,
        BrowserModule,
        LoadingModule,
        AngularSvgIconModule,
        DoctorCardModule,
        CompanyCardModule,
        LoadingModule,
        AddressPipeModule,
        PaymentAvailablePipeModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CardInfoPageModule { }
