import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule } from '@angular/forms';

import { FilterPipeModule } from 'src/app/shared/pipes';
import { SuggestMultiSelectComponent } from './suggest-multi-select.component';

@NgModule({
    declarations: [
        SuggestMultiSelectComponent,
    ],
    exports: [
        SuggestMultiSelectComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,

        FilterPipeModule,
        AngularSvgIconModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SuggestMultiSelectModule { }
