import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'address'
})
export class AddressPipe implements PipeTransform {
    transform(entity) {
        let address;

        if (!entity || !entity.address_address) {
            return 'Endereço não informado';
        }

        address = entity.address_address;

        if (entity.address_number) {
            address += `, ${entity.address_number}`;
        }

        if (entity.address_city) {
            address += `, ${entity.address_city}`;

            if (entity.address_state) {
                address += `-${entity.address_state}`;
            }
        }

        if (!entity.address_city && entity.address_state) {
            address += `, ${entity.address_state}`;
        }

        if (entity.address_cep) {
            address += `, ${entity.address_cep}`;
        }

        return address;
    }
}
