import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  @Input() company;

  constructor() { }

  ngOnInit(): void {
  }

}
