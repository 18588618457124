import { FormGroup } from '@angular/forms';

export function MustBeDifferent(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if ((matchingControl.value && control.value) && matchingControl.value === control.value) {
            control.setErrors({ mustBeDifferent: true });
            return;
        }

        return;
    }
}
