import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ResumeCheckoutService {
    attendanceId: null;

    setNewAttendanceId(attendanceId) {
        localStorage.setItem('new_attendance_id', attendanceId);
    }

    getNewAttendanceId() {
        let new_attendance_id;

        try {
            new_attendance_id = localStorage.getItem('new_attendance_id');
        } catch (error) {}

        return new_attendance_id;
    }
}
