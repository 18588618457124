import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ng2-tooltip-directive';
import { LoadingModule } from 'src/app/shared/components';
import { NgxMaskModule } from 'ngx-mask';

import { CardInfoComponent } from './card-info.component';
import { CardBrandModule } from '../card-brand/card-brand.module';
import { PagSeguroService, CepService } from 'src/app/shared/services';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        CardInfoComponent
    ],
    exports: [
        CardInfoComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        CardBrandModule,
        LoadingModule,
        TooltipModule,
        RouterModule
    ],
    providers: [
        CepService,
        PagSeguroService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CardInfoModule { }
