<app-loading [hidden]="!loading"></app-loading>
<div class="modal-body pb-0" *ngIf="!loading">
    <div class="row">
        <div class="col-md-12 text-md-left mt-3 ml-1">
            <h4 *ngIf="title.text" class="h4-text">{{ title.text }}</h4>
            <h4 *ngIf="!title.text" class="h4-text">Selecione um novo horário</h4>
        </div>
    </div>
    <div class="row mt-4 ml-1" *ngIf="doctor">
        <div class="col-md-1 img-margin">
            <img *ngIf="doctor.file" src="{{ doctor.file.thumb_url }}" class="img-profile">
        </div>
        <div class="col-md-4 ml-2 pr-0">
            <span class="doctor-name align-middle">{{ doctor.name }}</span>
            <span class="doctor-crm align-middle">{{ doctor.label }}</span>
        </div>
        <div class="col-md-6 pr-0 date-margin">
            <div class="slot-datepicker text-right float-right">
                <span class="slot-datepicker-arrows slot-datepicker-arrow-left" (click)="previousDay()"> < </span>
                <span class="slot-datepicker-date" bsDatepicker [minDate]="options.minDate"
                [(ngModel)]="dateValue" name="dateValue" ngDefaultControl (bsValueChange)="onValueChange($event)"
                [maxDate]="options.maxDate" [bsConfig]="bsConfig">
                    {{ dateValue | date: 'dd' }} de <span class="text-capitalize">{{ dateValue | date: 'MMMM'}}, {{ dateValue | date: 'yyyy' }}</span>
                </span>
                <span class="slot-datepicker-arrows slot-datepicker-arrow-right" (click)="nextDay()"> > </span>
            </div>
        </div>
    </div>
    <div class="row slots mx-1 mb-1 text-center" *ngIf="doctor && slots.length">
        <div class="col-md-2 mb-4 slot" *ngFor="let slot of slots">
            <span *ngIf="selectedSlot.id == slot.id" class="without-border">{{ slot.start_date | date:'HH:mm' }}</span>
            <span *ngIf="selectedSlot.id !== slot.id" (click)="setSlot(slot)" [ngClass]="{ 'active': currentSelectedSlot && (selectedSlot.id !== slot.id) && slot.id == currentSelectedSlot.id }">{{ slot.start_date | date:'HH:mm' }}</span>
        </div>
    </div>
    <div *ngIf="!slots.length" class="row slots mx-1 mb-3 text-center">
        <div class="col-md-11 alert-warning not-available">
            <span>Não há horários disponíveis para o médico {{ doctor.name }} no dia {{ dateValue | date: 'dd' }} de {{ dateValue | date: 'MMMM'}}, de {{ dateValue | date: 'yyyy' }}</span>
        </div>
    </div>
    <div class="row mt-0">
        <div class="col-md-12 mr-1 mb-2">
            <button type="button" *ngIf="!button.text" [disabled]="!currentSelectedSlot" (click)="bsModalRef.hide()" class="btn btn-primary float-right book-btn p-0 mr-2">
                Confirmar
                <svg-icon src="assets/svg/verified.svg" [svgClass]="'verified-logo ml-2'"></svg-icon>
            </button>
            <button type="button" *ngIf="button.text" [disabled]="!currentSelectedSlot" (click)="bsModalRef.hide()" class="btn btn-primary float-right book-btn p-0 mr-2">
                {{ button.text }}
                <svg-icon src="assets/svg/verified.svg" [svgClass]="'verified-logo ml-2'"></svg-icon>
            </button>
        </div>
    </div>
</div>
