import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


//Utils services
import { DoctorCardModule, MobileDefaultHeaderModule } from '../../components';
import { MobileScheduledSucessfullyPageComponent } from './scheduled-succesfully-page.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AddressPipeModule } from 'src/app/shared/pipes';
import { LoadingModule } from 'src/app/shared/components';

@NgModule({
    declarations: [
        MobileScheduledSucessfullyPageComponent
    ],
    exports: [
        MobileScheduledSucessfullyPageComponent,
    ],
    imports: [
        BrowserModule,
        MobileDefaultHeaderModule,
        AngularSvgIconModule,
        AddressPipeModule,
        DoctorCardModule,
        LoadingModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ScheduledSuccessfullyPageModule { }
