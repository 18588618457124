import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AlertMessageService, CloseModalService, ExternalService, DeviceService } from '../../../shared/services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-modal-onboard',
    templateUrl: './modal-onboard.component.html',
    styleUrls: ['./modal-onboard.component.scss']
})
export class ModalOnboardComponent extends CloseModalService implements OnInit {
    loading: boolean = false;
    onboardType = null;
    patientName = null
    isMobile: boolean = false;

    constructor(
        public alertMessageService: AlertMessageService,
        public location: Location,
        public bsModalRef: BsModalRef,
        private route: ActivatedRoute,
        private externalService: ExternalService,
        private deviceService: DeviceService,
        private router: Router
    ) {
        super(bsModalRef);
    }

    ngOnInit() {
        this.isMobile = this.deviceService.isMobile();
        this.getOnboardType();
    }

    getOnboardType() {
        const data = {
            token: this.route.snapshot.queryParams.token
        };

        this.loading = true;

        this.externalService.getOnboardType(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.hideModal('');
                return;
            }

            this.onboardType = resp.type;
            this.patientName = resp.patient_name;
        });
    }

    closeOnboard() {
        if (this.isMobile) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    hasPopup: true
                },
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
        }

        this.hideModal(this.onboardType);
    }
}
