import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MobileRoutingModule } from './mobile.routing.module';
import { SharedModule } from '../shared/shared.module';

// Components
import {
    DoctorCardModule, CompanyCardModule, MobileCalendarModule,
    CreditcardCardModule, CreditcardOwnerModule,
    InsuranceComponent
} from './components';

// Modals
import {
    MobileModalAttendanceCardModule, ModalMobileCalendarModule
} from './modals';

//Pages
import {
    CalendarPageModule, MobileSelectPaymentPageComponent,
    DoctorListPageModule, CompanyListPageModule, CardInfoPageModule, SelectAddressPageModule,
    SelectEventPageModule, SelectDoctorPageModule, ResumeCheckoutPageModule,
    CreditcardPageComponent, ConfigurationPageModule, AttendanceHistoryModule,
    ScheduledSuccessfullyPageModule
} from './pages';

//Services
import { MobileSearchService, CreditCardService } from './services'

@NgModule({
    declarations: [
        // Components
        InsuranceComponent,

        // Modals

        //Pages
        MobileSelectPaymentPageComponent,
        CreditcardPageComponent,
    ],
    imports: [
        SharedModule,
        MobileRoutingModule,

        // Project components/pages modules
        DoctorListPageModule,
        CompanyListPageModule,
        DoctorCardModule,
        CompanyCardModule,
        CardInfoPageModule,
        SelectAddressPageModule,
        SelectEventPageModule,
        SelectDoctorPageModule,
        MobileCalendarModule,
        CalendarPageModule,
        CreditcardCardModule,
        CreditcardOwnerModule,
        ResumeCheckoutPageModule,
        ScheduledSuccessfullyPageModule,
        ConfigurationPageModule,
        AttendanceHistoryModule,
        MobileModalAttendanceCardModule,
        ModalMobileCalendarModule,
    ],
    providers: [
        CreditCardService,
        MobileSearchService
    ],
    bootstrap: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MobileModule { }
