import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { DoctorCardComponent } from './doctor-card.component';

@NgModule({
    declarations: [
        DoctorCardComponent
    ],
    exports: [
        DoctorCardComponent
    ],
    imports: [
        BrowserModule,
        AngularSvgIconModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DoctorCardModule { }
