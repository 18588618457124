<div class="container">
    <app-loading [hidden]="!loading"></app-loading>

    <div class="row" *ngIf="isMobile && !loading && onboardType">
        <div class="col-12">
            <img src="assets/img/new-attendance.png" *ngIf="onboardType === 'new-attendance' || onboardType === 'pos'" style="width: 100%; height: 292px;">
            <img src="assets/img/missed.png" *ngIf="onboardType === 'missed'" style="width: 100%; height: 292px;">
        </div>

        <div class="col-12" style="padding-top: 8px;">
            <span style="font-weight: bold;font-size: 22px;line-height: 120%;color: #AFC1CF;" *ngIf="patientName">
                Olá, {{ patientName }}!&nbsp;
                <br>
                <br>
            </span>

            <span style="font-weight: bold;font-size: 20px;line-height: 120%;color: #AFC1CF;" *ngIf="onboardType === 'new-attendance' || onboardType === 'pos'">
                Que bom te ver aqui.
                <br>
            </span>

            <span *ngIf="onboardType === 'new-attendance' || onboardType === 'pos'" style="font-weight: normal;font-size: 16px;line-height: 150%;color: #676A6C;">
                A seguir você vai agendar sua consulta de volta sem burocracia, fácil como deve ser.
            </span>

            <span *ngIf="onboardType === 'missed'" style="font-weight: normal;font-size: 16px;line-height: 150%;color: #676A6C;">
                <span style="font-weight: bold;font-size: 20px;line-height: 120%;color: #AFC1CF;">
                    Vimos que você faltou a consulta.
                </span>
                <br>
                Sem problemas, que tal agendar
                <br>
                ela novamente agora?
            </span>
        </div>

        <div class="col-12 schedule-btn">
            <button class="btn btn-primary" style="width: 96.3%;" (click)="closeOnboard()">Continuar</button>
        </div>
    </div>

    <div class="row" *ngIf="!loading && onboardType && !isMobile">
        <div class="col-md-6">
            <img src="assets/img/new-attendance.png" *ngIf="onboardType === 'new-attendance' || onboardType === 'pos'" style="width: 292px; height: 292px;">
            <img src="assets/img/missed.png" *ngIf="onboardType === 'missed'" style="width: 292px; height: 292px;">
        </div>

        <div class="col-md-6" style="margin-top: 51px;">
            <div class="row">
                <div class="col-md-12">
                    <span style="font-weight: bold;font-size: 22px;line-height: 120%;color: #AFC1CF;" *ngIf="patientName">
                        Olá, {{ patientName }}!
                    </span>
                    <br>
                    <span style="font-weight: bold;font-size: 20px;line-height: 120%;color: #AFC1CF;" *ngIf="onboardType === 'new-attendance' || onboardType === 'pos'">
                        <br>
                        Que bom te ver aqui.
                    </span>
                    <span style="font-weight: bold;font-size: 20px;line-height: 120%;color: #AFC1CF;" *ngIf="onboardType === 'missed'">
                        Ops! Você faltou
                        <br>
                        sua última consulta.
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12" style="padding-top: 8px;">
                    <span *ngIf="onboardType === 'new-attendance' || onboardType === 'pos'" style="font-weight: normal;font-size: 14px;line-height: 150%;color: #676A6C;">
                        A seguir você vai agendar sua consulta
                        <br>
                        sem burocracia, fácil como
                        <br>
                        deve ser.
                    </span>
                    <span *ngIf="onboardType === 'missed'" style="font-weight: normal;font-size: 14px;line-height: 150%;color: #676A6C;">
                        Sem problemas, que tal agendar
                        <br>
                        ela novamente agora?
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12" style="margin-top: 16px;">
                    <button class="btn btn-primary" style="width: 96px; height: 36px; padding: 0 !important;" (click)="closeOnboard()">Continuar</button>
                </div>
            </div>
        </div>
    </div>
</div>
