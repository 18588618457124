import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { isBeforeDate, IsValidCPF, IsValidDate, isValidEmail, ValidateCPF } from 'src/app/shared/helpers';

import { DeviceService, AlertMessageService, PatientService} from '../../../shared/services';

@Component({
    selector: 'app-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
    RegisterForm: FormGroup;
    token = null;
    action = null;
    loading: boolean = false;
    isValidToken: boolean = true;
    hasSubmited: boolean = false;
    setting = {
        name: ''
    };
    patient = {
        name: '',
        toggle_active: true,
        cpf: '',
        contact_cellphone: '',
        email: '',
        born: '',
        gender: 'Masculino',
        cpf_responsible: ''
    };
    errors = {
        cpf_responsible: false,
        cpf: false
    };
    isWeb: boolean = true;
    cpfFlow = 'HIDE';

    constructor (
        private route: ActivatedRoute,
        private patientService: PatientService,
        private deviceService: DeviceService,
        private alertMessageService: AlertMessageService,
        private fb: FormBuilder,
        private router: Router
    ) {}

    cpfChange() {
        if (this.cpfFlow === 'HIDE') {
            this.errors.cpf_responsible = false;
            this.errors.cpf = !ValidateCPF(this.patient.cpf);
        } else {
            this.errors.cpf = false;
            this.errors.cpf_responsible = !ValidateCPF(this.patient.cpf_responsible);
        }
    };

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;

        this.isWeb = this.deviceService.isWeb();

        if (!this.token) {
            this.isValidToken = false;
            return;
        }

        this.getSettings();
        this.renderForm();
    }

    renderForm() {
        this.RegisterForm = this.fb.group({
            name: ['', [Validators.required]],
            born: ['', [Validators.required]],
            gender: ['', [Validators.required]],
            email: ['', [Validators.required]],
            cpf: ['', [Validators.required]],
            contact_cellphone: ['', [Validators.required]]
        }, {
            validators: [
                IsValidCPF('cpf'),
                IsValidDate('born'),
                isBeforeDate('born')
            ]
        });
    }
    getSettings() {
        const data = {
            token: this.token
        };

        this.loading = true;

        this.patientService.companyInfo(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.isValidToken = false;
                return;
            }

            this.setting = resp;
        });
    }

    isValid(data) {
        const nameSplited = data.name.split(' ');

        if (!data.name) {
            this.alertMessageService.open('O nome é um campo obrigatório', null, 'warning');
            return false;
        }

        if (!nameSplited || !nameSplited.length || nameSplited.length === 1) {
            this.alertMessageService.open('Preencha o nome e o seu sobrenome', null, 'warning');
            return false;
        }

        if ((!data.cpf || !ValidateCPF(data.cpf)) && (!data.cpf_responsible || !ValidateCPF(data.cpf_responsible))) {
            this.alertMessageService.open('O CPF está incorreto', null, 'warning');
            return false;
        }

        if (!data.born || !moment(data.born, 'DDMMYYYY').isValid() || moment(data.born, 'DDMMYYYY').isAfter(moment(), 'day')) {
            this.alertMessageService.open('A data de nascimento é um campo obrigatório', null, 'warning');
            return false;
        }

        if (!data.contact_cellphone || data.contact_cellphone.length !== 11) {
            this.alertMessageService.open('O telefone é um campo obrigatório e deve possuir 11 dígitos', null, 'warning');
            return false;
        }

        if (!data.email) {
            this.alertMessageService.open('O email é um campo obrigatório', null, 'warning');
            return false;
        }

        if (!data.gender) {
            this.alertMessageService.open('O gênero é um campo obrigatório', null, 'warning');
            return false;
        }

        if (!isValidEmail(data.email)) {
            this.alertMessageService.open('Digite um email válido!', null, 'warning');
            return false;
        }

        return true;
    }

    changeCpfFlow(type, inputToFocus) {
        this.cpfFlow = type;

        setTimeout(() => {
            document.getElementById(inputToFocus).focus();
        }, 50);
    }

    submit() {
        const data = Object.assign({
            token: this.token
        }, this.patient);

        if (!this.isValid(data)) {
            return;
        }

        if (data.cpf_responsible && this.cpfFlow === 'HIDE') {
            data.cpf_responsible = null;
        }

        if (data.cpf_responsible && this.cpfFlow === 'SHOW') {
            data.cpf = null;
        }

        this.loading = true;

        this.patientService.add(data, (error, resp) => {
            this.loading = false;

            if (error || !resp || !resp.token) {
                this.alertMessageService.open((error && error.message) || 'Algo de errado aconteceu e não foi possível validar seu cadastro', null, 'warning');
                return;
            }

            this.hasSubmited = true;

            const url = this.isWeb ? '/desktop/search' : '/mobile/card-info';

            this.router.navigate([url], {
                queryParams: {
                    token: resp.token
                }
            });

            if (resp.patientExists) {
                this.alertMessageService.open(`Cadastro validado! Agende seu atendimento.`, null, 'success');
            } else {
                this.alertMessageService.open('Cadastro realizado com sucesso! Agende seu atendimento.', null, 'success');
            }
        });
    }
}
