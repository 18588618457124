import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class ExternalService {
    deleteReason = (data, callback) => this.globalService.mountResource('POST', { url: '/external/delete-reason' }, data, callback);
    hasValidNpsToken = (data, callback) => this.globalService.mountResource('GET', { url: '/external/validate-nps-token' }, data, callback);
    npsRate = (data, callback) => this.globalService.mountResource('POST', { url: '/external/nps-rate' }, data, callback);
    unsubscribeEmail = (data, callback) => this.globalService.mountResource('POST', { url: '/external/unsubscribe-email' }, data, callback);
    validateUnsubscribeToken = (data, callback) => this.globalService.mountResource('GET', { url: '/external/validate-unsubscribe-token' }, data, callback);
    getOnboardType = (data, callback) => this.globalService.mountResource('GET', { url: '/external/onboard-type' }, data, callback);
    validateAgilizeAttendanceToken = (data, callback) => this.globalService.mountResource('GET', { url: '/external/validate-agilize-token' }, data, callback);
    agilizeAttendance = (data, callback) => this.globalService.mountResource('POST', { url: '/external/agilize-attendance' }, data, callback);
    getTokenInfo = (data, callback) => this.globalService.mountResource('GET', { url: '/external/token-info' }, data, callback);
    setOpenedLinkStatus = (data, callback) => this.globalService.mountResource('PUT', { url: '/external/set-opened-link-status' }, data, callback);

    constructor(
        protected globalService: GlobalService
    ) { }
}
