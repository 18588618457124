import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CompanyListComponent } from './company-list.component';
import { RouterModule } from '@angular/router';
import { AddressPipeModule } from 'src/app/shared/pipes';

@NgModule({
    declarations: [
        CompanyListComponent
    ],
    exports: [
        CompanyListComponent
    ],
    imports: [
        RouterModule,
        BrowserModule,

        AddressPipeModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CompanyListModule { }
