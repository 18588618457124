
import { Component, OnInit } from '@angular/core';
import { MainSearchService } from '../../services';
import { CheckoutService, SearchService } from '../../../shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchPageService } from 'src/app/desktop/services';

@Component({
    selector: 'app-doctor-list-page',
    templateUrl: './doctor-list-page.component.html'
})
export class DoctorListPageComponent implements OnInit {
    url;
    id;
    hasFilter;
    requestTabType;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public checkoutService: CheckoutService,
        public mainSearchService: MainSearchService,
        public searchService: SearchPageService
    ) {
        // this.searchService.hasFilteredItemsSubject.subscribe((hasFilter) => this.hasFilter = hasFilter);
    }

    ngOnInit() {
        this.url = this.router.url;
        this.requestTabType = this.route.snapshot.queryParams.requestTabType;

        if (!this.requestTabType) {
            this.requestTabType = 'doctor';
        }

        if (!this.url.includes('select-doctor')) {
            // this.searchService.list(null, (error, resp) => {
            //     if (error) {
            //         this.searchService.items = [];

            //         return;
            //     }
            // });
        }
    }

    goToDoctor(doctor) {
        const queryParams = Object.assign({
            is_doctor: true
        }, this.route.snapshot.queryParams || {});

        this.checkoutService.setDoctor(doctor);

        if (this.url.includes('select-doctor')) {
            this.router.navigate([`/mobile/card-info/${this.checkoutService.getCompany().id}`]);

            return;
        }

        this.router.navigate([`/mobile/card-info/${doctor.id}`], {
            queryParams: queryParams
        });
    }
}
