import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { CreditCardService, MobileCheckoutService } from '../../services';
import { CreditcardCardComponent, CreditcardOwnerComponent } from '../../components';

@Component({
    selector: 'app-creditcard-page',
    templateUrl: './creditcard-page.component.html',
    styleUrls: ['./creditcard-page.component.scss']
})
export class CreditcardPageComponent implements OnInit {
    url;
    @ViewChild(CreditcardCardComponent) creditcardCardComponent!: CreditcardCardComponent;
    @ViewChild(CreditcardOwnerComponent) creditcardOwner!: CreditcardOwnerComponent

    constructor(
        private route: Router,
        private location: Location,
        public creditcardService: CreditCardService,
        public checkoutService: MobileCheckoutService

    ) {}

    ngOnInit() {
        this.url = this.route.url;
        this.location.onUrlChange((url) => this.url = url);
    }

    nextStep() {
        this.route.navigate(['/mobile/select-payment/creditcard/owner'], { queryParamsHandling: 'preserve' });
    }

    backToResumePage() {
        if (!this.creditcardService.isValid) {
            return;
        }

        this.checkoutService.selectedPayment = true;
        this.checkoutService.payment = 'CREDITCARD';

        this.route.navigate(['/mobile/resume'], { queryParamsHandling: 'merge', queryParams: { paymentType: 'CREDITCARD' } });
    }
}
