import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { ModalDoctorCardSlots } from './modal-doctor-card-slots.component';
import { LoadingModule } from 'src/app/shared/components/loading/loading.module';
import { AddressPipeModule, PaymentAvailablePipeModule } from 'src/app/shared/pipes';

@NgModule({
    declarations: [
        ModalDoctorCardSlots
    ],
    exports: [
        ModalDoctorCardSlots,
    ],
    imports: [
        RouterModule,
        AngularSvgIconModule,
        BrowserModule,
        LoadingModule,
        BsDatepickerModule,
        AddressPipeModule,
        PaymentAvailablePipeModule
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalDoctorCardSlotsModule { }
