import { Component, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-history-back',
    templateUrl: './history-back.component.html',
    styleUrls: ['./history-back.component.scss']
})
export class HistoryBackComponent {
    @Output() backUrlEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private location: Location
    ) { }

    locationBack() {
        this.backUrlEvent.emit();
        this.location.back();
    }
}
