import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable()
export class CompanyService {
    get = (data) => this.globalService.mountResource('GET', { url: '/company' }, data);
    getEventPayments = (data) => this.globalService.mountResource('GET', { url: '/company/event-payments' }, data);
    getLogo = (data, callback) => this.globalService.mountResource('GET', { url: '/company/logo' }, data, callback);
    getSettings = (data, callback) => this.globalService.mountResource('GET', { url: '/company/settings' }, data, callback);
    getLeadFormSettings = (data, callback) => this.globalService.mountResource('GET', { url: '/company/lead-form-settings' }, data, callback);
    addLead = (data, callback) => this.globalService.mountResource('POST', { url: '/company/add-lead' }, data, callback);

    constructor(protected globalService: GlobalService) { }

    setCompanyLogo(data) {
        const companyLogo = JSON.stringify(data);

        localStorage.setItem('company_logo', companyLogo);
    }

    getCompanyLogo() {
        let companyLogo;

        try {
            companyLogo = JSON.parse(localStorage.getItem('company_logo'));
        } catch (error) {}

        return companyLogo;
    }
}
