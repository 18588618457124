import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceService, ExternalService } from '../../../shared/services';

@Component({
    selector: 'app-nps-page',
    templateUrl: './nps-page.component.html',
    styleUrls: ['./nps-page.component.scss']
})
export class NpsPageComponent implements OnInit {
    token = null;
    textAreaValue = null;
    formStep: boolean = true;
    loading: boolean = true;
    isValidToken: boolean = true;
    doctor = null
    rate = null;

    decryptedData = null;
    patient = null;

    constructor (
        private route: ActivatedRoute,
        private externalService: ExternalService,
        private deviceService: DeviceService
    ) {}

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;

        if (this.deviceService.isWeb()) {
            this.setBodyStyle();
        }

        if (!this.token) {
            return;
        }

        this.validateToken();
        this.externalService.setOpenedLinkStatus({ token: this.token }, () => {});
    }

    validateToken() {
        const data = {
            token: this.token
        };

        this.externalService.hasValidNpsToken(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.isValidToken = false;
                return;
            }

            this.isValidToken = true;
            this.decryptedData = resp;
            this.patient = resp.patient;
            this.doctor = resp.user;
        });
    }

    setBodyStyle() {
        document.querySelector('body').style.backgroundColor = '#E5E5E5';
    }

    submit() {
        const data = {
            nps_rate: this.rate,
            nps_observation: this.textAreaValue,
            token: this.token
        };

        if (!this.token || (!this.rate && isNaN(this.rate))) {
            return;
        }

        this.loading = true;

        this.externalService.npsRate(data, (error, resp) => {
            this.loading = false;
            this.formStep = false;

            if (error) {
                this.isValidToken = false;
                return;
            }

            this.isValidToken = true;
        });
    }
}
