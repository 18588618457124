import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CepService {
    currentCep;
    mobileCardFormCep = null

    constructor(
        private http: HttpClient
    ) {}

    getAddressByCep(cep) {
        this.currentCep = cep;

        return new Observable((resp) => {
            const request = new XMLHttpRequest();

            request.open('get', `https://viacep.com.br/ws/${cep}/json/`, true);

            request.send();

            request.onload = function () {
                resp.next(JSON.parse(this.response))
            }
        });
    }

    isValidCep(cep) {
        let validateCep;

        if (!cep) {
            return false;
        }

        validateCep = /^[0-9]{8}$/;

        if(validateCep.test(cep)) {
            return true;
        }

        return false;
    }

    parseStateName(state) {
        const states = {
            AC: 'Acre',
            AL: 'Alagoas',
            AP: 'Amapá',
            AM: 'Amazonas',
            BA: 'Bahia',
            CE: 'Ceará',
            DF: 'Distrito Federal',
            ES: 'Espírito Santo',
            GO: 'Goiás',
            MA: 'Maranhão',
            MT: 'Mato Grosso',
            MS: 'Mato Grosso do Sul',
            MG: 'Minas Gerais',
            PA: 'Pará',
            PB: 'Paraíba',
            PR: 'Paraná',
            PE: 'Pernambuco',
            PI: 'Piauí',
            RJ: 'Rio de Janeiro',
            RN: 'Rio Grande do Norte',
            RS: 'Rio Grande do Sul',
            RO: 'Rondônia',
            RR: 'Roraima',
            SC: 'Santa Catarina',
            SP: 'São Paulo',
            SE: 'Sergipe',
            TO: 'Tocantins',
        };

        return states[state];
    }
}