<div class="container">
    <main class="main">
        <div class="row share-title-border-bottom">
            <div class="col-md-12 col-12">
                <span class="share-title">
                    Compartilhar
                </span>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12 p-0 share-action share-action-border-bottom c-pointer" (click)="copyLink()">
                    <svg-icon src="assets/svg/copy.svg" class="ml-3"></svg-icon>
                    <span class="share-action-style">Copiar link</span>
                </div>
            </div>
            <div class="row share-action share-action-border-bottom">
                <div class="col-md-12 col-12 p-0 c-pointer" (click)="shareByWhatsapp()">
                    <svg-icon src="assets/svg/whatsapp.svg" class="ml-3"></svg-icon>
                    <span class="share-action-style">Whatsapp</span>
                </div>
            </div>
        </div>
    </main>
    <button class="btn close-btn btn-secondary" (click)="bsModalRef.hide()">Fechar</button>
</div>
