import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MobileDefaultHeaderComponent } from './default-header.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    declarations: [
        MobileDefaultHeaderComponent
    ],
    exports: [
        MobileDefaultHeaderComponent
    ],
    imports: [
        RouterModule,
        BrowserModule,
        AngularSvgIconModule,
        RouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MobileDefaultHeaderModule { }
