import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable()
export class SearchService {
    listEvents = (data, callback )  => this.globalService.mountResource('GET', { url: '/search/events' }, data, callback);
    doctorSlots = (data, callback) => this.globalService.mountResource('GET', { url: '/search/slots/doctor' }, data, callback);
    companySlots = (data, callback) => this.globalService.mountResource('GET', { url: '/search/slots/company' }, data, callback);
    listDoctors = (data, callback) => this.globalService.mountResource('GET', { url: '/search/company-list/doctors' }, data, callback);
    listPayments = (data, callback) => this.globalService.mountResource('GET', { url: '/search/list-payments' }, data, callback);
    getEventsProcedures = (data, callback) => this.globalService.mountResource('GET', { url: '/search/list-payments-and-prices' }, data, callback);
    mainList = (data, callback) => this.globalService.mountResource('GET', { url: '/search/main-list' }, data, callback);

    constructor(protected globalService: GlobalService) {
    }
}
