<app-default-header></app-default-header>
<app-loading [hidden]="!loading"></app-loading>
<main class="main" *ngIf="doctor" [hidden]="loading">
    <div class="row text-center doctor-image-margin-top">
        <div class="col-md-12 col-12">
            <img *ngIf="doctor.thumb_url" src="{{ doctor.thumb_url }}" class="doctor-image">
            <img *ngIf="!doctor.thumb_url" src="assets/img/user-profile.png" class="doctor-image">
            <svg-icon src="assets/svg/elipse.svg" class="icon-elipse"></svg-icon>
            <svg-icon src="assets/svg/verified-green.svg" class="icon-verified-green"></svg-icon>
        </div>
    </div>

    <div class="row text-center mt-3">
        <div class="col-md-12 col-12">
            <span class="message-all-done">{{ patient.name }}, tudo pronto!</span>
        </div>
    </div>

    <div class="row text-center mt-3">
        <div class="col-md-12 col-12 message-strong">
            {{ event.name }} para {{ patient.name }}
        </div>
    </div>

    <div class="row text-center">
        <div class="col-md-12 col-12 message-strong">
            {{ start_date | date: 'EEEE' | titlecase }}, {{ start_date | date: 'dd'}} de {{ start_date | date: 'MMMM' }}, às {{ start_date | date: 'HH:mm'}}
        </div>
    </div>

    <div class="row text-center mt-2">
        <div class="col-md-12 col-12">
            <div class="doctor">Profissional {{ doctor.name }}</div>
        </div>
    </div>

    <div class="row text-center mt-2">
        <div class="col-md-12 col-12" *ngIf="place.address || event.is_tele">
            <svg-icon src="assets/svg/location-gray.svg" class="mr-2" style="position: relative; top: -1px;"></svg-icon>
            <span *ngIf="place.address && !event.is_tele" style="text-decoration: underline;">{{ place.address | address }}</span>
            <span *ngIf="event.is_tele" style="font-weight: 400;">ATENDIMENTO ONLINE</span>
        </div>
    </div>

    <div class="row text-center">
        <div class="col-md-12 col-12 message-attendance-details">
            {{ company.name }}
        </div>
    </div>

    <div class="row text-center mt-5">
        <div class="col-md-12 col-12">
            <button class="btn btn-primary" (click)="openShareModal()">Compartilhar</button>
        </div>
    </div>
</main>