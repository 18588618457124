<div class="height-vh" [ngClass]="{  'mobile-font': !isWeb, 'container': isWeb, 'bg-size': isWeb, 'bg-contain': !isWeb, 'iframe': isIframe }" style="width: 100%; display: flex; justify-content: center; align-items: center; background-image: url({{setting.template && setting.template && setting.template.background && setting.template.background.url}}); background-repeat: no-repeat; background-color: #fff; padding: 16px;">
    <div [hidden]="!loading" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <app-loading></app-loading>
    </div>

	<div *ngIf="!loading && isValidToken && !hasSubmited" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <div class="m-t-4" style="text-align: center;" *ngIf="setting.template.show_logo && setting.logo_url">
			<img class="rounded-circle" src="{{ setting.logo_url }}" alt="" style="width: 120px;">
		</div>

		<div class="m-t-16 text-center">
			<span style="font-size: 28px; font-weight: 700;">{{ setting.template && setting.template.title }}</span>
		</div>

		<div class="m-t-16" [ngClass]="{  'mobile-font--big': !isWeb }" style="word-break: break-word; overflow-wrap: break-word;border-bottom: 1px solid #DFE6EC; padding-bottom: 16px; text-align: center; font-size: 16px;">{{ setting.template && setting.template.subtitle }}</div>

		<div class="m-t-16">
			<label [ngClass]="{  'mobile-font--big': !isWeb }" for="name">Nome</label>
			<input autocomplete="off" [(ngModel)]="lead.name" class="form-control" type="text" placeholder="Digite seu nome e sobrenome" id="name">
		</div>

		<div class="m-t-16">
            <label [ngClass]="{  'mobile-font--big': !isWeb }" for="phone">Telefone</label>
			<input autocomplete="off" [(ngModel)]="lead.phone" mask="(00) 00000-0000"  class="form-control" type="text" placeholder="(11) 11111-1111" id="phone">
		</div>

        <div class="m-t-16">
            <label [ngClass]="{  'mobile-font--big': !isWeb }" for="email">Email</label>
            <input autocomplete="off" [(ngModel)]="lead.email" class="form-control" type="text" placeholder="Digite o seu email" id="email">
        </div>

		<div class="m-t-20" [ngClass]="{  'mobile-font--small': !isWeb }" style="word-break: break-word; overflow-wrap: break-word;font-size: 10px; color: #AFC1CF; border-bottom: 1px solid #DFE6EC; padding-bottom: 16px;">
			{{ setting.template && setting.template.consentment_terms }}
		</div>

		<div class="m-t-16 d-flex" style="justify-content: flex-end;">
			<button class="ds-button ds-button--primary" *ngIf="isWeb" (click)="submit()">Enviar</button>
			<button class="ds-button btn-block ds-button--primary" style="height: 48px; font-size: 17px;" *ngIf="!isWeb" (click)="submit()">Enviar</button>
		</div>
	</div>

	<div *ngIf="!loading && !isValidToken" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <div class="row">
            <div class="col-md-12 col-12 text-center">
                <img src="assets/img/error-icon.png" alt="" style="width: 120px;">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                Erro!
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                Token inválido!
            </div>
        </div>
	</div>

	<div *ngIf="!loading && isValidToken && hasSubmited" style="max-width: 408px; width: 100%; padding: 25px; border-radius: 8px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); background-color: white;">
        <div class="row">
            <div class="col-md-12 col-12 text-center">
                <img src="assets/img/success-icon.png" style="width: 120px;">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                Sucesso!
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                Seu cadastro foi realizado com sucesso!
                <br>
                Em breve, entraremos em contato com você!
            </div>
        </div>
	</div>
</div>
