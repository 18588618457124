<main class="main">
    <div class="row">
        <div class="col-md-12 text-md-center">
            <img src="assets/img/calendar.png" class="calendar-img">
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12 text-md-center">
            <h4>Tudo certo!</h4>
        </div>
    </div>
    <div class="row mt-2 mb-2">
        <div class="offset-md-1 col-md-10 text">
            <span> &nbsp; Agendamento realizado com sucesso! Enviamos um email
                para você &nbsp; com o comprovante de pagamento e agendamento. Qualquer dúvida,
            </span>
            <div class="text-md-center">
                entre em contato com a clínica.
            </div>
        </div>
    </div>
</main>
