import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { CompanyCardComponent } from './company-card.component';

@NgModule({
    declarations: [
        CompanyCardComponent
    ],
    exports: [
        CompanyCardComponent
    ],
    imports: [
        BrowserModule,
        AngularSvgIconModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CompanyCardModule { }
