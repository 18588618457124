import { AuthService, CompanyService } from '../../../shared/services';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-default-header',
    templateUrl: './default-header.component.html',
    styleUrls: ['./default-header.component.scss']
})
export class DefaultHeaderComponent implements OnInit {
    @Input() options: any = {}

    private defaultOptions = {
        showLogin: true,
        isHomePage: false,
        showRegister: true
    };
    logo;
    auth;
    url;

    constructor(
        private authService: AuthService,
        private router: Router,
        private bsModalService: BsModalService,
        private companyService: CompanyService
    ) { }

    ngOnInit() {
        this.options = Object.assign(this.defaultOptions, this.options);
        this.logo = this.companyService.getCompanyLogo() ? this.companyService.getCompanyLogo().url : null;
        this.auth = this.authService.getAuth();
        this.url = this.router.url;
    }
}
