import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
    SearchPageComponent, ScheduledSuccessfullyPage,
    ResumeCheckoutPageComponent, AttendanceInfoPageComponent,
    NpsPageComponent, UnsubscribePageComponent, AgilizeAttendancePageComponent, ConfirmAttendancePageComponent, AttendanceStatusPageComponent,
    NotFoundPageComponent, LeadsPageComponent, RegisterPageComponent
} from './pages';

import { CheckoutGuardService } from '../shared/services';

const routes: Routes = [{
    path: 'desktop',
    canActivateChild: [CheckoutGuardService],
    children: [
        { path: 'search', component: SearchPageComponent },
        { path: 'scheduled-successfully', component: ScheduledSuccessfullyPage },
        { path: 'resume', component: ResumeCheckoutPageComponent },
        { path: 'attendance-info/:id', component: AttendanceInfoPageComponent },
        { path: 'nps-page', component: NpsPageComponent },
        { path: 'unsubscribe-page', component: UnsubscribePageComponent },
        { path: 'confirm-attendance', component: AttendanceStatusPageComponent },
        { path: 'agilize-attendance', component: AgilizeAttendancePageComponent },
        { path: 'attendance-status', component: ConfirmAttendancePageComponent },
        { path: 'leads', component: LeadsPageComponent },
        { path: 'register', component: RegisterPageComponent },
        { path: '**', redirectTo: '404' },
        { path: '404', component: NotFoundPageComponent }
    ]
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class DesktopRoutingModule { }
