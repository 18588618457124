import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services';

@Component({
    selector: 'app-configuration-page',
    templateUrl: './configuration-page.component.html',
    styleUrls: ['./configuration-page.component.scss'],
})
export class MobileConfigurationPageComponent implements OnInit {
    user;

    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    ngOnInit() {
        this.user = this.authService.getUser();
        const auth = this.authService.getAuth();

        if (!this.user || !auth) {
            this.router.navigate(['/']);
        }
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['/']);
    }
}
