<!-- <main *ngIf="url && url.includes('/mobile/select-payment') && !url.includes('/mobile/select-payment/creditcard') && !url.includes('/mobile/select-payment/insurance')"> -->
<main>
    <svg-icon src="assets/svg/close.svg" class="close-page-icon" (click)="navigateByUrl('/mobile/card-info')"></svg-icon>

    <div class="row payment-title-margin-top">
        <div class="col-12 pl-0 pb-4 payment-border-bottom">
            <span class="payment-title">Convênio ou Particular?</span>
        </div>
    </div>

    <div class="row">
        <div [ngClass]="{ 'border-active': paymentMethod === 'Particular' }" class="col-12 pt-4 pb-4 payment-border-bottom" (click)="changePaymentMethod('Particular')">
            <svg-icon src="assets/svg/money.svg" class="mr-2"></svg-icon>
            <span class="payment-type">Particular</span>
            <svg-icon src="assets/svg/arrow-right.svg" class="float-right"></svg-icon>
        </div>
    </div>

    <div class="row" *ngIf="itemInfo.insurance_groups.length">
        <div [ngClass]="{ 'border-active': paymentMethod === 'Convênio' }" class="col-12 pt-4 pb-4 payment-border-bottom" (click)="changePaymentMethod('Convênio');">
            <svg-icon src="assets/svg/insurance.svg" class="mr-2"></svg-icon>
            <span class="payment-type">Convênio</span>
            <svg-icon src="assets/svg/arrow-right.svg" class="float-right"></svg-icon>
        </div>
    </div>

    <div *ngIf="paymentMethod === 'Convênio'" class="d-flex flex-column" style="margin-top: 24px;">
        <div style="flex: 1;">
            <span class="mt-2 d-block place" style="font-weight: 300; font-size: 16px;">Convênio</span>

            <div class="dropdown" style="margin-top: 8px;">
                <button class="btn dropdown-select text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #f2f5f7; color: #676a6c; border: none; width: 100%;">
                    <span class="dropdown-text">{{ (selectedInsuranceGroup && selectedInsuranceGroup.name) || 'Selecione' }}</span>
                </button>

                <div class="dropdown-menu" style="width: 100%; padding: 10px;" aria-labelledby="dropdown">
                    <button class="dropdown-button dropdown-item" title="{{ group.name }}" style="margin-bottom: 5px; border-radius: 5px;" *ngFor="let group of itemInfo.insurance_groups" (click)="selectInsuranceGroup(group)" [ngClass]="{ 'selected': selectedInsuranceGroup && selectedInsuranceGroup.id === group.id }">
                        <span class="d-block text-truncate">
                            <span class="place place-name">{{ group.name }}</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>

        <div style="flex: 1; margin-top: 16px;">
            <span class="mt-2 d-block place" style="font-weight: 300; font-size: 16px;">Plano</span>

            <div class="dropdown" style="margin-top: 8px;">
                <button class="btn dropdown-select text-left" type="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: #f2f5f7; color: #676a6c; border: none; width: 100%;">
                    <span class="dropdown-text">{{ (selectedInsurancePlan && selectedInsurancePlan.name) || 'Selecione' }}</span>
                </button>

                <div class="dropdown-menu" style="width: 100%; padding: 10px;" aria-labelledby="dropdown">
                    <button class="dropdown-button dropdown-item" title="{{ plan.preview_name || plan.name }}" style="margin-bottom: 5px; border-radius: 5px;" *ngFor="let plan of insurancePlans" (click)="selectInsurancePlan(plan)" [ngClass]="{ 'selected': selectedInsurancePlan && selectedInsurancePlan.id === plan.id }">
                        <span class="d-block text-truncate">
                            <span class="place place-name">{{ plan.preview_name || plan.name }}</span>
                        </span>
                    </button>

                    <button class="dropdown-button dropdown-item" style="margin-bottom: 5px; border-radius: 5px;" *ngIf="!insurancePlans.length">
                        <span class="d-block text-truncate">
                            <span class="place place-name">Não há planos disponíveis para o filtro selecionado</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div style="margin-top: 32px;">
        <button (click)="submit()" class="btn btn-block btn-primary">Salvar</button>
    </div>
</main>
