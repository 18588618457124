import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutDefaultComponent } from '../shared/components/layout-default/layout-default.component';

// Pages
import {
    MobileResumeCheckoutPageComponent, MobileSelectPaymentPageComponent,
    MobileScheduledSucessfullyPageComponent, SelectAddressPageComponent, SelectEventPageComponent, CalendarPageComponent,
    SelectDoctorPageComponent, CardInfoPageComponent, CreditcardPageComponent
} from './pages';

import { CheckoutGuardService } from '../shared/services';

// Components
import { CreditcardCardComponent, CreditcardOwnerComponent, InsuranceComponent } from './components';
import { NotFoundPageComponent } from '../desktop/pages';

const routes: Routes = [{
    path: 'mobile',
    component: LayoutDefaultComponent,
    canActivateChild: [CheckoutGuardService],
    children: [
        { path: 'resume', component: MobileResumeCheckoutPageComponent },
        {
            path: 'select-payment', component: MobileSelectPaymentPageComponent,
            children: [
                { path: 'insurance', component: InsuranceComponent },
                {
                    path: 'creditcard', component: CreditcardPageComponent,
                        children: [
                            { path: 'card', component: CreditcardCardComponent },
                            { path: 'owner', component: CreditcardOwnerComponent }
                        ]
                }
            ]
        },
        // { path: 'whose', component: MobileWhoseIsTheAttendancePageComponent }, -- REMOVED
        { path: 'scheduled-successfully', component: MobileScheduledSucessfullyPageComponent },
        { path: 'card-info', component: CardInfoPageComponent },
        { path: 'select-address', component: SelectAddressPageComponent },
        { path: 'select-event', component: SelectEventPageComponent },
        { path: 'select-doctor', component: SelectDoctorPageComponent },
        { path: 'select-slot', component: CalendarPageComponent },
        { path: '**', redirectTo: '404' },
        { path: '404', component: NotFoundPageComponent }
    ]
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class MobileRoutingModule { }
