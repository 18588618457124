<app-loading [hidden]="!enabledDatesLoading"></app-loading>
<main [hidden]="enabledDatesLoading">
    <div>
        <div class="d-flex justify-content-center" style="width: 100%;">
            <bs-datepicker-inline class="datepicker" (bsValueChange)="onValueChange($event)"
            [bsValue]="selectedDate" bsDatepicker [minDate]="options.minDate" [(ngModel)]="dateValue" name="dateValue"
            ngDefaultControl [dateCustomClasses]="dateCustomClasses" [maxDate]="options.maxDate"
            [bsConfig]="bsConfig" [datesEnabled]="enabledDates" *ngIf="datepickerInitialized">
            </bs-datepicker-inline>
        </div>
    </div>

    <div class="col-12 slot" *ngIf="!doctor.config_arrival_order">
        <app-loading [hidden]="!loading" class="slot-loading"></app-loading>
        <div class="d-flex" [ngClass]="{ 'justify-content-center': slots.length >= 3 }" *ngIf="openSlots && !loading">
            <div class="d-flex row slot-group slot-scroll" style="max-width: 600px;" *ngIf="slots.length">
                <div class="col-4 mb-3" *ngFor="let slot of slots">
                    <button type="button" class="btn button-slot btn-outline-primary" (click)="setSlot(slot)" [ngClass]="{ 'selected': selectedSlot && slot.id === selectedSlot.id }">{{ slot.start_date | date: 'HH:mm' : '-03:00' }}</button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 slot" *ngIf="doctor.config_arrival_order">
        <app-loading [hidden]="!loading" class="slot-loading"></app-loading>
        <div class="d-flex justify-content-center" *ngIf="openSlots && !loading">
            <div class="d-flex row slot-group slot-scroll justify-content-left" style="max-width: 600px" *ngIf="slotsGroupedByShift.length">
                <div [ngClass]="{ 'col-12': slotsGroupedByShift.length === 1, 'col-6': slotsGroupedByShift.length === 2, 'col-4': slotsGroupedByShift.length === 3 }" *ngFor="let shift of slotsGroupedByShift">
                    <button type="button" class="btn button-slot btn-outline-primary" (click)="setShift(shift)" [ngClass]="{ 'selected': selectedSlot && shift.id === selectedSlot.id }">{{ shift.label }}</button>
                </div>
            </div>
        </div>
    </div>
</main>
