import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable()
export class DependentsService {
    add = (data, callback) => this.globalService.mountResource('POST', { url: '/dependents' }, data, callback);
    edit = (changes, callback) => this.globalService.mountResource('PUT', { url: '/dependents/:id' }, changes, callback);
    show = (data, callback) => this.globalService.mountResource('GET', {url: '/dependents/:id' }, data, callback);
    showAll = (data, callback) => this.globalService.mountResource('GET', { url: '/dependents' }, data, callback);
    destroy = (data, callback) => this.globalService.mountResource('DELETE', { url: '/dependents/:id' }, data, callback);

    constructor(protected globalService: GlobalService) { }
}
