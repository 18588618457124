import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SelectEventPageComponent } from './select-event-page.component';
import { AddressPipeModule } from 'src/app/shared/pipes';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        SelectEventPageComponent,
    ],
    exports: [
        SelectEventPageComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AngularSvgIconModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SelectEventPageModule { }
