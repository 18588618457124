import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ResumeCheckoutPageComponent } from './resume-checkout-page.component';
import { CardInfoModule, DefaultHeaderModule, HistoryBackModule } from '../../components';
import { RouterModule } from '@angular/router';
import { AddressPipeModule } from 'src/app/shared/pipes';
import { CardBrandModule } from 'src/app/desktop/components';
import { LoadingModule } from 'src/app/shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressPipe } from 'src/app/shared/pipes/address/address.pipe';
import { DoctorCardModule } from 'src/app/mobile/components';

@NgModule({
    declarations: [
        ResumeCheckoutPageComponent
    ],
    exports: [
        ResumeCheckoutPageComponent
    ],
    imports: [
        BrowserModule,
        AngularSvgIconModule,
        DefaultHeaderModule,
        RouterModule,
        AddressPipeModule,
        CardBrandModule,
        LoadingModule,
        HistoryBackModule,
        CardInfoModule,
        ReactiveFormsModule,
        DoctorCardModule,
        FormsModule
    ],
    providers: [
        AddressPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ResumeCheckoutPageModule { }
