import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AlertMessageService {
    constructor(private toastr: ToastrService) {
    }

    open(title = '', message = '', type = 'success', options?) {
        if (!options) {
            options = {
                class: 'toast-top-center'
            }
        }

        this.toastr[type](title, message, {
            positionClass: options.class
        });
    }
}
