import { Component, OnInit } from '@angular/core';
import { IsValidDate, isAfterDate } from '../../../shared/helpers';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { MobileCheckoutService, CreditCardService } from '../../services';
import { PagSeguroService, CepService, AlertMessageService, CheckoutService } from 'src/app/shared/services';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-creditcard-card',
    templateUrl: './creditcard-card.component.html',
    styleUrls: ['./creditcard-card.component.scss']
})
export class CreditcardCardComponent implements OnInit {
    cardInfoForm: FormGroup;
    expirationDate: Date;
    cvv_mask;
    cvv_placeholder = '000';
    lastCardNumber;
    hasSessionId: boolean = false;
    loading: boolean = false;

    constructor (
        private fb: FormBuilder,
        private pagSeguroService: PagSeguroService,
        private creditCardService: CreditCardService,
        private checkoutService: MobileCheckoutService,
        private cepService: CepService,
        private alertMessageService: AlertMessageService,
        private router: Router,
        private route: ActivatedRoute,
        private checkoutServiceDesktop: CheckoutService
    ) { }

    ngOnInit() {
        this.renderForm();

        if (!this.checkoutService.getPlace()) {
            this.navigateByUrl('/mobile/card-info');
            return
        }

        if (this.route.snapshot.queryParams.wasDesktop) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    wasDesktop: ''
                },
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });

            this.checkoutServiceDesktop.setSlot(null);
            this.navigateByUrl('/mobile/card-info');
            return;
        }

        if (this.checkoutService.getCreditCard().card) {
            this.hasSessionId = true;
            return;
        }

        this.getSession();
        this.getSenderHash();
    }

    setCreditcardData() {
        this.cardInfoForm.setValue(this.checkoutService.getCreditCard().card);
        this.creditCardService.isValid = true;
    }

    getSession() {
        const data = {
            company_id: this.checkoutService.getPlace().company_id,
            token: this.route.snapshot.queryParams.token
        };

        if (!data.token) {
            this.navigateByUrl('/mobile/resume');
            return;
        }

        this.loading = true;

        this.pagSeguroService.getSessionId(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.navigateByUrl('/mobile/select-payment');

                return;
            }

            this.pagSeguroService.setSessionId(resp);
            this.hasSessionId = true;
        });
    }

    navigateByUrl(url) {
        this.router.navigate([url], { queryParamsHandling: 'preserve' });
    }

    renderForm() {
        const creditcard = this.checkoutService.getCreditCard();

        this.cardInfoForm = this.fb.group({
            senderHash: ['', [Validators.required]],
            cardNumber: ['', [Validators.required]],
            expirationDate: ['', [Validators.required]],
            cvv: ['', [Validators.required]],
            cep: ['', [Validators.required]],
            brand: ['', [Validators.required]],
            cardToken: ['']
        }, {
            validators: [
                IsValidDate('expirationDate', { pattern: 'MMYY' }),
                isAfterDate('expirationDate', { pattern: 'MMYY' })
            ]
        });

        if (creditcard.card && Object.keys(creditcard.card).length) {
            this.cardInfoForm.setValue(creditcard.card);
        }

        this.cardInfoForm.valueChanges.subscribe((status) => {
            if ((this.cardInfoForm.value.cardNumber && this.cardInfoForm.value.cardNumber.length >= 14 && this.cardInfoForm.value.cardNumber <= 16) && !this.cardInfoForm.value.brand) {
                this.alertMessageService.open('O número do cartão está inválido!', null, 'warning');
            }

            if (this.creditCardService.isValid && !this.cardInfoForm.valid) {
                this.creditCardService.isValid = false;
            }

            if (this.cardInfoForm.valid && !this.cardInfoForm.value.cardToken && ((this.cardInfoForm.value.cardNumber && !this.lastCardNumber) || (this.lastCardNumber && this.cardInfoForm.value.cardNumber && this.cardInfoForm.value.cardNumber !== this.lastCardNumber))) {
                this.lastCardNumber = this.cardInfoForm.value.cardNumber;

                this.pagSeguroService.getCreditCardHash(this.cardInfoForm.value, (error, resp) => {
                    if (error) {
                        this.alertMessageService.open(error, null, 'warning');
                        this.creditCardService.isValid = false;

                        return;
                    }

                    this.cardInfoForm.controls.cardToken.setValue(resp);
                    this.creditCardService.isValid = this.cardInfoForm.valid;
                });
            }

            if (this.cardInfoForm.valid) {
                this.checkoutService.setCreditcardData(this.cardInfoForm.value);
            }

            if (!this.cepService.isValidCep(this.cardInfoForm.value.cep)) {
                return;
            }

            if (this.cepService.currentCep === this.cardInfoForm.value.cep) {
                return;
            }

            this.cepService.getAddressByCep(this.cardInfoForm.value.cep).subscribe((cep) => {
                if (cep['erro']) {
                    this.alertMessageService.open('O cep digitado não é válido!', null, 'warning');
                    this.creditCardService.isValid = false;

                    return;
                }

                this.cepService.mobileCardFormCep = cep;
                this.creditCardService.isValid = this.cardInfoForm.valid;
            });
        });
    }

    get f() {
        return this.cardInfoForm.controls;
    }

    getSenderHash() {
        this.pagSeguroService.getSenderHashReady((resp) => {
            if (!resp) {
                this.navigateByUrl('/mobile/select-payment');
                return;
            }

            this.cardInfoForm.controls.senderHash.setValue(resp);
        });
    }

    onCardNumberChange() {
        const number = this.f.cardNumber.value;
        const brandRegex = {
            visa: '^4[0-9]{12}(?:[0-9]{3})',
            mastercard: '^5[1-5][0-9]{14}',
            diners: '^3(?:0[0-5]|[68][0-9])[0-9]{11}',
            elo: '^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))',
            amex: '^3[47][0-9]{13}',
            jcb: '^(?:2131|1800|35[0-9]{3})[0-9]{11}',
            hipercard: '^(606282[0-9]{10}([0-9]{3})?)|(3841[0-9]{15})',
        };
        let cardBrand;

        Object.keys(brandRegex).some((brand) => {
            const regex = new RegExp(brandRegex[brand], 'gi');

            if (regex.test(number)) {
                cardBrand = brand;
                return true;
            }

            return false;
        });

        this.cardInfoForm.controls.brand.setValue(cardBrand);
        this.cvv_mask = cardBrand === 'amex' ? '0000' : '000';
        this.cvv_placeholder = cardBrand === 'amex' ? '0000' : '000';
    }
}
