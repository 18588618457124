import { Component, OnInit } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService, BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { ActivatedRoute } from '@angular/router';

import * as moment from 'moment-timezone';
import * as lodash from 'lodash';
import { CheckoutService, SlotService, AlertMessageService, CloseModalService } from '../../../shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-modal-doctor-card-slots',
    templateUrl: './modal-doctor-card-slots.component.html',
    styleUrls: ['./modal-doctor-card-slots.component.scss']
})
export class ModalDoctorCardSlots extends CloseModalService implements OnInit {
    openSlots: boolean = true;
    loading: boolean = false;
    datepickerInitialized = true;
    isInit: boolean = true;

    doctor;
    event;
    place;
    slots;
    selectedSlot;
    slot;
    selectedDate;
    procedurePayments;
    now = moment();
    enabledDates = [
        moment('2001-09-19'),
    ];
    dateCustomClasses: DatepickerDateCustomClasses[] = [];
    bsConfig: Partial<BsDatepickerConfig>;
    config = {
        isAnimated: true,
        containerClass: 'card-theme',
        customTodayClass: 'current-day',
        showWeekNumbers: false,
        selectFromOtherMonth: false
    };
    options = {
        locale: 'pt-br',
        minDate: this.now.clone().add(1, 'day').toDate(),
        maxDate: this.now.clone().add(3, 'months').toDate()
    };
    dateValue: Date = this.now.clone().add(1, 'day').toDate();
    listedSlots = {};

    constructor(
        private localeService: BsLocaleService,
        private checkoutService: CheckoutService,
        private slotService: SlotService,
        private route: ActivatedRoute,
        private alertMessageService: AlertMessageService,
        public bsModalRef: BsModalRef
    ) {
        super(bsModalRef);

        this.localeService.use(this.options.locale);
        this.bsConfig = Object.assign({}, this.config);

        ptBrLocale.weekdaysShort = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
        defineLocale('pt-br', ptBrLocale);
    }

    ngOnInit(): void {
        this.slot = this.checkoutService.getSlot();
        this.event = this.checkoutService.getEvent();
        this.place = this.checkoutService.getPlace();
        this.doctor = this.checkoutService.getDoctor();

        if (!this.event || !this.place || !this.doctor || !this.slot) {
            this.bsModalRef.hide();
            return;
        }

        this.selectedSlot = this.checkoutService.getSlot().id;
        this.selectedDate = moment(this.slot.start_date).toDate()
        this.getEnabledDates();
        this.getUserSlots(this.selectedDate);
    }

    getTelemedicineCalendar(data) {
        const filter = {
            ...data,
            is_scheduler: true,
            is_microservice: true
        };

        this.slotService.showAllTelemedicineSlots(filter, (error, resp) => {
            // this.enabledDatesLoading = false;
            this.datepickerInitialized = true;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.enabledDates = [];

                return;
            }

            const response = Object.keys(resp) || [];

            const dates = response.filter(date => resp[date] === 'AVAILABLE').map(date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'));

            this.setCustomDates(dates || []);
        })
    }

    getTelemedicineSlots(date, filterData) {
        if (!filterData) {
            return;
        }

        filterData.is_scheduler = true;
        filterData.is_microservice = true;

        this.loading = true;

        this.slotService.getTelemedicineSlots(filterData, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            if (!resp.length) {
                this.slots = [];

                return;
            }

            if (!this.listedSlots[this.place.id]) {
                this.listedSlots[this.place.id] = {};
            }

            if (!this.listedSlots[this.place.id][this.event.id]) {
                this.listedSlots[this.place.id][this.event.id] = {};
            }

            this.openSlots = true;
            this.slots = lodash.uniqBy(resp, (item) => item.id);

            if (!this.slots.length) {
                this.alertMessageService.open('Não há horários disponíveis no dia selecionado!', null, 'warning');
                this.openSlots = false;
            }

            this.listedSlots[this.place.id][this.event.id][moment(date).format('YYYY-MM-DD')] = this.slots;
        });
    }

    getEnabledDates() {
        const data = {
            month_key: moment().format('YYYY-MM'),
            event_id: this.event.id,
            company_id: this.place.company_id,
            user_id: this.doctor.id,
            place_id: this.place.id,
            token: this.route.snapshot.queryParams.token
        };

        if ( this.place && this.place.is_tele) {
            data.event_id = this.place.id;
            data.place_id = this.place.place.id;
            this.getTelemedicineCalendar(data);
            return;
        }

        this.loading = true;

        this.slotService.calendar(data, (error, resp) => {
            this.loading = false;
            this.datepickerInitialized = true;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                this.enabledDates = [];

                return;
            }

            this.setCustomDates(resp);
        });
    }

    setCustomDates(dates) {
        this.dateCustomClasses = [];
        this.enabledDates = [];

        dates.forEach((date) => {
            const now = moment();
            const dateObject = {
                date: moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).toDate(),
                classes: ['enabled-date']
            };

            if (moment(date, 'DD/MM/YYYY').isBefore(now)) {
                return;
            }

            this.enabledDates.push(moment(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')));
            this.dateCustomClasses.push(dateObject);
        });

        if (!this.enabledDates.length) {
            this.enabledDates.push(moment('2001-09-22'));
        }
    }

    previousCustomDates(dates) {
        this.dateCustomClasses = [];
        this.enabledDates = [];

        dates.forEach((date) => {
            const dateObject = {
                date: moment(date).toDate(),
                classes: ['enabled-date']
            };

            this.enabledDates.push(moment(date));
            this.dateCustomClasses.push(dateObject);
        });

        if (!this.enabledDates.length) {
            this.enabledDates.push(moment('2001-09-22'));
        }
    }

    onValueChange(value: Date) {
        if (this.dateValue === value) {
            return;
        }

        this.dateValue = value;
        this.getUserSlots(this.dateValue);
    }

    getFilter(date) {
        const filter = {
            company_id: this.place.company_id,
            user_id: this.doctor.id,
            place_id: this.place.id,
            event_id: this.event.id,
            start_date: moment(date).format('YYYY-MM-DD'),
            token: this.route.snapshot.queryParams.token
        };

        if (this.place && this.place.is_tele) {
            filter.event_id = this.place.id;
            filter.place_id = this.event.id;
        }

        if (!this.place || !this.event) {
            return;
        }

        return filter;
    }

    showInexistentEventMessage() {
        this.alertMessageService.open('Selecione uma unidade antes do tipo de atendimento', null, 'warning');
        return;
    }

    getUserSlots(date) {
        if (this.isInit) {
            this.openSlots = true;
        }

        if (this.listedSlots[moment(date).format('YYYY-MM-DD')]) {
            this.slots = this.listedSlots[moment(date).format('YYYY-MM-DD')];
            return;
        }

        if ((this.place && this.place.is_tele) || (this.event && this.event.is_tele)) {
            this.getTelemedicineSlots(date, this.getFilter(date));
            return;
        }

        if (this.isInit) {
            this.datepickerInitialized = false;
        }

        this.loading = true;

        this.slotService.showAll(this.getFilter(date), (error, doctorSlots) => {
            this.datepickerInitialized = true;
            this.loading = false;

            if (error) {
                return;
            }

            if (!doctorSlots.length) {
                this.slots = [];

                return;
            }

            this.isInit = false;

            this.slots = lodash.uniqBy(doctorSlots, (item) => item.id) || [];
            this.listedSlots[moment(date).format('YYYY-MM-DD')] = this.slots;
        });
    }

    schedule(slot) {
        const userId = this.doctor.id;
        let newSlot;

        slot.user_id = userId;

        if (!slot || !this.event || !this.place || !this.doctor) {
            return;
        }

        if (this.doctor && !this.doctor.paymentData) {
            this.doctor.paymentData = {};
        }

        slot.procedurePayments = this.doctor.paymentData.procedurePayments;

        newSlot = Object.assign(this.checkoutService.getSlot(), slot);

        this.checkoutService.setSlot(newSlot);

        this.hideModal(true);
    }

    confirmSchedule(id) {
        if (this.selectedSlot && this.selectedSlot === id) {
            this.selectedSlot = null;

            return;
        }

        this.selectedSlot = id;
    }
}
