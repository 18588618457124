import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    constructor() {
    }

    getInsurances() {
        const insurances = [{
            id: 'ABET',
            name: 'ABET'
        }, {
            id: 'Agemed',
            name: 'Agemed'
        }, {
            id: 'Allianz',
            name: 'Allianz'
        }, {
            id: 'Alvorecer',
            name: 'Alvorecer'
        }, {
            id: 'AME',
            name: 'AME'
        }, {
            id: 'AMENO',
            name: 'AMENO'
        }, {
            id: 'AMEPLAN',
            name: 'AMEPLAN'
        }, {
            id: 'Amil',
            name: 'Amil'
        }, {
            id: 'AMS Petrobrás',
            name: 'AMS Petrobrás'
        }, {
            id: 'ASSEFAZ',
            name: 'ASSEFAZ'
        }, {
            id: 'Associação Assistencial de Saúde Suplementar Cruz Azul Saúde',
            name: 'Associação Assistencial de Saúde Suplementar Cruz Azul Saúde'
        }, {
            id: 'Associação Policial de Assistência à Saúde - Apas Sorocaba/Votorantim',
            name: 'Associação Policial de Assistência à Saúde - Apas Sorocaba/Votorantim'
        }, {
            id: 'BACEN',
            name: 'BACEN'
        }, {
            id: 'Bio Saúde Serviços Médicos',
            name: 'Bio Saúde Serviços Médicos'
        }, {
            id: 'Biovida Saúde',
            name: 'Biovida Saúde'
        }, {
            id: 'Bradesco Saúde',
            name: 'Bradesco Saúde'
        }, {
            id: 'CABESP',
            name: 'CABESP'
        }, {
            id: 'Camed',
            name: 'Camed'
        }, {
            id: 'Care Plus',
            name: 'Care Plus'
        }, {
            id: 'Cartão de todos ',
            name: 'Cartão de todos '
        }, {
            id: 'Cassi',
            name: 'Cassi'
        }, {
            id: 'CB Saúde',
            name: 'CB Saúde'
        }, {
            id: 'CEMIG',
            name: 'CEMIG'
        }, {
            id: 'COOPUS',
            name: 'COOPUS'
        }, {
            id: 'Dix Saúde',
            name: 'Dix Saúde'
        }, {
            id: 'ECONOMUS',
            name: 'ECONOMUS'
        }, {
            id: 'FUNCESP',
            name: 'FUNCESP'
        }, {
            id: 'Fundação SABESP de Seguridade Social - SABESPrev',
            name: 'Fundação SABESP de Seguridade Social - SABESPrev'
        }, {
            id: 'GAMA Saúde',
            name: 'GAMA Saúde'
        }, {
            id: 'Garantia de Saúde',
            name: 'Garantia de Saúde'
        }, {
            id: 'GEAP',
            name: 'GEAP'
        }, {
            id: 'Golden Cross',
            name: 'Golden Cross'
        }, {
            id: 'HAPVIDA',
            name: 'HAPVIDA'
        }, {
            id: 'HBC Saúde',
            name: 'HBC Saúde'
        }, {
            id: 'Life Empresarial',
            name: 'Life Empresarial'
        }, {
            id: 'Life Saúde',
            name: 'Life Saúde'
        }, {
            id: 'Lincx',
            name: 'Lincx'
        }, {
            id: 'Mapfre Saúde',
            name: 'Mapfre Saúde'
        }, {
            id: 'Med-Tour',
            name: 'Med-Tour'
        }, {
            id: 'Medial Saúde',
            name: 'Medial Saúde'
        }, {
            id: 'Medisanitas',
            name: 'Medisanitas'
        }, {
            id: 'Mediservice',
            name: 'Mediservice'
        }, {
            id: 'Metrus Instituto de Seguridade Social',
            name: 'Metrus Instituto de Seguridade Social'
        }, {
            id: 'Nipomed',
            name: 'Nipomed'
        }, {
            id: 'NotreDame Intermédica',
            name: 'NotreDame Intermédica'
        }, {
            id: 'Omint',
            name: 'Omint'
        }, {
            id: 'PAME',
            name: 'PAME'
        }, {
            id: 'Panamed Saúde',
            name: 'Panamed Saúde'
        }, {
            id: 'PASBC',
            name: 'PASBC'
        }, {
            id: 'PASBC - Banco Central',
            name: 'PASBC - Banco Central'
        }, {
            id: 'Plan-Assiste',
            name: 'Plan-Assiste'
        }, {
            id: 'Plena Saúde',
            name: 'Plena Saúde'
        }, {
            id: 'Porto Seguro',
            name: 'Porto Seguro'
        }, {
            id: 'PORTOMED',
            name: 'PORTOMED'
        }, {
            id: 'Postal Saúde',
            name: 'Postal Saúde'
        }, {
            id: 'Prevent Senior',
            name: 'Prevent Senior'
        }, {
            id: 'PROASA',
            name: 'PROASA'
        }, {
            id: 'São Cristovão',
            name: 'São Cristovão'
        }, {
            id: 'Saúde Caixa',
            name: 'Saúde Caixa'
        }, {
            id: 'Saúde Itaú',
            name: 'Saúde Itaú'
        }, {
            id: 'SEISA',
            name: 'SEISA'
        }, {
            id: 'SEPACO',
            name: 'SEPACO'
        }, {
            id: 'Sinam',
            name: 'Sinam'
        }, {
            id: 'Sistemas e Planos de Saúde',
            name: 'Sistemas e Planos de Saúde'
        }, {
            id: 'SOMPO',
            name: 'SOMPO'
        }, {
            id: 'Sul América Saúde',
            name: 'Sul América Saúde'
        }, {
            id: 'Telos',
            name: 'Telos'
        }, {
            id: 'Unafisco',
            name: 'Unafisco'
        }, {
            id: 'Unibanco Saúde',
            name: 'Unibanco Saúde'
        }, {
            id: 'Unihosp',
            name: 'Unihosp'
        }, {
            id: 'Unihosp Saúde',
            name: 'Unihosp Saúde'
        }, {
            id: 'Unimed',
            name: 'Unimed'
        }, {
            id: 'Usisaude',
            name: 'Usisaude'
        }];

        return insurances;
    }

    arrayToObject(arr, key) {
        const collection = {};

        arr.forEach((data) => {
            const val = data[key];

            if (!val) {
                return;
            }

            collection[val] = data;
        });

        return collection;
    }
}
