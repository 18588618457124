import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CardInfoComponent } from '../../components';

import { AlertMessageService, CheckoutService, AuthService, CreditcardService, AttendanceService, SearchService, PagSeguroService, ResumeCheckoutService, PatientService, CompanyService, ExternalService } from '../../../shared/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalDoctorCardSlots } from '../../modals';

@Component({
    selector: 'app-resume-checkout-page',
    templateUrl: './resume-checkout-page.component.html',
    styleUrls: ['./resume-checkout-page.component.scss']
})
export class ResumeCheckoutPageComponent implements OnInit {
    @ViewChild(CardInfoComponent) cardInfoComponent!: CardInfoComponent;

    inputValue = 'particular';
    formatedInputValue = null;
    settings = null;
    token = null;
    patient = null;
    doctor = null;
    place = null;
    slot = null;
    event = null;
    paymentMethod = null;
    insuranceInfo = null;
    company_logo = null;
    tokenInfo = null;
    paymentLoading: boolean = false;
    loading: boolean = false;

    constructor(
        private alertMessageService: AlertMessageService,
        private checkoutService: CheckoutService,
        public creditcardService: CreditcardService,
        private router: Router,
        private route: ActivatedRoute,
        private attendanceService: AttendanceService,
        private searchService: SearchService,
        private bsModalService: BsModalService,
        private resumeCheckoutService: ResumeCheckoutService,
        private patientService: PatientService,
        private companyService: CompanyService,
        private externalService: ExternalService
    ) { }

    ngOnInit() {
        this.setDefaultStyle();
        this.token = this.route.snapshot.queryParams.token;
        this.doctor = this.checkoutService.getDoctor();
        this.place = this.checkoutService.getPlace();
        this.paymentMethod = this.checkoutService.getPayment();
        this.insuranceInfo = this.checkoutService.getInsurancePlan();
        this.event = this.checkoutService.getEvent();
        this.slot = this.checkoutService.getSlot();
        this.company_logo = this.companyService.getCompanyLogo();

        if (!this.doctor) {
            this.backToCard();
            return;
        }

        this.doctor.keys = [];
        this.doctor.payment_methods = [];

        if (!this.token) {
            this.alertMessageService.open('Token invalido ou expirado.');
            return;
        }

        if (!this.doctor || !this.event || !this.place || !this.slot) {
            this.alertMessageService.open('É necessário selecionar o paciente, profissional, unidade e o horário do agendamento.', null, 'warning');
            this.backToCard();
            return;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                wasDesktop: true
            },
            queryParamsHandling: 'merge',
        });

        this.getTokenInfo();
        this.getSettings();
        this.getPatientInfo();
        // this.getPayments()
    }

    setDefaultStyle() {
        document.body.style.backgroundColor = 'white';
    }

    getTokenInfo() {
        const data = {
            token: this.token
        };

        this.loading = true;

        return this.externalService.getTokenInfo(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'warning');
                return
            }

            this.tokenInfo = resp;
        });
    }

    getSettings() {
        const data = {
            token: this.token
        };

        this.loading = true;

        return this.companyService.getSettings(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'warning');
                return;
            }

            this.settings = resp;
        });
    }

    togglePayment(payment) {
        this.formatedInputValue = payment;
    }

    getPatientInfo() {
        const data = {
            token: this.token
        };

        this.loading = true;

        this.patientService.patientInfo(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'danger');
                return;
            }

            if (!resp) {
                this.alertMessageService.open('Ops.. Algo de errado aconteceu', null, 'danger');
                this.backToCard();
                return;
            }

            this.patient = resp;
            this.checkoutService.setPatient(this.patient);
        });
    }

    backToCard() {
        this.router.navigate(['/desktop/search'], {
            queryParams: {
                hasPopup: true
            },
            queryParamsHandling: 'merge'
        });
    }

    getPayments() {
        const data = {
            company_id: this.place.company_id,
            event_id: this.event.id
        };

        this.paymentLoading = true;

        return this.searchService.listPayments(data, (error, resp) => {
            this.paymentLoading = false;

            if (error) {
                this.doctor.payment_methods = [];
                this.doctor.keys = [];
                this.checkoutService.setDoctor(this.doctor);

                return;
            }

            this.doctor.keys = resp.keys;
            this.doctor.payment_methods = resp.payment_methods;
            this.doctor.insurances_by_keys = resp.insurances_by_keys;
            this.checkoutService.setDoctor(this.doctor);
        });
    }

    openEditCardSlots() {
        const modal = this.bsModalService.show(ModalDoctorCardSlots, {
            class: 'modal-edit-slot'
        });

        modal.content.onClose.subscribe((resp) => {
            if (!resp) {
                return;
            }

            this.slot = this.checkoutService.getSlot();
        });
    }

    submit() {
        const data = {
            token: this.token,
            attendance: {
                patient: this.patient,
                place: this.place,
                event: this.event,
                company_id: this.place.company_id,
                user_id: this.doctor.id,
                slot: this.slot,
                price: this.slot.price || 0,
                description: this.event.name,
                insurance_id: null
            },
            payment: {
                payment_method: null
            }
        };

        if (this.paymentMethod === 'Convênio' && this.insuranceInfo) {
            data.payment.payment_method = 'INSURANCE';
            data.attendance.insurance_id = this.insuranceInfo.id;
        }

        if (this.paymentMethod !== 'Convênio' || !this.insuranceInfo || !this.insuranceInfo.id || (this.tokenInfo && ['missed', 'canceled'].includes(this.tokenInfo.type))) {
            data.payment.payment_method = 'PARTICULAR';
            data.payment['missed'] = (this.tokenInfo && ['missed', 'canceled'].includes(this.tokenInfo.type)) ? true : false;
        }

        this.loading = true;

        this.attendanceService.addAttendance(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.resumeCheckoutService.attendanceId = resp.attendance_id;
            this.resumeCheckoutService.setNewAttendanceId(resp.attendance_id);
            this.router.navigate(['/desktop/scheduled-successfully/'], { queryParamsHandling: 'preserve' });
        });
    }
}
