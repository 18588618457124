import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CardBrandComponent } from './card-brand.component';

@NgModule({
    declarations: [
        CardBrandComponent
    ],
    exports: [
        CardBrandComponent
    ],
    imports: [
        BrowserModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CardBrandModule { }
