import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { LayoutDefaultComponent } from './layout-default.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        LayoutDefaultComponent
    ],
    exports: [
        LayoutDefaultComponent
    ],
    imports: [
        BrowserModule,
        RouterModule
    ]
})
export class LayoutDefaultModule { }
