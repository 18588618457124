import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { CheckoutService } from './checkout.service';
import { ResumeCheckoutService } from './resume-checkout.service';

@Injectable({
    providedIn: 'root'
})
export class CheckoutGuardService implements CanActivateChild {
    constructor (
        private checkoutService: CheckoutService,
        private resumeCheckoutService: ResumeCheckoutService,
        private router: Router
    ) { }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url = state.url;
        const desktopAllowedUrls = ['/desktop/resume', '/desktop/whose', '/desktop/scheduled-successfully'];
        const mobileAllowedsUrls = [
            '/mobile/select-payment', '/mobile/select-payment/insurance', '/mobile/select-payment/creditcard', '/mobile/select-payment/creditcard/card',
            '/mobile/select-payment/creditcard/owner', '/mobile/card-info', '/mobile/select-address', '/mobile/select-doctor',
            '/mobile/select-event', '/mobile/select-slot', '/mobile/resume', '/mobile/scheduled-successfully'
        ];

        if (url.includes('mobile') && !mobileAllowedsUrls.some((allowedUrl) => url.includes(allowedUrl))) {
            this.checkoutService.clearAllCheckoutService();
            this.resumeCheckoutService.setNewAttendanceId(null);
        }

        if (url.includes('desktop') && !desktopAllowedUrls.some((allowedUrl) => url.includes(allowedUrl))) {
            this.checkoutService.clearAllCheckoutService();
            this.resumeCheckoutService.setNewAttendanceId(null);
        }

        if (route.queryParams.skdld && !(~~route.queryParams.skdld)) {
            const clonedQueryParams = Object.assign({}, route.queryParams);

            this.router.navigate(['/desktop/search'], {
                queryParams: Object.assign(clonedQueryParams, { skdld: '' })
            });

            this.checkoutService.clearAllCheckoutService();
            this.resumeCheckoutService.setNewAttendanceId(null);
        }

        return true;
    }
}
