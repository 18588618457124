import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
    transform(phone: any) {
        let phoneNumber = phone;

        if (!phoneNumber) {
            return '';
        }

        phoneNumber = phoneNumber.replace(/\D/g, '');
        phoneNumber = phoneNumber.replace(/^(\d{2})(\d)/g, '($1) $2');
        phoneNumber = phoneNumber.replace(/(\d)(\d{4})$/, '$1-$2');

        return phoneNumber;
    }
}
