import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable()
export class AttendanceService {
    getAttendanceByToken = (data, callback) => this.globalService.mountResource('GET', { url: '/attendance/info-by-token' }, data, callback);
    listAll = (data, callback) => this.globalService.mountResource('GET', { url: '/attendance' }, data, callback);
    list = (data, callback) => this.globalService.mountResource('GET', { url: '/attendance/list' }, data, callback);
    addAttendance = (data, callback) => this.globalService.mountResource('POST', { url: '/attendance' }, data, callback);
    attendanceStatus = (data, callback) => this.globalService.mountResource('PUT', { url: '/attendance/status' }, data, callback);
    confirmAttendance = (data, callback) => this.globalService.mountResource('PUT', { url: '/attendance/:id' }, data, callback);

    constructor(protected globalService: GlobalService) { }
}
