import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal/';

import { CheckoutService, SlotService } from '../../../shared/services'

defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'app-modal-doctor-slots',
  templateUrl: './modal-doctor-slots.component.html',
  styleUrls: ['./modal-doctor-slots.component.scss']
})
export class ModalDoctorSlotsComponent implements OnInit {
    params;
    title;
    button;
    loading;
    slots;
    selectedSlot;
    currentSelectedSlot;
    doctor;
    now = moment();
    bsConfig: Partial<BsDatepickerConfig>;
    config = {
        isAnimated: true,
        containerClass: 'theme-default'
    };
    options = {
        locale: 'pt-br',
        minDate: this.now.clone().add(1, 'day').toDate(),
        maxDate: this.now.clone().add(3, 'months').toDate()
    };
    dateValue: Date = this.now.clone().add(1, 'day').toDate();

    constructor(
        private checkoutService: CheckoutService,
        private slotService: SlotService,
        private location: Location,
        private route: ActivatedRoute,
        private localeService: BsLocaleService,
        public bsModalRef: BsModalRef,
    ) {
        this.localeService.use(this.options.locale);
        this.bsConfig = Object.assign({}, this.config);
    }

    ngOnInit(): void {
        this.getDoctorAndSelectedSlot();
        this.getUserSlots(this.dateValue);
    }

    getDoctorAndSelectedSlot() {
        if (this.checkoutService.getSlot() && this.checkoutService.getDoctor()) {
            this.selectedSlot = this.checkoutService.getSlot();
            this.doctor = this.checkoutService.getDoctor();
            this.dateValue = this.selectedSlot.start_date;
        }
    }

    setSlot(slot) {
        if (slot.id === this.selectedSlot.id) {
            return;
        }

        this.checkoutService.setSlot(slot);
        this.currentSelectedSlot = this.checkoutService.getSlot()
    }

    onValueChange(value: Date) {
        this.dateValue = value;
        this.getUserSlots(value);
    }

    nextDay() {
        const dateValue = moment(this.dateValue);
        const maxDate = moment(this.options.maxDate).subtract(1, 'day');

        if (dateValue >= maxDate) {
            return;
        }

        this.dateValue = moment(this.dateValue).add(1, 'day').toDate();
        this.getUserSlots(this.dateValue);
    }

    previousDay() {
        if (this.dateValue <= this.options.minDate) {
            return;
        }

        this.dateValue = moment(this.dateValue).subtract(1, 'day').toDate();
        this.getUserSlots(this.dateValue);
    }

    getFilter(date) {
        const filter = {
            company_url: this.params['companyUrl'],
            user_id: this.doctor.id,
            place_id: this.checkoutService.getPlace().id,
            agenda_event_id: this.checkoutService.getEvent().id,
            start_date: moment(date).format('YYYY-MM-DD'),
            token: this.route.snapshot.queryParams.token
        };

        return filter;
    }

    getUserSlots(date) {
        const filter = this.getFilter(date);

        this.loading = true;

        this.slotService.showAll(filter, (error, doctorSlots) => {
            if (error) {
                this.location.back();
            }

            if (!doctorSlots.length) {
                this.slots = [];
                this.loading = false;

                return;
            }

            this.slots = (doctorSlots || [])
            this.loading = false;
        })
    }
}
