import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], filter = { key: '', value: '', limit_character: null }): any {
        let regex;
        let itemsFiltered;

        if (filter.limit_character && filter.value.length < filter.limit_character) {
            return items;
        }

        if (!items || !filter) {
            return items;
        }

        regex = new RegExp(filter.value, 'gi');

        itemsFiltered = items.filter((item) => regex.test(item[filter.key].normalize("NFD").replace(/[\u0300-\u036f]/g, '')));

        return itemsFiltered;
    }
}
