<header>
    <div class="container pt-3">
        <div class="d-flex justify-content-between align-items-center">
            <div class="col-3 pl-0">
                <a (click)="locationBack()">
                    <svg-icon src="assets/svg/mobile-arrow-left.svg"></svg-icon>
                </a>
            </div>
        </div>
    </div>
</header>
