import { Injectable } from '@angular/core';
import { GlobalService } from './global.service'
import * as moment from 'moment-timezone';

@Injectable()
export class AuthService {
    constructor(protected globalService: GlobalService) { }

    login = (data, callback) => this.globalService.mountResource('POST', { url: '/auth/login' }, data, callback);
    forgotPassword = (data, callback) => this.globalService.mountResource('POST', { url: '/auth/forgot-password' }, data, callback);
    tokenValidate = (data, callback) => this.globalService.mountResource('GET', { url: '/auth/forgot-password/validate' }, data, callback);
    redefine = (data, callback) => this.globalService.mountResource('POST', { url: '/auth/forgot-password/redefine' }, data, callback);

    setUserData = (data) => {
        localStorage.setItem('auth_user', JSON.stringify(data.user));
        localStorage.setItem('auth_token', data.token);
        localStorage.setItem('auth_logged_at', moment().toISOString());
    }

    setUserChangedData = (data) => {
        localStorage.removeItem('auth_user');
        localStorage.setItem('auth_user', JSON.stringify(data));
    }

    clearLocalStorage() {
        localStorage.removeItem('auth_user');
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_logged_at');
    }

    logout = () => {
        this.clearLocalStorage();
    }

    getUser = () => {
        let user;

        try {
            user = JSON.parse(localStorage.getItem('auth_user'));
        } catch (err) {
        }

        return user;
    }

    getToken = () => {
        let token;

        try {
            token = localStorage.getItem('auth_token');
        } catch (err) {
        }

        return token;
    }

    getLoggedAt() {
        let loggedAt;

        try {
            loggedAt = localStorage.getItem('auth_logged_at');
        } catch (err) {
        }

        return loggedAt;
    }

    isLogged(): boolean {
        return (this.getUser() && this.getToken());
    }

    getAuth() {
        if (!this.isLogged()) {
            return null;
        }

        return {
            user: this.getUser(),
            token: this.getToken()
        };
    }
}
