import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalMobileCalendarComponent } from './modal-calendar.component';
import { LoadingModule } from '../../../shared/components/loading/loading.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    declarations: [
        ModalMobileCalendarComponent
    ],
    exports: [
        ModalMobileCalendarComponent
    ],
    imports: [
        RouterModule,
        BrowserModule,
        LoadingModule,
        BsDatepickerModule,
        AngularSvgIconModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalMobileCalendarModule { }
