<app-default-header></app-default-header>

<main class="mt-3">
    <div class="container mt-0">
        <div class="row">
            <div class="col-md-6">
                <app-history-back [hidden]="loading"></app-history-back>
            </div>
        </div>
        <div class="row company-profile enter-left-bounce" *ngIf="loading">
            <div class="col-md-12 text-center">
                <app-loading></app-loading>
            </div>
        </div>

        <div class="row company-profile mt-3" *ngIf="!loading && companyInfo">
            <div class="col-md-8">

                <div class="row company-header">
                    <div class="col-md-1">
                        <img src="https://via.placeholder.com/72x72" class="company-logo">
                    </div>

                    <div class="col-md-10 my-auto">
                        <h1 class="company-name align-middle"> {{ companyInfo.company.name }}</h1>
                        <h5 class="company-description align-middle">{{ companyInfo.company.description || '' }}</h5>
                    </div>
                </div>

                <div class="row maps">
                    <div class="col-md-12">
                        <img src="https://via.placeholder.com/665x160">
                    </div>
                </div>

                <div class="row company-info mt-4">
                    <div class="col-md-12">
                        <p class="company-info-title mb-0">Endereços/Unidades</p>

                        <div class="address" *ngFor="let place of companyInfo.company.places">
                            <h3 class="address-name">{{ place.name }}</h3>
                            <p class="address-address">{{ place | address }}</p>
                        </div>

                        <p class="company-info-title mt-3 mb-0">Contato</p>
                        <p class="phone-email mt-0">{{ companyInfo.company.contact_phone | phone}} | {{ companyInfo.company.contact_email }}</p>

                        <p class="company-info-title mt-3 mb-0">Horário e dias de funcionamento</p>
                        <ul class="week-days mt-2 mb-2">
                            <li *ngIf="companyInfo.agendaConfig.enable_monday">Segunda</li>
                            <li *ngIf="companyInfo.agendaConfig.enable_tuesday">Terça</li>
                            <li *ngIf="companyInfo.agendaConfig.enable_wednesday">Quarta</li>
                            <li *ngIf="companyInfo.agendaConfig.enable_thursday">Quinta</li>
                            <li *ngIf="companyInfo.agendaConfig.enable_friday">Sexta</li>
                            <li *ngIf="companyInfo.agendaConfig.enable_saturday">Sábado</li>
                            <li *ngIf="companyInfo.agendaConfig.enable_sunday">Domingo</li>
                        </ul>
                        <p class="mt-0 mb-0">Abre às: {{ companyInfo.agendaConfig.work_start }} | Fecha às: {{ companyInfo.agendaConfig.work_end }}</p>

                        <p class="company-info-title mt-3 mb-0">Especialidades</p>
                        <p class="mt-0 mb-0">Oftalmologia</p>
                        <p class="mt-0 mb-0">Endocrinologia</p>

                        <p class="company-info-title mt-3" *ngIf="companyInfo.doctors.length">Profissionais</p>
                        <div *ngIf="companyInfo.doctors.length" class="row">
                            <div class="col-md-4 doctor mb-2" *ngFor="let doctor of companyInfo.doctors">
                                <div class="row mb-3">
                                    <div class="col-md-2">
                                        <img *ngIf="doctor.user.image" src="{{ doctor.user.image.thumb_url }}" class="align-middle doctor-image">
                                        <img *ngIf="!doctor.user.image" src="https://via.placeholder.com/32x32" class="align-middle doctor-image">
                                    </div>
                                    <div class="col-md-10 my-auto">
                                        <span class="align-middle">{{ doctor.user.name}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> <!-- end profile -->

            <div class="col-md-4">
                <div class="company-event">
                    <form [formGroup]="companyForm" (ngSubmit)="submit()">
                        <div class="form-group">
                            <label>Selecione a unidade</label>
                            <select class="form-control" formControlName="place_id" *ngIf="companyInfo.company.places">
                                <option value="">Selecione</option>
                                <option *ngFor="let place of companyInfo.company.places" value="{{place.id}}">{{ place.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Tipo de atendimento</label>
                            <select class="form-control" formControlName="event_id" *ngIf="companyEventPayments.length">
                                <option value="">Selecione</option>
                                <option *ngFor="let event of companyEventPayments" value="{{event.id}}">{{ event.name }}</option>
                            </select>
                        </div>
                        <button type="submit" class="btn btn-primary submit" [disabled]="!companyForm.valid">
                            Marcar consulta
                        </button>
                    </form>
                    <div class="mt-2" *ngIf="eventsById[f.event_id.value] && eventsById[f.event_id.value].payments && eventsById[f.event_id.value].payments.length">
                        Pagamentos aceitos:
                        <ul>
                            <li *ngFor="let paymentMethod of eventsById[f.event_id.value].payments">
                                {{ paymentMethod | paymentMethod }}&nbsp;
                                <span *ngIf="paymentMethod !== 'INSURANCE'">- &nbsp;R${{ eventsById[f.event_id.value].price }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
