<div class="row card-row" style="background-color: #E5E5E5; width: 100%;height: 100vh; margin: 0 !important;">
    <div class="offset-md-2 col-12 col-md-8 card-column" style="padding: 0 !important; border-radius: 8px;display: flex;justify-content: center;align-items: center;">
        <main style="background-color: white; border-radius: 10px; width: 100%; max-width: 100%;">
            <div class="card">
                <div class="card-body" style="padding: 40px !important;">
                    <main>
                        <div class="row">
                            <div class="col-md-12 col-12 text-center">
                                <img src="assets/img/404.png" alt="">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12" style="margin-top: 32px;display: flex; justify-content: center; font-style: normal;font-weight: bold;font-size: 28px;line-height: 120%;text-align: center;color: #008CFF;">
                                Erro 404
                                <br>
                                Página não encontrada
                            </div>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col-12 col-md-12 text-center" style="max-width: 400px; margin-top: 32px;">
                                A página procurada não foi encontrada. Verifique se o caminho digitado está correto.
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </main>
    </div>
</div>
