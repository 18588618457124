import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';

import { CalendarPageComponent } from './calendar-page.component';
import { MobileCalendarModule } from '../../components/calendar/mobile-calendar.module';

@NgModule({
    declarations: [
        CalendarPageComponent
    ],
    exports: [
        CalendarPageComponent
    ],
    imports: [
        MobileCalendarModule,
        BrowserModule,
        AngularSvgIconModule,
        RouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CalendarPageModule { }
