<div class="row card-row" style="background-color: #E5E5E5; width: 100%; height: 100vh;">
    <div class="offset-md-2 col-12 col-md-8 card-column" style="padding: 0 !important; display: flex; justify-content: center; align-items: center;">
        <main style="background-color: white; border-radius: 10px; width: 100%;">
            <div class="card" style="width: 100%;">
                <div class="card-body">
                    <app-loading [hidden]="!loading"></app-loading>
                    <main *ngIf="!loading && !isValidToken">
                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                                <img src="assets/img/error-icon.png" alt="" style="width: 120px;">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                                Erro!
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                                Token inválido ou já utilizado!
                            </div>
                        </div>
                    </main>

                    <main *ngIf="!loading && isValidToken">
                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-top: 40px;">
                                <img src="assets/img/success-icon.png" alt="" style="width: 120px;">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-center" style="font-weight: bold;font-size: 28px;line-height: 120%;color: #008CFF; margin-top: 20px;">
                                Sucesso!
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-12 text-center" style="margin-bottom: 40px !important;margin-top: 30px; font-size: 17px;line-height: 150%;color: #676A6C;">
                                Prezado(a) <strong>{{ patient.name }},</strong>
                                <br>
                                o atendimento foi {{ patient.action }} com sucesso!
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </main>
    </div>
</div>
