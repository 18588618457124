<app-loading [hidden]="!loading"></app-loading>

<div class="container" [hidden]="!hasSessionId">
    <form [formGroup]="cardInfoForm">
        <div class="row mt-4">
            <div class="col-12">
                <span class="card-info">Adicionar informações do cartão</span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <label class="text-sm-left">Número do cartão</label>
                <input class="form-control input-outline placeholder-light-gray" (keyup)="onCardNumberChange()" type="text"
                    maxlength="19" formControlName="cardNumber" mask="0000 0000 0000 0000" placeholder="xxxx xxxx xxxx xxxx"
                    autocomplete="off" [ngClass]="{ 'is-valid': !f.cardNumber.errors && f.cardNumber.value.length > 14, 'is-invalid': f.cardNumber.value && !f.cardNumber.errors && !f.brand.value }">
                <app-card-brand class="card-position" *ngIf="f.cardNumber.value && f.brand.value"
                    [cardBrand]="f.brand.value"></app-card-brand>
            </div>

            <div class="col-12">
                <span *ngIf="f.cardNumber.value && !f.cardNumber.errors && !f.brand.value" class="invalid-feedback d-block">Número do cartão inválido</span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-4">
                <label class="text-sm-left">Validade</label>
                <input class="form-control input-outline placeholder-light-gray" type="text" mask="00/00"
                    formControlName="expirationDate" placeholder="MM/AA" autocomplete="off"
                    [ngClass]="{ 'is-invalid': f.expirationDate.value && f.expirationDate.errors, 'is-valid': f.expirationDate.value && !f.expirationDate.errors }">
                <span *ngIf="f.expirationDate.value && f.expirationDate.errors" class="invalid-feedback d-block">Data inválida!</span>
            </div>

            <div class="col-3">
                <label class="text-sm-left">CVV</label>
                <input class="form-control input-outline placeholder-light-gray" [mask]="cvv_mask" type="text"
                    formControlName="cvv" [placeholder]="cvv_placeholder" autocomplete="off" [ngClass]="{ 'is-valid': f.cvv.value && !f.cvv.errors, 'is-invalid': f.cvv.value && f.cvv.errors }">
                <span class="invalid-feedback d-block" *ngIf="f.cvv.value && f.cvv.errors">CVV inválido</span>
            </div>

            <div class="col-5">
                <label class="text-sm-left">CEP</label>
                <input class="form-control input-outline placeholder-light-gray" mask="00000-000" type="text"
                    formControlName="cep" autocomplete="off" placeholder="Digite o CEP" [ngClass]="{ 'is-valid': f.cep.value && !f.cep.errors }">
            </div>
        </div>
    </form>
</div>
