import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { AlertMessageService } from './alert-message.service';
import { pagseguroMessages } from '../utils'

declare var PagSeguroDirectPayment:any;

@Injectable()
export class PagSeguroService {
    getSessionId = (data, callback) => this.globalService.mountResource('GET', { url: '/pagseguro/session' }, data, callback);
    transaction = (data, callback) => this.globalService.mountResource('POST', { url: '/pagseguro/transaction' }, data, callback);

    constructor(
        protected globalService: GlobalService
    ) { }

    setSessionId(sessionId) {
        if (!sessionId) {
            return;
        }

        PagSeguroDirectPayment.setSessionId(sessionId);
    }

    getSenderHashReady(callback) {
        PagSeguroDirectPayment.onSenderHashReady((resp) => {
            if (!resp || resp.status !== 'success') {
                callback(null);
                return;
            }

            callback(resp.senderHash);
        });
    }

    getCreditCardHash(cardInfo, callback) {
        PagSeguroDirectPayment.createCardToken({
            cardNumber: cardInfo.cardNumber,
            brand: cardInfo.brand,
            cvv: cardInfo.cvv,
            expirationMonth: cardInfo.expirationDate.substr(0, 2),
            expirationYear: '20' + cardInfo.expirationDate.substr(2, 2).toString(),
            success: (resp) => callback(null, resp.card.token),
            error: (resp) => {
                if (!resp || (resp && !resp.error)) {
                    return;
                }

                callback(this.getParsedError(resp.errors));
            }
        });
    }

    getParsedError(error) {
        if (!Object.keys(error) || !Object.keys(error).length) {
            return 'Algo de errado aconteceu com seu pagamento, verifique se os dados estão corretos. ';
        }

        return pagseguroMessages[Object.keys(error)[0]] || 'Algo de errado aconteceu com seu pagamento, verifique se os dados estão corretos. ';

    }
}
