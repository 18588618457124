import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DesktopRoutingModule } from './desktop.routing.module';

import {
    CompanyPageModule, SearchPageComponent, ResumeCheckoutPageModule, ScheduledSuccessfullyPageModule,
    AttendanceInfoModule, NpsPageModule, UnsubscribePageModule, AgilizeAttendancePageModule,
    ConfirmAttendancePageModule, NotFoundPageModule, AttendanceStatusPageModule, LeadsPageModule, RegisterPageModule
} from './pages';

import {
    ModalScheduledSuccessfullyComponent, ModalDoctorSlotsComponent,
    ModalShareAttendanceResumeModule, ModalDoctorCardSlotsModule, ModalOnboardModule
} from './modals';

import {
    CompanyListModule, DefaultHeaderModule, CardInfoModule,
    SearchFiltersComponent, SuggestMultiSelectModule, CardBrandModule, CompanyCardModule,
    HistoryBackModule, DoctorCardSlotsModule
} from './components';

import {
    SearchPageService
} from './services';

import { LoadingModule } from '../shared/components';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        // pages
        SearchPageComponent,

        // modals
        ModalDoctorSlotsComponent,
        ModalScheduledSuccessfullyComponent,

        // components
        SearchFiltersComponent,
    ],
    imports: [
        SharedModule,
        DesktopRoutingModule,
        DefaultHeaderModule,
        ResumeCheckoutPageModule,
        SuggestMultiSelectModule,
        LoadingModule,
        LeadsPageModule,
        CompanyListModule,
        CompanyPageModule,
        CardBrandModule,
        CardInfoModule,
        CompanyCardModule,
        ModalShareAttendanceResumeModule,
        ModalDoctorCardSlotsModule,
        HistoryBackModule,
        DoctorCardSlotsModule,
        ScheduledSuccessfullyPageModule,
        AttendanceInfoModule,
        NpsPageModule,
        UnsubscribePageModule,
        ModalOnboardModule,
        AgilizeAttendancePageModule,
        ConfirmAttendancePageModule,
        NotFoundPageModule,
        AttendanceStatusPageModule,
        RegisterPageModule
    ],
    providers: [
        SearchPageService,
    ],
    bootstrap: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DesktopModule { }
