import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-modal-scheduled-successfully',
    templateUrl: './modal-scheduled-successfully.component.html',
    styleUrls: ['./modal-scheduled-successfully.component.scss']
})
export class ModalScheduledSuccessfullyComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
}
