<div style="width: 100%; height: 100vh; padding: 10px;" *ngIf="step === 1">
    <div class="d-flex justify-content-center" style="align-items: center; height: 100%;">
        <main style="background-color: white; width: 100%; max-width: 100%;">
            <div class="d-flex flex-column text-center">
                <div>
                    <svg-icon src="assets/svg/check-schedule.svg"></svg-icon>
                </div>
                <div style="margin-top: 16px; font-style: normal;font-weight: 500;font-size: 24px;line-height: 115%;">Agendamento realizado com sucesso</div>
                <div style="margin-top: 24px;">
                    <button class="btn btn-primary" (click)="step = 2">Continuar</button>
                </div>
            </div>
        </main>
    </div>
</div>

<div style="width: 100%; padding: 15px;" *ngIf="step === 2">
    <app-loading [hidden]="!loading"></app-loading>

    <div class="d-flex flex-column" *ngIf="!loading" [hidden]="loading">
        <div style="font-style: normal;font-weight: normal;font-size: 32px;line-height: 112%;color: #008cff;">Agendamento</div>

        <div style="margin-top: 16px;font-weight: 400;
        font-size: 16px;line-height: 112%;">
            <svg-icon src="{{ status ? 'assets/svg/double-check.svg' : 'assets/svg/scheduled-icon.svg' }}" style="margin-right: 8px; width: 24px; height: 24px;"></svg-icon>
            {{ status ? 'Atendimento confirmado' : 'Agendado' }}
        </div>

        <div style="margin-top: 32px; padding-bottom: 24px;">
            <app-doctor-card [doctorData]="doctor"></app-doctor-card>
        </div>

        <div style="font-style: normal; display: flex; align-items: center; font-weight: normal;font-size: 16px;line-height: 115%; padding-top: 24px; border-top: 1px solid #f0f0f0;">
            <svg-icon src="assets/svg/clock.svg" style="margin-right: 8px; position: relative; top: -1px;"></svg-icon>
            {{ slot.start_date | date: 'dd/MM/yyyy' }}<span *ngIf="!slot.shift">&nbsp;às {{ slot.start_date | date: 'HH:mm' : '-03:00' }}</span>

        </div>

        <div style="font-size: 15px;" *ngIf="slot.shift"> A partir das {{ slot.shift.attendance_start }} às {{ slot.shift.attendance_end }}</div>

        <div style="font-style: normal; display: flex; align-items: center; font-weight: normal;font-size: 16px;line-height: 115%; margin-top: 16px;">
            <svg-icon src="assets/svg/pin.svg" style="margin-right: 8px; position: relative; top: -2px;"></svg-icon>
            <span *ngIf="place.address && !event.is_tele">{{ place.address | address }}</span>
            <span *ngIf="!place.address && !event.is_tele">Endereço não informado</span>
            <span *ngIf="event.is_tele">TELEATENDIMENTO</span>
        </div>

        <div style="font-style: normal; display: flex; font-weight: normal;font-size: 16px !important; margin-top: 16px;">
            <svg-icon src="assets/svg/outline-stethoscope.svg" style="margin-right: 8px;"></svg-icon>
            {{ event.name }}
        </div>

        <div style="display: flex; flex-direction: column; line-height: 115%; margin-top: 16px; padding: 24px 0 24px 0; border-bottom: 1px solid #f0f0f0; border-top: 1px solid #f0f0f0;">
            <div style="font-style: normal; display: flex; font-weight: normal;font-size: 18px !important;">
                Pagamento
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; margin-top: 10px;">
                <span>{{payment}}</span>
                <div *ngIf="payment === 'Particular' && slot.price" style="font-size: 14px;">{{ slot.price | currency: 'BRL' }}</div>
                <div *ngIf="payment === 'Convênio'" style="font-size: 14px;">{{ insurance.name }}</div>
            </div>
        </div>

        <!-- <div style="padding-top: 8px;text-align: center;" [ngClass]="{ 'pt-3': !status }">
            <a href="#" style="font-style: normal;font-weight: 500;font-size: 14px;line-height: 115%;color: #676a6c; text-decoration: underline;">Visualizar rota</a>
        </div> -->
    </div>
</div>
